import { ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { UnsubscriberComponent } from '@bpce/utils';
import { ModalComponent } from '../modal.component';
import { ModalConfig, ModalContent, ModalRef } from '../modal.model';

@Injectable()
export class ModalBuilderService extends UnsubscriberComponent {
  private component: ComponentType<any> | null = null; // NOSONAR

  constructor(
    protected applicationRef: ApplicationRef,
    protected injector: Injector,
    protected resolver: ComponentFactoryResolver
  ) {
    super();
  }

  get container(): ViewContainerRef | null | undefined {
    return this.applicationRef.components[0].instance.viewContainerRef;
  }

  public create<T extends ModalContent>(component: ComponentType<T>): ModalBuilderService {
    this.component = component;
    return this;
  }

  // prettier-ignore
  public open<T extends ModalContent, K = any, H = any>(
    modalConfig: ModalConfig<K>,
    target?: ViewContainerRef,
    injector?: Injector
  ): ModalRef<T, H> { // NOSONAR
    if (this.component === null) {
      throw new Error('You must call create() before calling open()');
    }

    let container: ViewContainerRef;
    if (target) {
      container = target;
    } else if (this.container) {
      container = this.container;
    } else {
      const appName = this.applicationRef.componentTypes[0].name;
      throw new Error(`Missing 'viewContainerRef' declaration in ${appName} constructor`);
    }
    if (injector) {
      this.injector = injector; // substitution by own injector as an optional arg
    }

    const modalRef = this.createDialog<T, H>(container, this.resolver, this.injector, this.component);

    this.initDialog(modalRef, modalConfig);
    this.component = null;

    return modalRef;
  }

  private createDialog<T extends ModalContent, H>(
    container: ViewContainerRef,
    resolver: ComponentFactoryResolver,
    injector: Injector,
    componentType: ComponentType<T>
  ): ModalRef<T, H> {
    // Create parent component
    const modalContainerFactory = resolver.resolveComponentFactory<ModalComponent>(ModalComponent);
    const modalContainerRef = container.createComponent<ModalComponent>(modalContainerFactory, 0, injector);
    const modalRef = new ModalRef<T, H>(modalContainerRef);

    const componentInjector = Injector.create({
      providers: [{ provide: ModalRef, useValue: modalRef }],
      parent: injector
    });

    // Dynamically create and attach child component into parent
    modalRef.componentRef = modalContainerRef.instance.attachContent(componentType, componentInjector);

    return modalRef;
  }

  private initDialog<T extends ModalContent, K, H>(
    modalRef: ModalRef<T, H>,
    { mode, data, id, hasStickyFooter }: ModalConfig<K>
  ) {
    // Bind modalComponent inputs
    modalRef.containerRef.instance.mode = mode;
    modalRef.containerRef.instance.data = data;
    modalRef.containerRef.instance.id = id || '';
    modalRef.containerRef.instance.hasStickyFooter = hasStickyFooter || false;

    if (modalRef.componentRef) {
      // If a close event is fired from modal, close and destroy modal
      modalRef.componentRef.instance.closed
        .pipe(this.autoUnsubscriber())
        .subscribe(value => modalRef.containerRef.instance.close(value));
    }

    // When after close event is fired, destroy modal component
    modalRef.containerRef.instance.afterClose.pipe(this.autoUnsubscriber()).subscribe(() => {
      if (modalRef.componentRef) {
        modalRef.componentRef.destroy();
      }
      modalRef.containerRef.destroy();
    });
  }
}
