import { RedactionRule } from '../model/model';

export namespace TokenizerHelper {
  export const HIDE_PLACEHOLDER = '\u2022';

  // Maximum des caractères masqués est 10. (exemple : pour un mail très long)
  const limitOfRedactedCharacter = 10;

  export function setCaviardageString(value: string, redactionRules: RedactionRule[]): string {
    if (value && typeof value === 'string') {
      return redactionRules.reduce((acc, { firstIndex, lastIndex }) => applyRule(acc, firstIndex, lastIndex), value);
    } else {
      return '';
    }
  }

  function applyRule(value: string, firstIndex: number, lastIndex: number): string {
    return value
      .split('')
      .map((char, index) => replaceChar(char, index, firstIndex, lastIndex))
      .join('');
  }

  function replaceChar(char: string, index: number, firstIndex: number, lastIndex: number) {
    const placeholder =
      lastIndex - firstIndex >= limitOfRedactedCharacter && index - firstIndex >= limitOfRedactedCharacter
        ? ''
        : TokenizerHelper.HIDE_PLACEHOLDER;
    return index >= firstIndex && index <= lastIndex && char !== ' ' ? placeholder : char;
  }
}
