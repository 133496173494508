import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconV2Module } from '@bpce/ui/icon-v2';
import { UiButtonV2Module } from '@bpce/ui/button-v2';
import { InfoBoxComponent } from './infobox.component';

@NgModule({
  declarations: [InfoBoxComponent],
  imports: [CommonModule, UiIconV2Module, UiButtonV2Module],
  exports: [InfoBoxComponent]
})
export class UiInfoBoxModule {}
