import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SvgEmbedderService {
  private readonly cachedResponseList = new Map<string, string>();
  private readonly pendingResponseList = new Map<string, Subject<string>>();

  constructor(@Optional() private readonly httpClient: HttpClient) {}

  get(src: string): Observable<string> {
    if (!this.httpClient) {
      throw Error(
        'Could not find HttpClient provider for use with SvgEmbedderService. ' +
          'Please include the HttpClientModule from @angular/common/http in your app imports.'
      );
    }

    const cachedResponse = this.cachedResponseList.get(src);
    let pendingResponse = this.pendingResponseList.get(src);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    if (pendingResponse) {
      return pendingResponse;
    }

    pendingResponse = new Subject();
    this.pendingResponseList.set(src, pendingResponse);

    return this.httpClient.get(src, { responseType: 'text' }).pipe(
      tap(res => {
        pendingResponse?.next(res);
        pendingResponse?.complete();
        this.cachedResponseList.set(src, res);
        this.pendingResponseList.delete(src);
      })
    );
  }
}
