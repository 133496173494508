import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {NavigationService} from "../../routing/services/navigation.service";
import {CcoopApiService} from "./ccoop-api.service";

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {

  constructor(
    private apiService: CcoopApiService,
    private navigationService: NavigationService,
  ) {
  }


  public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(httpRequest)
      .pipe(catchError((request: any) => {
        switch (request.status) {
          case 401:
            this.navigationService.goToLinkExpired();
            break
          case 403:
            this.navigationService.goToMail();
            break
          case 404:
            this.navigationService.goToLinkExpired();
            break
          case 409:
            this.navigationService.goToMailAlreadyExists();
            break
          case 422:
            this.navigationService.goToStepsPresentation();
            break
        }
        return throwError(request)
      }));
  }
}
