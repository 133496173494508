<div class="bpce-progress-loader">
  <div class="bpce-progress-loader-svg">
    <svg aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="47" />
      <circle
        [style.stroke-dasharray.px]="strokeCircumference"
        [style.stroke-dashoffset.px]="strokeDashOffset"
        cx="50"
        cy="50"
        id="movingCircle"
        r="47"
      />
    </svg>
    <p
      class="bpce-progress-loader-number"
      role="progressbar"
      [attr.aria-valuemin]="minValue"
      [attr.aria-valuemax]="maxValue"
      [attr.aria-valuenow]="ariaValueNow"
      [attr.aria-valuetext]="subtitle"
    >
      <span id="loaderValue">{{ value }}</span>
      <sup>%</sup>
    </p>
  </div>
  <p *ngIf="title" class="bpce-progress-loader-title">{{ title }}</p>
  <p *ngIf="subtitle" class="bpce-progress-loader-subtitle">{{ subtitle }}</p>
</div>
