import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {CcoopApiService} from '../../api/services/ccoop-api.service';
import {first, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {
  AssociationChoicesReferentiel,
  CommitmentAccountReferentiel,
  CommitmentCardReferentiel,
  CommitmentPrinciplesReferentiel,
  DonationChoicesReferentiel,
  FinalReferentiel,
  FormulaChoicesReferentiel,
  ErrorReferentiel,
  MailReferentiel,
  MailSavedReferentiel,
  StepsPresentationReferentiel,
  SummaryReferentiel,
  TrackingChoicesReferentiel,
  TransverseReferentiel,
  PartnerOffer,
} from '../../../../../../models/Referentiel';
import {DataActions} from './data.actions';
import {SetErrorsAction} from 'ngrx-forms';
import {ReferentielService} from '../services/referentiel.service';
import {ProspectService} from '../services/prospect.service';
import * as EmailValidator from 'email-validator';
import {of} from 'rxjs';
import {
  PartnerOfferError,
  PartnerOfferValidator,
} from '../../shared/helper/partner-offer.validator';

@Injectable()
export class DataEffects {
  constructor(
    private ccoopApiService: CcoopApiService,
    private referentielService: ReferentielService,
    private actions$: Actions,
    private store: Store,
    private prospectService: ProspectService,
    private apiService: CcoopApiService,
  ) {}

  save$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataActions.saveProspect),
        withLatestFrom(this.prospectService.prospect$),
        mergeMap(([, prospect]) => this.ccoopApiService.putProspect(prospect)),
      ),
    {dispatch: false},
  );

  checkSetEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.setEmail),
      map(({email}) => {
        const errors = {};
        if (!email) {
          errors['email'] = {required: true};
        } else {
          if (EmailValidator.validate(email) === false) {
            errors['email'] = {mail: true};
          }
        }
        return new SetErrorsAction('EMAIL.email', {...errors});
      }),
    ),
  );

  checkSetCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.setCode),
      mergeMap(({code}) => {
        if (!code) {
          return of(new SetErrorsAction('EMAIL.code', {}));
        }
        return this.apiService.getPartnerOffers$(code).pipe(
          map((partnerOffers: PartnerOffer[]) => {
            const errors = {};
            if (partnerOffers.length > 0) {
              const partnerOffer = partnerOffers[0];
              const error: PartnerOfferError = PartnerOfferValidator.getError(partnerOffer);
              if (error === PartnerOfferError.INVALID) {
                errors['code'] = {invalid: true};
              } else if (error === PartnerOfferError.EXPIRED) {
                errors['code'] = {expired: true};
              }
            } else if (code) {
              errors['code'] = {invalid: true};
            }
            return new SetErrorsAction('EMAIL.code', {...errors});
          }),
        );
      }),
    ),
  );

  setCode = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.setPartnerOffers),
      map(({partnerOffers}) => {
        const partneroffer = partnerOffers?.find((partnerOffer) => !!partnerOffer.code);
        return DataActions.setPartner({partner: partneroffer ? partneroffer.code : undefined});
      }),
    ),
  );

  setPartner = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.setPartnerOffers),
      map(({partnerOffers}) => {
        const partneroffer = partnerOffers?.find((partnerOffer) => !!partnerOffer.name);
        return DataActions.setPartner({partner: partneroffer ? partneroffer.name : undefined});
      }),
    ),
  );

  initDonation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.initDonation),
      withLatestFrom(
        this.prospectService.prospect$,
        this.referentielService.donationChoicesReferentiel$,
      ),
      map(([, prospect, referentiel]) => {
        if (prospect.donation === undefined) {
          return DataActions.setDonation({
            donation: true,
            donationPerOperation: referentiel.donationPerOperation.defaultValue ?? null,
            donationCeiling: referentiel.donationCeiling.defaultValue ?? null,
          });
        }
        return DataActions.setDonation({
          donation: prospect.donation,
          donationPerOperation: prospect.donationPerOperation,
          donationCeiling: prospect.donationCeiling,
        });
      }),
    ),
  );

  switchDonation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.switchDonation),
      withLatestFrom(
        this.prospectService.prospect$,
        this.referentielService.donationChoicesReferentiel$,
      ),
      map(([, prospect, referentiel]) => {
        if (prospect.donation === true) {
          return DataActions.setDonation({
            donation: false,
            donationPerOperation: undefined,
            donationCeiling: undefined,
          });
        } else {
          return DataActions.setDonation({
            donation: true,
            donationPerOperation: referentiel.donationPerOperation.defaultValue,
            donationCeiling: referentiel.donationCeiling.defaultValue,
          });
        }
      }),
    ),
  );

  loadTransverseReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchTransverseLocalReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('transverseReferentiel')
          .pipe(
            map((referentiel: TransverseReferentiel) =>
              DataActions.setTransverseReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  loadLinkExpiredReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchErrorLinkExpired),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('linkExpiredReferentiel')
          .pipe(
            map((referentiel: ErrorReferentiel) =>
              DataActions.setLinkExpiredReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  browserErrorReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchErrorBrowserError),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('browserErrorReferentiel')
          .pipe(
            map((referentiel: ErrorReferentiel) =>
              DataActions.setLinkExpiredReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  loadStepsPresentationReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchStepsPresentationReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('stepsPresentationReferentiel')
          .pipe(
            map((referentiel: StepsPresentationReferentiel) =>
              DataActions.setStepsPresentationReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  mailReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchMailReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('mailReferentiel')
          .pipe(
            map((referentiel: MailReferentiel) => DataActions.setMailReferentiel({referentiel})),
          ),
      ),
    ),
  );

  mailSavedReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchMailSavedReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('mailSavedReferentiel')
          .pipe(
            map((referentiel: MailSavedReferentiel) =>
              DataActions.setMailSavedReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  formulaChoicesReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchFormulaChoiceReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('formulaChoicesReferentiel')
          .pipe(
            map((referentiel: FormulaChoicesReferentiel) =>
              DataActions.setFormulaChoiceReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  commitmentPrincipleReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchCommitmentPrinciplesReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('commitmentPrinciplesReferentiel')
          .pipe(
            map((referentiel: CommitmentPrinciplesReferentiel) =>
              DataActions.setCommitmentPrinciplesReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  commitmentAccountReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchCommitmentAccountReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('commitmentAccountReferentiel')
          .pipe(
            map((referentiel: CommitmentAccountReferentiel) =>
              DataActions.setCommitmentAccountReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  trackingChoicesReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchTrackingChoicesReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('trackingChoicesReferentiel')
          .pipe(
            map((referentiel: TrackingChoicesReferentiel) =>
              DataActions.setTrackingChoicesReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  commitmentCardReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchCommitmentCardReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('commitmentCardReferentiel')
          .pipe(
            map((referentiel: CommitmentCardReferentiel) =>
              DataActions.setCommitmentCardReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  asociationChoicesReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchAssociationChoicesReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('associationChoicesReferentiel')
          .pipe(
            map((referentiel: AssociationChoicesReferentiel) =>
              DataActions.setAssociationChoicesReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  donationChoicesReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchDonationChoicesReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('donationChoicesReferentiel')
          .pipe(
            map((referentiel: DonationChoicesReferentiel) =>
              DataActions.setDonationChoicesReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  summaryReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchSummaryReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('summaryReferentiel')
          .pipe(
            map((referentiel: SummaryReferentiel) =>
              DataActions.setSummaryReferentiel({referentiel}),
            ),
          ),
      ),
    ),
  );

  finalReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.fetchFinalReferentiel),
      first(),
      mergeMap(() =>
        this.ccoopApiService
          .fetchReferentiel$('finalReferentiel')
          .pipe(
            map((referentiel: FinalReferentiel) => DataActions.setFinalReferentiel({referentiel})),
          ),
      ),
    ),
  );

  checksetDonationWithValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.setDonationWithValidation),
      map((props) => DataActions.validateDonation(props)),
    ),
  );

  checkValidateDonation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.validateDonation),
      mergeMap(({donation, donationPerOperation, donationCeiling}) =>
        this.referentielService.donationChoicesReferentiel$.pipe(
          map((referentiel) => {
            if (donation === false) {
              return new SetErrorsAction('DONATION', {});
            }

            const errors = {};

            if (donationPerOperation === undefined || donationPerOperation === null) {
              errors['donationPerOperation'] = {required: true};
            } else {
              if (donationPerOperation < referentiel.donationPerOperation.minimum) {
                errors['donationPerOperation'] = {minimum: true};
              }

              if (donationPerOperation > referentiel.donationPerOperation.maximum) {
                errors['donationPerOperation'] = {maximum: true};
              }
            }

            if (donationCeiling && donationCeiling < referentiel.donationCeiling.minimum) {
              errors['donationCeiling'] = {minimum: true};
            }

            if (donationCeiling && donationCeiling > referentiel.donationCeiling.maximum) {
              errors['donationCeiling'] = {maximum: true};
            }
            return new SetErrorsAction('DONATION', errors);
          }),
        ),
      ),
    ),
  );

  // checkSetValue$ = createEffect(() => this.actions$.pipe(
  //   ofType(SetValueAction.TYPE),
  //   mergeMap((form: { controlId, value }) => this.referentielService.donationChoicesReferentiel$
  //     .pipe(map((referentiel) => {
  //       if (form.controlId === 'DONATION.donationPerOperation') {
  //         if (form.value === undefined || form.value === null) {
  //           return new SetErrorsAction('DONATION.donationPerOperation', {required: true})
  //         } else {
  //           console.log('remppve errors')
  //           return new SetErrorsAction('DONATION.donationPerOperation', {})
  //         }
  //
  //         if (form.value < referentiel.donationPerOperation.minimum) {
  //           return new SetErrorsAction('DONATION.donationPerOperation', {minimum: true})
  //         }
  //
  //         if (form.value > referentiel.donationPerOperation.maximum) {
  //           return new SetErrorsAction('DONATION.donationPerOperation', {maximum: true})
  //         }
  //       }
  //
  //       if (form.controlId === 'DONATION.donationCeiling') {
  //         if (form.value === undefined || form.value === null) {
  //           return new SetErrorsAction('DONATION.donationCeiling', {required: true})
  //         } else {
  //           return new SetErrorsAction('DONATION.donationCeiling', {})
  //         }
  //
  //         if (form.value < referentiel.donationCeiling.minimum) {
  //           return new SetErrorsAction('DONATION.donationCeiling', {minimum: true})
  //         }
  //
  //         if (form.value > referentiel.donationCeiling.maximum) {
  //           return new SetErrorsAction('DONATION.donationCeiling', {maximum: true})
  //         }
  //       }
  //
  //       console.log('should not happen')
  //       return new SetErrorsAction(form.controlId, {})
  //     }))
  //   ))
  // );
}
