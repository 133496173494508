import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

const DEFAULT_ILLUSTRATION_ID = 'erreurs_page_404';
const HEIGHT_DEAFULT = 224;
const WIDTH_DEFAULT = 320;

@Component({
  selector: 'ui-illustration',
  template: ` <ui-svg-embedder [src]="src" [height]="height" [width]="width"
    ><ui-svg-embedder></ui-svg-embedder
  ></ui-svg-embedder>`,
  styleUrls: ['./illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IllustrationComponent {
  private static readonly defaultIllustrationId = DEFAULT_ILLUSTRATION_ID;
  private static readonly heightDeafult = HEIGHT_DEAFULT;
  private static readonly widthDefault = WIDTH_DEFAULT;

  private svgIcon: SVGElement;

  @Input() id = '';
  @Input() height = IllustrationComponent.heightDeafult;
  @Input() width = IllustrationComponent.widthDefault;

  get src(): string {
    const illustration = this.id || IllustrationComponent.defaultIllustrationId;

    return `svg/illustrations/${illustration}.svg`;
  }
}
