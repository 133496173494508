<div
  class="bpce-header"
  [ngClass]="{
    'bpce-header-with-border-bottom': hasBorderBottom,
    'bpce-header-with-background': hasBackground,
    'bpce-header-with-custom-background': backgroundColor,
    'bpce-header-with-shadow': hasShadow,
    'bpce-header-only-title': !showBack && !customRightContent && !showHelp && !showClose
  }"
  [style.background-color]="backgroundColor"
>
  <div class="bpce-header-left">
    <!-- Back button -->
    <button
      class="bpce-header-back"
      uiButtonV2
      aria-label="Retour"
      [category]="'secondary'"
      [onColoredBackground]="backgroundColor"
      [icon]="'chevron-left'"
      [noBorder]="true"
      type="button"
      bpceAutoFocus
      [activateFocus]="applyBpceAutoFocus === autoFocusOption.BACK"
      bpceAriaButton
      [enableDirective]="applyBpceAriaButton"
      (bpceThrottleClick)="back.emit()"
      *ngIf="mustShowBackButton()"
    >
      <ng-container *ngIf="!hideBackLabel">Retour</ng-container>
    </button>

    <button
      class="bpce-header-left-custom"
      uiButtonV2
      [attr.aria-label]="leftButtonLabel"
      [category]="'secondary'"
      [onColoredBackground]="backgroundColor"
      [icon]="leftButtonIcon"
      [noBorder]="true"
      type="button"
      bpceAutoFocus
      [activateFocus]="applyBpceAutoFocus === autoFocusOption.BACK"
      bpceAriaButton
      [enableDirective]="applyBpceAriaButton"
      (bpceThrottleClick)="back.emit()"
      *ngIf="mustShowCustomLeftButton()"
    >
      {{ leftButtonLabel }}
    </button>
  </div>

  <div class="bpce-header-middle">
    <div class="bpce-header-ellipsis-container">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="bpce-header-right">
    <ng-container *ngIf="customRightContent; else defaultRightContent">
      <ng-container [ngTemplateOutlet]="customRightContent"></ng-container>
    </ng-container>

    <ng-template #defaultRightContent>
      <section uiButtonV2Group>
        <!-- Help button -->
        <button
          class="bpce-header-assist"
          uiButtonV2
          aria-label="Aide"
          [category]="'secondary'"
          [onColoredBackground]="backgroundColor"
          [icon]="'help'"
          [noBorder]="true"
          type="button"
          bpceAutoFocus
          [activateFocus]="applyBpceAutoFocus === autoFocusOption.HELP"
          bpceAriaButton
          [enableDirective]="applyBpceAriaButton"
          (bpceThrottleClick)="help.emit()"
          *ngIf="showHelp"
        ></button>

        <!-- Close button -->
        <button
          class="bpce-header-close"
          uiButtonV2
          [attr.aria-label]="closeButtonAriaLabel"
          [category]="'secondary'"
          [onColoredBackground]="backgroundColor"
          [icon]="'close'"
          [noBorder]="true"
          type="button"
          bpceAutoFocus
          [activateFocus]="applyBpceAutoFocus === autoFocusOption.CLOSE"
          bpceAriaButton
          [enableDirective]="applyBpceAriaButton"
          (bpceThrottleClick)="close.emit()"
          *ngIf="showClose"
        ></button>
      </section>
    </ng-template>
  </div>
</div>
