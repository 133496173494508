import {Component, EventEmitter, Input, Output} from '@angular/core';

import * as replaceSpecialCharacters from "replace-special-characters"

@Component({
  selector: 'app-modale-title',
  templateUrl: './modale-title.component.html',
  styleUrls: ['./modale-title.component.scss']
})
export class ModaleTitleComponent {

  @Input() title: string;

}
