<section class="bpce-fab">
  <button
    id="fabBtn"
    class="bpce-fab-button bpce-fab-button-custom"
    [class.bpce-fab-button-disappear]="!isFabDisplayed"
  >
    <div class="bpce-fab-button-grid">
      <span class="bpce-fab-button-icon-wrapper">
        <ui-pictogram [id]="icon" size="md" reverse="true"></ui-pictogram>
      </span>
    </div>
  </button>
</section>
