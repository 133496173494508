import { Inject, InjectionToken, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { provideUrlDqmService, URL_DQM } from '../dqe/urlInjectionToken';
import { provideDqeConfigService } from '../dqe/dqe.config';
import { ADDRESS_SUGGESTION_SERVICE, AddressSuggestionService } from './address-suggestion.service';

export function provideAddressSuggestionService(): Provider {
  return [
    {
      provide: ADDRESS_SUGGESTION_SERVICE,
      useClass: AddressSuggestionService
    }
  ];
}

// ToDo must be possible for the projects to pass a specific provider
@NgModule({})
export class UiAddressSuggestionModule {
  static url(urlDQM: string): ModuleWithProviders<UiAddressSuggestionModule> {
    return {
      ngModule: UiAddressSuggestionModule,
      providers: [provideUrlDqmService(urlDQM), provideDqeConfigService(URL_DQM), provideAddressSuggestionService()]
    };
  }

  static urlToken(
    @Inject(URL_DQM) urlDQMToken: InjectionToken<string>
  ): ModuleWithProviders<UiAddressSuggestionModule> {
    return {
      ngModule: UiAddressSuggestionModule,
      providers: [provideDqeConfigService(urlDQMToken), provideAddressSuggestionService()]
    };
  }
}
