<div
  #button
  type="button"
  role="tab"
  class="tabs-button"
  [attr.aria-controls]="'tabpanel-' + index"
  [attr.id]="'tab-' + index"
  [attr.aria-selected]="ariaSelected"
  [attr.tabindex]="tabIndex"
>
  <ng-content></ng-content>
</div>
