import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { boroughsObject } from '@bpce/utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DQE_CONFIG, DqeConfig, ServiceNameEnum } from '../dqe/dqe.config';
import { SuggestionService, ServiceUnavailableError } from '../dqe/dqe.model';
import { ITown } from './town-suggestion.model';
import { convertObjectToTown, isIdLocalityValid } from './town-suggestion.util';

export const TOWN_SUGGESTION_SERVICE = new InjectionToken<SuggestionService<string, ITown>>('TOWN_SUGGESTION_SERVICE');

@Injectable({
  providedIn: 'root'
})
export class DqeTownSuggestService implements SuggestionService<string, ITown> {
  constructor(
    @Inject(DQE_CONFIG)
    private readonly dqeConfig: DqeConfig,
    private readonly httpClient: HttpClient
  ) {
    // should be taken over by the back-end
    if (dqeConfig === null || dqeConfig.url === '') {
      throw new TypeError('Invalid DQE config');
    }
  }

  /***
   * Suggests
   * Called only when filling requirements
   * @param query
   * @param showKnownAs
   */
  suggest(query: string, showKnownAs = true): Observable<ITown[]> {
    if (!this.validValue(query)) {
      return of([]);
    }

    const url = `${this.dqeConfig.url}/${ServiceNameEnum.ZipCode}/`;
    const params = this.getConfigParamsHTTP(query);

    return this.httpClient.get(url, { params }).pipe(
      map(response => {
        const boroughsArray = this.getBoroughs(query, response);
        const sortingMethod = /\d/.test(query) ? this.sortByZipCode : this.sortByName;

        return boroughsArray.concat(
          Object.keys(response)
            .map(key => response[key])
            .filter(this.isValidCity(boroughsArray))
            .map(convertObjectToTown)
            .sort(sortingMethod)
            .reduce((prev, curr) => this.reduceTownSuggest(showKnownAs, prev, curr), [])
        );
      }),
      catchError(() => {
        throw new ServiceUnavailableError();
      })
    );
  }

  sortByName(a: ITown, b: ITown): number {
    return a.city.localeCompare(b.city);
  }

  sortByZipCode(a: ITown, b: ITown): number {
    const zipCodeA = +a.zipCode;
    const zipCodeB = +b.zipCode;

    if (zipCodeA === zipCodeB) {
      return 0;
    }

    return zipCodeA < zipCodeB ? -1 : 1;
  }

  validValue(value: string): boolean {
    return !!value;
  }

  getDQEConfig(): DqeConfig {
    return this.dqeConfig;
  }

  private reduceTownSuggest(showKnownAs, previousValue, currentValue) {
    if (!showKnownAs) {
      currentValue.knownAs = '';
    }
    return previousValue.findIndex(e => e.idCity === currentValue.idCity) < 0
      ? [...previousValue, currentValue]
      : showKnownAs && currentValue.knownAs && !previousValue.find(i => i.knownAs === currentValue.knownAs)
      ? [...previousValue, currentValue]
      : previousValue;
  }

  private isValidCity(boroughsArray) {
    return ({ CodePostal, Localite: city, IDLocalite: idCity, Cedex: cedex }) =>
      !!(
        CodePostal &&
        city !== '' &&
        isIdLocalityValid(idCity) &&
        !boroughsArray.find(e => e.idCity === idCity) &&
        cedex !== '1'
      );
  }

  private getBoroughsFromName(value: string) {
    const town = Object.keys(boroughsObject).find(b => b.includes(value.toLocaleUpperCase()));
    return town ? this.getTownContent(town) : [];
  }

  // prettier-ignore
  private getBoroughsFromArray(response: any) { // NOSONAR
    return Object.keys(response)
      .map(key => response[key])
      .filter(value => value['Province'].includes('*-*'))
      .map(value => {
        const town = Object.keys(boroughsObject).find(b => b === value['Localite']);
        return town ? this.getTownContent(town) : [];
      })
      .filter(suggest => !!suggest)[0];
  }

  private getTownContent(town: string) {
    return boroughsObject[town].map(convertObjectToTown);
  }

  private townHasBoroughs(response): boolean {
    return !!Object.keys(response)
      .map(key => response[key])
      .find(value => value['Province'].includes('*-*'));
  }

  private getBoroughs(value: string, response) {
    // Specific check for the Y town.
    if (value.toLocaleUpperCase() === 'Y') {
      return this.getTownContent('Y');
    } // NOSONAR If index in response contains more than one * in Province, it means that this town has boroughs.
    else if (this.townHasBoroughs(response)) {
      return this.getBoroughsFromArray(response);
    } else if (Object.keys(boroughsObject).find(k => k.includes(value.toLocaleUpperCase()))) {
      return this.getBoroughsFromName(value);
    } else {
      return [];
    }
  }

  private getConfigParamsHTTP(value): HttpParams {
    return new HttpParams()
      .set('CodePostal', value)
      .append('Alpha', 'true')
      .append('Etendue', 'Y')
      .append('Instance', '0')
      .append('Pays', 'FRA');
  }
}
