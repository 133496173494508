import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const DEFAULT_PERCENT = 0;
const CIRCLE_RADIUS = 47;
const TWO = 2;
const MIN_VALUE = 0;
const MAX_VALUE = 100;
const VALUE_NOW_STEPS = 10;

@Component({
  selector: 'ui-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiProgressLoaderComponent {
  readonly strokeCircumference = TWO * Math.PI * CIRCLE_RADIUS;
  readonly maxValue = MAX_VALUE;
  readonly minValue = MIN_VALUE;
  readonly stepsValue = VALUE_NOW_STEPS;

  value = DEFAULT_PERCENT;

  strokeDashOffset = 0;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  set percentage(value: number) {
    this.value = Math.min(Math.max(value, this.minValue), this.maxValue);
    this.strokeDashOffset = (this.strokeCircumference * (this.maxValue - this.value)) / this.maxValue;
  }

  get ariaValueNow(): number {
    if (this.value % this.stepsValue === 0) {
      return this.value;
    }

    return (Math.ceil(this.value / this.stepsValue) - 1) * this.stepsValue;
  }
}
