<header *ngIf="referentiel$ | async as referentiel">
  <ui-header
             [attr.back-title]="backTitle"
             [attr.close-title]="closeTitle"
             (back)="backButtonClicked.emit()"
             (close)="closeButtonClicked.emit()"
             [leftButtonIcon]="showBackButton ? 'chevron-left' : undefined"
             [leftButtonLabel]="showBackButton && showBackLabel ? referentiel.backButtonLabel : undefined"
             [showBack]="showBackButton"
             [showClose]="showCloseButton"
             [showHelp]="false">

    <div class="title">
      <img [alt]="referentiel.logoAlt" *ngIf="showLogo" [src]="apiUrl + '/referentiel/files/' + referentiel.logo">
      <app-modale-title [title]="label"></app-modale-title>
    </div>
  </ui-header>

  <ng-content></ng-content>
</header>
