import { FocusMonitor } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { filter, mapTo, switchMap, tap } from 'rxjs/operators';
import { KeyboardUtils } from '@bpce/utils';

@Directive({
  selector: '[bpceAriaButton]',
})
export class BpceAriaButtonDirective implements OnInit, OnDestroy {
  @Input() focusOrigins = ['keyboard'];

  @HostBinding('attr.tabindex')
  @Input()
  tabIndex = 0;

  @HostBinding('attr.aria-disabled')
  @Input()
  disabled: boolean;

  @Input()
  set enableDirective(value: boolean) {
    this.enabled$.next(value === undefined || value);
  }

  @HostBinding('attr.role') role: string;

  private originalRole: string;
  private readonly subscription: Subscription = new Subscription();
  private readonly enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly focusMonitor: FocusMonitor,
    protected readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.originalRole = this.role;
    this.manageDirective();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private manageDirective(): void {
    this.subscription.add(
      this.enabled$
        .asObservable()
        .pipe(
          switchMap((isEnabled: boolean) => {
            if (isEnabled) {
              this.role = 'button';
              this.addFocusResetClass();
              return merge(this.registerActionKeyboard(), this.registerFocusStyleManager());
            } else {
              this.role = this.originalRole;
              this.removeFocusResetClass();
              return of(void 0);
            }
          })
        )
        .subscribe()
    );
  }

  private registerFocusStyleManager() {
    const removeFocusClassOnBlur$: Observable<void> = fromEvent(this.elementRef.nativeElement, 'blur').pipe(
      tap(() => this.removeFocusClass()),
      mapTo(void 0)
    );

    const addFocusClassOnFocus$: Observable<void> = this.focusMonitor.monitor(this.elementRef.nativeElement).pipe(
      filter(origin => this.focusOrigins.indexOf(origin as string) !== -1),
      tap(() => this.addFocusClass()),
      mapTo(void 0)
    );
    return merge(removeFocusClassOnBlur$, addFocusClassOnFocus$);
  }

  private addFocusClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'bpce-focus-style');
  }

  private addFocusResetClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'bpce-focus-reset');
  }

  private removeFocusClass(): void {
    this.renderer.removeClass(this.elementRef.nativeElement, 'bpce-focus-style');
  }

  private removeFocusResetClass(): void {
    this.renderer.removeClass(this.elementRef.nativeElement, 'bpce-focus-reset');
  }

  private registerActionKeyboard(): Observable<void> {
    return fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
      filter((e: KeyboardEvent) => KeyboardUtils.isAccessibilityKey(e)),
      tap(event => event.stopImmediatePropagation()),
      tap(event => event.stopPropagation()),
      tap(event => event.preventDefault()),
      filter((e: KeyboardEvent) => !e.repeat),
      tap(() => this.elementRef.nativeElement.click()),
      mapTo(void 0)
    );
  }
}
