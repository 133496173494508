import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { UnsubscriberComponent } from '@bpce/utils';
import { fromEvent } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UiToasterContentDirective } from './toaster-content.directive';

const DURATION = 600;
/**
 * @dynamic
 */
@Component({
  selector: 'ui-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiToasterComponent extends UnsubscriberComponent implements OnInit, OnDestroy {
  private static NB_INSTANCE = 1;

  @ViewChild('toaster', { static: true }) toasterElement: ElementRef;

  @ViewChild('container', { static: true }) toasterContainer: ElementRef;

  @ViewChild(CdkTrapFocus, { static: true }) focusDirective: CdkTrapFocus;

  @ViewChild(UiToasterContentDirective, { static: true }) toasterContent: UiToasterContentDirective;

  @Input() id: string;
  @Input() showButton: boolean;
  @Input() buttonText: string;
  @Output() afterClose = new EventEmitter<void>();
  @Output() backdropClick = new EventEmitter<void>();
  @Output() buttonClick = new EventEmitter<void>();

  closing = false;

  // Had to add @dynamics : https://github.com/angular/angular/issues/20351
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  get toaster(): HTMLElement {
    return this.toasterElement.nativeElement;
  }

  ngOnInit(): void {
    // Set autogenerated id if not provided
    if (!this.id) {
      this.id = `bpce-toaster-${UiToasterComponent.NB_INSTANCE++}`;
    }

    // Have to listen for the first tab
    // https://github.com/angular/components/issues/10156
    fromEvent(document, 'keydown')
      .pipe(
        filter((e: KeyboardEvent) => e.key === 'Tab'),
        first(),
        this.autoUnsubscriber()
      )
      .subscribe(() => {
        if (!this.focusDirective.focusTrap.focusFirstTabbableElement()) {
          // focus on container if no focusable element
          this.renderer.setAttribute(this.toasterContainer.nativeElement, 'tabindex', '0');
          this.toasterContainer.nativeElement.focus();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  close(): void {
    this.closing = true;
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      this.afterClose.emit();
    }, DURATION);
  }

  onOverlayClick(): void {
    this.backdropClick.emit();
  }

  onButtonClick(): void {
    this.buttonClick.emit();
  }

  attachContent<T>(cmp: ComponentType<T>, componentInjector: Injector): ComponentRef<T> {
    return this.toasterContent.attach(cmp, componentInjector);
  }
}
