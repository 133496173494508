import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

export enum InfoBoxType {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  ERROR = 'error'
}

const MARGIN_BOTTOM = 24;

@Component({
  selector: 'ui-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBoxComponent implements OnChanges {
  @Input() title: string;
  @Input() type: InfoBoxType = InfoBoxType.DEFAULT;
  @Input() showButton = false;
  @Input() buttonLabel: string;
  @Input() fontIconName: string;
  @Input() marginBottom = MARGIN_BOTTOM;
  @Output() buttonClick = new EventEmitter<void>();
  fontIcon: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title || changes.type || changes.fontIconName) {
      this.fontIcon = this.fontIconName ? this.fontIconName : this.getFontIcon();
    }
  }

  getFontIcon(): string {
    switch (this.type) {
      case InfoBoxType.WARNING:
        return 'warning';
      case InfoBoxType.ERROR:
        return 'erase';
      default:
        return 'information';
    }
  }
}
