import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import {TabComponent} from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterContentInit {
  @Input('data-default-tab') defaultTab;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.defaultTab) {
      this.setSelected(this.defaultTab);
    }
  }

  setSelected(index: number) {
    this.tabs.forEach((tab, i) => {
      if (i === index) {
        tab.show();
      } else {
        tab.hide();
      }
    });
  }
}
