export const KEY = {
  ADD: '+',
  ALT: 'Alt',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  BACK_QUOTE: '`',
  BACK_SLASH: '\\',
  CAPS_LOCK: 'CapsLock',
  CLEAR: 'Clear',
  CLOSE_BRACKET: ']',
  COMMA: ',',
  CONTEXT_MENU: 'ContextMenu',
  CONTROL: 'Control',
  COPY: 'Copy',
  CRSEL: 'CrSel',
  CUT: 'Cut',
  DASH: '-',
  DECIMAL_POINT: '.',
  DELETE: 'Delete',
  DIVIDE: '/',
  END: 'End',
  ENTER: 'Enter',
  EQUAL: '=',
  ERASE_TO_END_OF: 'EraseEof',
  ESCAPE: 'Escape',
  EXSEL: 'ExSel',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  HOME: 'Home',
  INSERT: 'Insert',
  MEDIA_PLAYER: 'LaunchMediaPlayer',
  MULTIPLY: '*',
  NUM_LOCK: 'NumLock',
  OPEN_BRACKET: '[',
  PAGE_DOWN: 'PageDown',
  PAGE_UP: 'PageUp',
  PASTE: 'Paste',
  PAUSE: 'Pause',
  PERIOD: '.',
  PRINT: 'PrintScreen',
  SCROLL_LOCK: 'ScrollLock',
  SEMI_COLON: ';',
  SHIFT: 'Shift',
  SINGLE_QUOTE: "'",
  SLASH: '/',
  SPACE: ' ',
  SUBTRACT: '-',
  REDO: 'Redo',
  TAB: 'Tab',
  UNDO: 'Undo',
  UNIDENTIFIED: 'Unidentified',
  VOLUME_DOWN: 'AudioVolumeDown',
  VOLUME_MUTE: 'AudioVolumeMute',
  VOLUME_UP: 'AudioVolumeUp',
};

export const IE_KEY = {
  ARROW_DOWN: 'Down',
  ARROW_LEFT: 'Left',
  ARROW_RIGHT: 'Right',
  ARROW_UP: 'Up',
  CONTEXT_MENU: 'Apps',
  CRSEL: 'Crsel',
  DELETE: 'Del',
  EXSEL: 'Exsel',
  ESCAPE: 'Escape',
  MEDIA_PLAYER: 'SelectMedia',
  SCROLL_LOCK: 'Scroll',
  SPACE: 'Spacebar',
};

export const IE_KEYCODES = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  DELETE: 46,
  ESCAPE: 27,
  SPACE: 32,
};

const normalizedKeyMap = new Map<string, string>([
  [IE_KEY.ARROW_LEFT, KEY.ARROW_LEFT],
  [IE_KEY.ARROW_UP, KEY.ARROW_UP],
  [IE_KEY.ARROW_RIGHT, KEY.ARROW_RIGHT],
  [IE_KEY.ARROW_DOWN, KEY.ARROW_DOWN],
  [IE_KEY.CONTEXT_MENU, KEY.CONTEXT_MENU],
  [IE_KEY.CRSEL, KEY.CRSEL],
  [IE_KEY.DELETE, KEY.DELETE],
  [IE_KEY.ESCAPE, KEY.ESCAPE],
  [IE_KEY.EXSEL, KEY.EXSEL],
  [IE_KEY.SCROLL_LOCK, KEY.SCROLL_LOCK],
  [IE_KEY.SPACE, KEY.SPACE],
]);

const normalizedKeyCodeMap = new Map<number, string>([
  [IE_KEYCODES.SPACE, KEY.SPACE],
  [IE_KEYCODES.ARROW_LEFT, KEY.ARROW_LEFT],
  [IE_KEYCODES.ARROW_UP, KEY.ARROW_UP],
  [IE_KEYCODES.ARROW_RIGHT, KEY.ARROW_RIGHT],
  [IE_KEYCODES.ARROW_DOWN, KEY.ARROW_DOWN],
  [IE_KEYCODES.DELETE, KEY.DELETE],
  [IE_KEYCODES.ESCAPE, KEY.ESCAPE],
]);

const numberRegex = new RegExp(/^\d+$/);
export const specialKeys = [
  KEY.BACKSPACE,
  KEY.TAB,
  KEY.END,
  KEY.HOME,
  KEY.ARROW_LEFT,
  KEY.ARROW_RIGHT,
  KEY.DELETE,
  KEY.ADD,
  KEY.ALT,
  KEY.CONTROL,
  KEY.SHIFT,
  KEY.CAPS_LOCK,
  KEY.F1,
  KEY.F2,
  KEY.F3,
  KEY.F4,
  KEY.F5,
  KEY.F6,
  KEY.F7,
  KEY.F8,
  KEY.F9,
  KEY.F10,
  KEY.F11,
  KEY.F12,
];
export const forMetaKeys = ['c', 'x', 'a', 'v', 'z', 'y'];

// @dynamic
export abstract class KeyboardUtils {
  static normalizeKey(key: string): string;
  static normalizeKey(ogKey: string | number): string | number;
  static normalizeKey(ogKey: string | number): string | number {
    if (typeof ogKey === 'number') {
      return normalizedKeyCodeMap.get(ogKey) ?? ogKey;
    }
    return normalizedKeyMap.get(ogKey) ?? ogKey;
  }

  static wasKeyPressed(codes: Array<string | number>, event: KeyboardEvent): boolean {
    // prettier-ignore
    // eslint-disable-next-line spaced-comment
    if (!codes || !codes.length || !event || !(event.key || event.keyCode)) { // NOSONAR
      return false;
    }

    // prettier-ignore
    // eslint-disable-next-line spaced-comment
    const pressedKey = KeyboardUtils.normalizeKey(event.key || event.keyCode); // NOSONAR
    const matchingKeys = codes.map(kc => KeyboardUtils.normalizeKey(kc));

    return matchingKeys.some(key => pressedKey === key);
  }

  static isNumericCharacter(event: KeyboardEvent): boolean {
    return numberRegex.test(KeyboardUtils.normalizeKey(event.key));
  }

  static isSpecialCharacter(event: KeyboardEvent): boolean {
    return specialKeys.indexOf(KeyboardUtils.normalizeKey(event.key)) !== -1;
  }

  static isDeleteCharacter(event: KeyboardEvent): boolean {
    // prettier-ignore
    // eslint-disable-next-line spaced-comment
    const key = KeyboardUtils.normalizeKey(event.key || event.keyCode); // NOSONAR
    return key === KEY.DELETE || key === KEY.BACKSPACE;
  }

  static isMetaCombinaison(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && forMetaKeys.indexOf(event.key) !== -1;
  }

  static isBackspaceKey(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key) === KEY.BACKSPACE;
  }

  static isCutShortcut(event: KeyboardEvent): boolean {
    return event.key === KEY.CUT || (event.ctrlKey && event.key.toLowerCase() === 'x');
  }

  static isCopyShortcut(event: KeyboardEvent): boolean {
    return event.key === KEY.COPY || (event.ctrlKey && event.key.toLowerCase() === 'c');
  }

  static isPasteShortcut(event: KeyboardEvent): boolean {
    return event.key === KEY.PASTE || (event.ctrlKey && event.key.toLowerCase() === 'v');
  }

  static isAddKey(event: KeyboardEvent): boolean {
    return event.key === KEY.ADD;
  }

  static isTabKey(event: KeyboardEvent): boolean {
    return event.key === KEY.TAB;
  }

  static isEscapeKey(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.ESCAPE;
  }

  static isArrowRight(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.ARROW_RIGHT;
  }

  static isArrowLeft(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.ARROW_LEFT;
  }

  static isArrowDown(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.ARROW_DOWN;
  }

  static isArrowUp(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.ARROW_UP;
  }

  static isEnterKey(event: KeyboardEvent): boolean {
    return event.key === KEY.ENTER;
  }

  static isSpaceKey(event: KeyboardEvent): boolean {
    return KeyboardUtils.normalizeKey(event.key || event.keyCode) === KEY.SPACE;
  }

  static isAccessibilityKey(event: KeyboardEvent): boolean {
    const key = KeyboardUtils.normalizeKey(event.key || event.keyCode);
    return key === KEY.ENTER || key === KEY.SPACE;
  }
}
