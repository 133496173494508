<span
  [ngClass]="iconClass"
  [ngStyle]="{
    'font-size.px': fontIconSize,
    'height.px': svgIconSize,
    'width.px': svgIconSize,
    'padding.px': fontIcon ? 0 : svgIconPadding
  }"
  [attr.aria-hidden]="!ariaLabel"
  [attr.aria-label]="ariaLabel ? ariaLabel : null"
  [attr.role]="ariaLabel ? 'img' : null"
  class="ui-icon"
>
  <ng-template [ngIf]="svgIcon"> <div [innerHTML]="svgFile$ | async" class="svg"></div> </ng-template>
</span>
