// THIS FILE IS GENERATED, PLEASE DO NOT EDIT DIRECTLY

export const themes = [
  {
    primary: '#581374',
    secondary: '#c40050',
    href: '89c3.css',
    logo: '89c3/img/logo-small.svg',
    brand: '89c3',
    name: '89C3',
    favicon: '89c3/favicons/favicon.ico'
  },
  {
    primary: '#d1005d',
    secondary: '#4a4a4a',
    href: 'bcp.css',
    logo: 'bcp/img/logo-small.svg',
    brand: 'bcp',
    name: 'Banque Commerciale du Portugal',
    favicon: 'bcp/favicons/favicon.ico'
  },
  {
    primary: '#e30613',
    secondary: '#2e3642',
    href: 'bds.css',
    logo: 'bds/img/logo-small.svg',
    brand: 'bds',
    name: 'Banque de Savoie',
    favicon: 'bds/favicons/favicon.ico'
  },
  {
    primary: '#051b71',
    secondary: '#369ad4',
    href: 'bp.css',
    logo: 'bp/img/logo-small.svg',
    brand: 'bp',
    name: 'Banque Populaire',
    favicon: 'bp/favicons/favicon.ico'
  },
  {
    primary: '#581d74',
    secondary: '#666666',
    href: 'bpce.css',
    logo: 'bpce/img/logo-small.svg',
    brand: 'bpce',
    name: 'Groupe BPCE',
    favicon: 'bpce/favicons/favicon.ico'
  },
  {
    primary: '#c70d00',
    secondary: '#1e1e1e',
    href: 'bpcp.css',
    logo: 'bpcp/img/logo-small.svg',
    brand: 'bpcp',
    name: 'Banque Palatine Entreprises',
    favicon: 'bpcp/favicons/favicon.ico'
  },
  {
    primary: '#1e1e1e',
    secondary: '#c70d00',
    href: 'bpe.css',
    logo: 'bpe/img/logo-small.svg',
    brand: 'bpe',
    name: 'Banque Palatine Particuliers',
    favicon: 'bpe/favicons/favicon.ico'
  },
  {
    primary: '#62120f',
    secondary: '#000000',
    href: 'bqpa.css',
    logo: 'bqpa/img/logo-small.svg',
    brand: 'bqpa',
    name: 'Banque Palatine',
    favicon: 'bqpa/favicons/favicon.ico'
  },
  {
    primary: '#0b569d',
    secondary: '#4a5a64',
    href: 'btp.css',
    logo: 'btp/img/logo-small.svg',
    brand: 'btp',
    name: 'BTP Banque',
    favicon: 'btp/favicons/favicon.ico'
  },
  {
    primary: '#369ad4',
    secondary: '#333333',
    href: 'casden.css',
    logo: 'casden/img/logo-small.svg',
    brand: 'casden',
    name: 'Casden',
    favicon: 'casden/favicons/favicon.ico'
  },
  {
    primary: '#c8102e',
    secondary: '#ec6919',
    href: 'cc.css',
    logo: 'cc/img/logo-small.svg',
    brand: 'cc',
    name: 'Crédit Coopératif',
    favicon: 'cc/favicons/favicon.ico'
  },
  {
    primary: '#d7000f',
    secondary: '#3e454a',
    href: 'ce.css',
    logo: 'ce/img/logo-small.svg',
    brand: 'ce',
    name: "Caisse d'Épargne",
    favicon: 'ce/favicons/favicon.ico'
  },
  {
    primary: '#007988',
    secondary: '#e30613',
    href: 'cf.css',
    logo: 'cf/img/logo-small.svg',
    brand: 'cf',
    name: 'Crédit Foncier de France',
    favicon: 'cf/favicons/favicon.ico'
  },
  {
    primary: '#0061a1',
    secondary: '#0091cc',
    href: 'cm.css',
    logo: 'cm/img/logo-small.svg',
    brand: 'cm',
    name: 'Crédit Maritime',
    favicon: 'cm/favicons/favicon.ico'
  },
  {
    primary: '#0061a1',
    secondary: '#0091cc',
    href: 'cmgo.css',
    logo: 'cmgo/img/logo-small.svg',
    brand: 'cmgo',
    name: 'Crédit Maritime Grand Ouest',
    favicon: 'cmgo/favicons/favicon.ico'
  },
  {
    primary: '#7c252c',
    secondary: '#f9b237',
    href: 'dpp.css',
    logo: 'dpp/img/logo-small.svg',
    brand: 'dpp',
    name: 'Banque Dupuy de Parseval',
    favicon: 'dpp/favicons/favicon.ico'
  },
  {
    primary: '#136aba',
    secondary: '#293133',
    href: 'mz.css',
    logo: 'mz/img/logo-small.svg',
    brand: 'mz',
    name: 'Banque Marze',
    favicon: 'mz/favicons/favicon.ico'
  },
  {
    primary: '#581374',
    secondary: '#c40050',
    href: 'neo.css',
    logo: 'neo/img/logo-small.svg',
    brand: 'neo',
    name: 'NEO',
    favicon: 'neo/favicons/favicon.ico'
  },
  {
    primary: '#581d74',
    secondary: '#ae1580',
    href: 'nx.css',
    logo: 'nx/img/logo-small.svg',
    brand: 'nx',
    name: 'Natixis',
    favicon: 'nx/favicons/favicon.ico'
  },
  {
    primary: '#581d74',
    secondary: '#666666',
    href: 'socfim.css',
    logo: 'socfim/img/logo-small.svg',
    brand: 'socfim',
    name: 'Socfim',
    favicon: 'socfim/favicons/favicon.ico'
  }
];
