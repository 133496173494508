// THIS FILE IS GENERATED, PLEASE DO NOT EDIT DIRECTLY
import { BankInfo } from './bank-info.model';

export const bankInfoList: BankInfo[] = [
  {
    etab: '',
    bic: 'CCBPFRPPMTZ',
    label: 'Banque Populaire Alsace Lorraine Champagne',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPNAN',
    label: 'Banque Populaire Atlantique',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CMCIFRP1MON',
    label: 'Monabanq',
    shortLabel: '',
    color1: '#BAD606',
    color2: '#131313'
  },
  {
    etab: '',
    bic: 'COVEFR21',
    label: 'Monabanq',
    shortLabel: '',
    color1: '#BAD606',
    color2: '#131313'
  },
  {
    etab: '',
    bic: 'CCBPFRPPBDX',
    label: 'Banque Populaire Aquitaine Centre Atlantique',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPGRE',
    label: 'Banque Populaire Rhône Alpes',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPDJN',
    label: 'Banque Populaire Bourgogne Franche-Comté',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPLIL',
    label: 'Banque Populaire du Nord',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPPPG',
    label: 'Banque Populaire du Sud',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPBDX',
    label: 'Banque Populaire Grand Ouest',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPREN',
    label: "Banque Populaire de l'Ouest",
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPMAR',
    label: 'Banque Populaire Méditerranée',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPTLS',
    label: 'Banque Populaire Occitane',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPMTG',
    label: 'Banque Populaire Rives de Paris',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'CCBPFRPPVER',
    label: 'Banque Populaire Val de France',
    shortLabel: 'Banque Populaire',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '13335',
    bic: 'CEPAFRPP333',
    label: "Caisse d'Epargne Aquitaine Poitou-Charentes",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '18715',
    bic: 'CEPAFRPP871',
    label: "Caisse d'Epargne Auvergne Limousin",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '14445',
    bic: 'CEPAFRPP444',
    label: "Caisse d'Epargne Bretagne-Pays de Loire",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '18315',
    bic: 'CEPAFRPP831',
    label: "Caisse d'Epargne Côte d'Azur",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '16705',
    bic: 'CEPAFRPP670',
    label: "Caisse d'Epargne Grand Est Europe",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '12135',
    bic: 'CEPAFRPP213',
    label: "Caisse d'Epargne Bourgogne Franche-Comté",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '14265',
    bic: 'CEPAFRPP426',
    label: "Caisse d'Epargne Loire Drôme Ardèche",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '15135',
    bic: 'CEPAFRPP513',
    label: "Caisse d'Epargne Grand Est Europe",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '13135',
    bic: 'CEPAFRPP313',
    label: "Caisse d'Epargne Midi Pyrénées",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '13485',
    bic: 'CEPAFRPP348',
    label: "Caisse d'Epargne Languedoc Roussillon",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '17515',
    bic: 'CEPAFRPP751',
    label: "Caisse d'Epargne Ile-de-France",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '14505',
    bic: 'CEPAFRPP450',
    label: "Caisse d'Epargne Loire-Centre",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '16275',
    bic: 'CEPAFRPP627',
    label: "Caisse d'Epargne Hauts de France",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '11425',
    bic: 'CEPAFRPP142',
    label: "Caisse d'Epargne Normandie",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '11315',
    bic: 'CEPAFRPP131',
    label: "Caisse d'Epargne Provence Alpes Corse",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '13825',
    bic: 'CEPAFRPP382',
    label: "Caisse d'Epargne Rhône Alpes",
    shortLabel: "Caisse d'Epargne",
    color1: '#B30000',
    color2: '#F20000'
  },
  {
    etab: '',
    bic: 'SOAPFR22',
    label: 'Carrefour Banque',
    shortLabel: '',
    color1: '#004DA0',
    color2: '#004DA0'
  },
  {
    etab: '',
    bic: 'CMCIFRPP',
    label: 'CIC',
    shortLabel: '',
    color1: '#011D8F',
    color2: '#00838A'
  },
  {
    etab: '',
    bic: 'BNPAFR',
    label: 'BNP Paribas',
    shortLabel: '',
    color1: '#00925C',
    color2: '#26BB69'
  },
  {
    etab: '',
    bic: 'AECFFR',
    label: 'American Express France',
    shortLabel: '',
    color1: '#0052CB',
    color2: '#0052CB'
  },
  {
    etab: '',
    bic: 'AXABFR',
    label: 'Axa Banque',
    shortLabel: '',
    color1: '#00008F',
    color2: '#FF1721'
  },
  {
    etab: '12579',
    bic: 'BPSMFR',
    label: 'Banque BCP',
    shortLabel: '',
    color1: '#D1005D',
    color2: '#4A4A4A'
  },
  {
    etab: '',
    bic: 'BSAVFR',
    label: 'Banque de Savoie',
    shortLabel: '',
    color1: '#E30613',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'BSPFFR',
    label: 'Banque Palatine',
    shortLabel: '',
    color1: '#C62615',
    color2: '#C62615'
  },
  {
    etab: '',
    bic: 'BSPFFRPPXXX',
    label: 'Banque Palatine',
    shortLabel: '',
    color1: '#C62615',
    color2: '#C62615'
  },
  {
    etab: '',
    bic: 'BFBKFR',
    label: 'BforBank',
    shortLabel: '',
    color1: '#45342D',
    color2: '#8D6E2B'
  },
  {
    etab: '',
    bic: 'AGRIFR',
    label: 'Crédit Agricole',
    shortLabel: '',
    color1: '#008A91',
    color2: '#E20613'
  },
  {
    etab: '',
    bic: 'CMCIFR',
    label: 'Crédit Mutuel',
    shortLabel: '',
    color1: '#006BB6',
    color2: '#EF3E33'
  },
  {
    etab: '',
    bic: 'CMBRFR2BXXX',
    label: 'Crédit Mutuel Arkea',
    shortLabel: '',
    color1: '#006BB6',
    color2: '#EF3E33'
  },
  {
    etab: '',
    bic: 'GEBAFR',
    label: 'Hello Bank!',
    shortLabel: '',
    color1: '#39C1D2',
    color2: '#1B82A4'
  },
  {
    etab: '',
    bic: 'INGBFR',
    label: 'ING Direct',
    shortLabel: '',
    color1: '#000066',
    color2: '#FF6200'
  },
  {
    etab: '',
    bic: 'LCLPFR',
    label: 'LCL',
    shortLabel: '',
    color1: '#1C3490',
    color2: '#FCD003'
  },
  {
    etab: '',
    bic: 'CRLYFRPP',
    label: 'Crédit Lyonnais',
    shortLabel: '',
    color1: '#1C3490',
    color2: '#FCD003'
  },
  {
    etab: '',
    bic: 'CRLYFR',
    label: 'Crédit Lyonnais',
    shortLabel: '',
    color1: '#1C3490',
    color2: '#FCD003'
  },
  {
    etab: '',
    bic: 'CCFRFR',
    label: 'HSBC',
    shortLabel: '',
    color1: '#8B929A',
    color2: '#DB0011'
  },
  {
    etab: '',
    bic: 'GSCFFR',
    label: 'Société Générale',
    shortLabel: '',
    color1: '#E10030',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'SOGEFR',
    label: 'Société Générale',
    shortLabel: '',
    color1: '#E10030',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'SOGEFRPP',
    label: 'Société Générale',
    shortLabel: '',
    color1: '#E10030',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'PSSTFR',
    label: 'La Banque Postale',
    shortLabel: '',
    color1: '#FCC708',
    color2: '#18406F'
  },
  {
    etab: '',
    bic: 'BREDFR',
    label: 'Banque Populaire BRED',
    shortLabel: 'Banque Populaire BRED',
    color1: '#003C82',
    color2: '#16B1E6'
  },
  {
    etab: '',
    bic: 'NORDFR',
    label: 'Crédit Du Nord',
    shortLabel: '',
    color1: '#1D1D1B',
    color2: '#224884'
  },
  {
    etab: '',
    bic: '',
    label: 'Autre banque',
    shortLabel: '',
    color1: '#CCCCCC',
    color2: '#CCCCCC'
  },
  {
    etab: '',
    bic: 'CCFRFR',
    label: 'CCF',
    shortLabel: '',
    color1: '#8B929A',
    color2: '#DB0011'
  },
  {
    etab: '',
    bic: 'COBPFR',
    label: 'Banque 1818',
    shortLabel: '',
    color1: '#591D75',
    color2: '#591D75'
  },
  {
    etab: '',
    bic: 'CGDIFR',
    label: 'Caixa Geral de despositos',
    shortLabel: '',
    color1: '#003459',
    color2: '#003459'
  },
  {
    etab: '42559',
    bic: 'CCOPFR',
    label: 'Crédit Coopératif',
    shortLabel: '',
    color1: '#C50E1F',
    color2: '#666666'
  },
  {
    etab: '',
    bic: 'CFFRFR',
    label: 'Crédit Foncier',
    shortLabel: '',
    color1: '#007883',
    color2: '#007883'
  },
  {
    etab: '',
    bic: 'CMMMFR',
    label: 'Crédit Maritime',
    shortLabel: '',
    color1: '#0061A1',
    color2: '#0091CC'
  },
  {
    etab: '',
    bic: 'FTNOFR',
    label: 'Fortuneo Banque',
    shortLabel: '',
    color1: '#88C648',
    color2: '#232323'
  },
  {
    etab: '',
    bic: 'GPBAFR',
    label: 'Groupama Banque',
    shortLabel: '',
    color1: '#006C58',
    color2: '#006C58'
  },
  {
    etab: '',
    bic: 'NATXFR',
    label: 'Natixis',
    shortLabel: '',
    color1: '#7D5B98',
    color2: '#7D5B98'
  },
  {
    etab: '',
    bic: 'GPBAFR',
    label: 'Orange Banque',
    shortLabel: '',
    color1: '#F47820',
    color2: '#030405'
  },
  {
    etab: '',
    bic: 'AGFBFRCC',
    label: 'Allianz Banque',
    shortLabel: '',
    color1: '#000000',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'SMCTFR',
    label: 'Société Marseillaise de Crédit',
    shortLabel: '',
    color1: '#000000',
    color2: '#000000'
  },
  {
    etab: '',
    bic: 'REVOGB21',
    label: 'Revolut',
    shortLabel: '',
    color1: '#CCCCCC',
    color2: '#CCCCCC'
  },
  {
    etab: '',
    bic: 'BOUSFRPP',
    label: 'Boursorama',
    shortLabel: '',
    color1: '#D20073',
    color2: '#009DE0'
  },
  {
    etab: '',
    bic: '',
    label: 'N26',
    shortLabel: '',
    color1: '#000000',
    color2: '#48C3CA'
  },
  {
    etab: '',
    bic: '',
    label: 'MilleniumBCP',
    shortLabel: '',
    color1: '#D1005D',
    color2: '#2E3641'
  },
  {
    etab: '',
    bic: '',
    label: 'BTPBanque',
    shortLabel: '',
    color1: '#0B569D',
    color2: '#4A5A64'
  },
  {
    etab: '',
    bic: '',
    label: 'Barclays',
    shortLabel: '',
    color1: '#3B3836',
    color2: '#C29366'
  },
  {
    etab: '',
    bic: '',
    label: 'DeutscheBank',
    shortLabel: '',
    color1: '#0018A8',
    color2: '#0018A8'
  },
  {
    etab: '',
    bic: '',
    label: 'RoyalBankofscotland',
    shortLabel: '',
    color1: '#15366F',
    color2: '#017EA6'
  },
  {
    etab: '',
    bic: '',
    label: 'Santander',
    shortLabel: '',
    color1: '#EB0000',
    color2: '#6D6D6D'
  },
  {
    etab: '',
    bic: '',
    label: 'Lloyds',
    shortLabel: '',
    color1: '#15854F',
    color2: '#231F20'
  },
  {
    etab: '',
    bic: '',
    label: 'UBS',
    shortLabel: '',
    color1: '#010101',
    color2: '#C29366'
  },
  {
    etab: '',
    bic: '',
    label: 'Unicredit',
    shortLabel: '',
    color1: '#E2001A',
    color2: '#B01301'
  },
  {
    etab: '',
    bic: '',
    label: 'CreditSuisse',
    shortLabel: '',
    color1: '#003662',
    color2: '#003662'
  },
  {
    etab: '',
    bic: '',
    label: 'Nordea',
    shortLabel: '',
    color1: '#0000A0',
    color2: '#0000A0'
  },
  {
    etab: '',
    bic: '',
    label: 'BancoBilbao',
    shortLabel: '',
    color1: '#00336A',
    color2: '#00336A'
  },
  {
    etab: '',
    bic: '',
    label: 'BancaIntesaSanpaolo',
    shortLabel: '',
    color1: '#21632F',
    color2: '#DA6A10'
  },
  {
    etab: '',
    bic: '',
    label: 'Rabobank',
    shortLabel: '',
    color1: '#FD6401',
    color2: '#0000A6'
  },
  {
    etab: '',
    bic: '',
    label: 'Commerzbank',
    shortLabel: '',
    color1: '#1D1D1B',
    color2: '#FBBB13'
  },
  {
    etab: '',
    bic: '',
    label: '',
    shortLabel: '',
    color1: '#CCCCCC',
    color2: '#CCCCCC'
  },
  {
    etab: '',
    bic: 'DELUFR22XXX',
    label: 'Banque Delubac et Cie',
    shortLabel: '',
    color1: '#5E2750',
    color2: '#FFFFFF'
  }
];
