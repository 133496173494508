import {NgModule} from "@angular/core";
import {HttpClientModule} from "@angular/common/http";
import {CcoopApiService} from "./services/ccoop-api.service";


@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    CcoopApiService,
  ]
})
export class ApiModule {
}
