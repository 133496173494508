import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModalModule } from '@bpce/ui/modal';
import { InterstitialService } from './interstitial.service';

@NgModule({
  imports: [CommonModule, UiModalModule],
  providers: [InterstitialService]
})
export class UiInterstitialModule {}
