import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BpceSubscriberDirective } from './directives/bpce-subscriber.directive';
import { BpceThrottleClickDirective } from './directives/bpce-throttle-click.directive';
import {
  BpceBankCardRedaction,
  BpceCustomRedaction,
  BpceEmailRedaction,
  BpcePhoneRedaction,
} from './pipes/bpce-caviardage';
import { BankInfoService } from './services/bank-info/bank-info.service';
import { UiInputListService } from './services/input-list.service';
import { UtilsService } from './services/utils.service';

@NgModule({
  declarations: [
    BpceThrottleClickDirective,
    BpceSubscriberDirective,
    BpceBankCardRedaction,
    BpceCustomRedaction,
    BpceEmailRedaction,
    BpcePhoneRedaction,
  ],
  imports: [CommonModule],
  providers: [UtilsService, BankInfoService, UiInputListService],
  exports: [
    BpceThrottleClickDirective,
    BpceSubscriberDirective,
    BpceBankCardRedaction,
    BpceCustomRedaction,
    BpceEmailRedaction,
    BpcePhoneRedaction,
  ],
})
export class UtilsModule {}
