import {Component, EventEmitter, HostListener, Inject, Input, Output} from '@angular/core';
import {HelpReferentiel} from "../../../../../../../models/Referentiel";
import {Observable} from "rxjs";
import {ReferentielService} from "../../../data/services/referentiel.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BPCE_MODAL_DATA} from "@bpce/ui/modal";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {map} from "rxjs/operators";
@Component({
  selector: 'app-iframe-modale',
  templateUrl: './i-frame-modale.component.html',
  styleUrls: ['./i-frame-modale.component.scss']
})
export class IFrameModaleComponent {
  public readonly closed = new EventEmitter<void>()

  public sanitizedUrl$: Observable<SafeResourceUrl>
  public title$: Observable<string>
  public iframeTitle$: Observable<string>

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closed.emit()
  }

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(BPCE_MODAL_DATA) public param$: Observable<{url: string, title: string, iFrameTitle: string}>
  ) {
    this.title$ = param$.pipe(map(param => param.title))
    this.iframeTitle$ = param$.pipe(map(param => param.iFrameTitle ?? ''))
    this.sanitizedUrl$ = param$.pipe(map(param => this.sanitizer.bypassSecurityTrustResourceUrl(param.url)))
  }
}
