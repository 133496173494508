
<ul>
  <li>
    <img src="assets/chrome-logo.png" alt="">
    <div class="browser-name">Chrome</div>
    <app-external-link label="Télécharger" title="Télécharger Chrome" [showArrow]="false" [isLink]="true" [iframeModale]="false" [url]="'https://www.google.com/intl/fr/chrome/'"></app-external-link>
  </li>

  <li>
    <img src="assets/firefox-logo.png" alt="">
    <div class="browser-name">Firefox</div>
    <app-external-link label="Télécharger"title="Télécharger Firefox" [showArrow]="false" [isLink]="true" [iframeModale]="false" [url]="'https://www.mozilla.org/fr/firefox/new/'"></app-external-link>
  </li>

  <li>
    <img src="assets/edge-logo.png" alt="">
    <div class="browser-name">Edge</div>
    <app-external-link label="Télécharger" title="Télécharger Edge" [showArrow]="false" [isLink]="true" [iframeModale]="false" [url]="'https://www.microsoft.com/fr-fr/edge'"></app-external-link>
  </li>
</ul>
