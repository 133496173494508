import { ComponentType } from '@angular/cdk/overlay';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  HostBinding,
  Injector,
  ViewContainerRef
} from '@angular/core';

/**
 * Modal content wrapper used to inject the modal content dynamically
 */
@Directive({
  selector: '[uiModalContentWrapper]',
  exportAs: 'uiModalContentWrapper'
})
export class UiModalContentWrapper {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  attach<T>(cmp: ComponentType<T>, injector: Injector | undefined): ComponentRef<T> {
    const cmpFactory = this.componentFactoryResolver.resolveComponentFactory<T>(cmp);
    return this.viewContainerRef.createComponent(cmpFactory, this.viewContainerRef.length, injector);
  }
}

/**
 * Title of a modal element. Stays fixed to the top of the modal when scrolling.
 */
@Directive({
  selector: '[ui-modal-title], [uiModalTitle], ui-modal-title',
  exportAs: 'uiModalTitle'
})
export class UiModalTitle {
  @HostBinding('class.bpce-modal-header') readonly headerClass = true;
}

/**
 * Scrollable content container of a modal.
 */
@Directive({
  selector: `[ui-modal-content], ui-modal-content, [uiModalContent]`
})
export class UiModalContent {
  @HostBinding('class.bpce-modal-body') readonly bodyClass = true;
}

/**
 * Container for the bottom action buttons in a modal.
 * Stays fixed to the bottom when scrolling.
 */
@Directive({
  selector: `[ui-modal-actions], ui-modal-actions, [uiModalActions]`
})
export class UiModalActions {
  @HostBinding('class.bpce-modal-footer') readonly footerClass = true;
}
