import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { KeyboardUtils } from '@bpce/utils';

/**
 * @deprecated Use UiButtonV2, UiButton will be removed in @bpce/ui@^14.0.0
 */
@Component({
  selector: 'button[uiButton], button[ui-button], a[uiButton], a[ui-button]',
  exportAs: 'uiButton',
  templateUrl: './button.html',
  styleUrls: ['./button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiButtonComponent implements OnChanges, OnDestroy, AfterContentInit, AfterViewInit {
  @Input() disabled = false;
  @Input() disableRipple = false;
  @Input() tabIndex: number;

  @HostBinding('class.ui-button')
  uiButton = true;

  private originalHref: string;
  private selected = false;
  private selectedObserver: MutationObserver;

  constructor(
    private readonly _elRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    if (this._elRef.nativeElement) {
      this.selected = this._elRef.nativeElement.classList.contains('selected');
    }
  }

  ngAfterViewInit(): void {
    if (this._elRef.nativeElement) {
      this.selectedObserver = new MutationObserver(mutationList => this.onClassChange(mutationList));
      this.selectedObserver.observe(this._elRef.nativeElement, { attributes: true });
    }
  }

  ngOnDestroy(): void {
    if (this.selectedObserver) {
      this.selectedObserver.disconnect();
    }
  }

  @HostBinding('attr.tabindex')
  get _tabIndex(): number {
    return this.disabled ? -1 : this.tabIndex || 0;
  }

  @HostBinding('attr.disabled')
  get _disabled(): boolean | null {
    return this.disabled || null;
  }

  @HostBinding('attr.aria-disabled')
  get _ariaDisabled(): string {
    return this.disabled.toString();
  }

  @HostBinding('attr.aria-selected')
  get _ariaSelected(): string {
    return this.selected.toString();
  }

  @HostBinding('class.bpce-icon')
  @HostBinding('class.check')
  get _selectedIcon(): boolean {
    return this.selected;
  }

  @HostListener('keyup', ['$event'])
  _onKeyupEvent(event: KeyboardEvent): void {
    if (KeyboardUtils.isTabKey(event)) {
      this.renderer.removeClass(this._elRef.nativeElement, 'no-focus-visible');
    }
  }

  @HostListener('mousedown')
  _onMouseDownEvent(): void {
    this.renderer.addClass(this._elRef.nativeElement, 'no-focus-visible');
  }

  _getHostElement(): unknown {
    return this._elRef.nativeElement;
  }

  _isRippleDisabled(): boolean {
    return this.disableRipple || this.disabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (this.disabled) {
        if (this._elRef.nativeElement.href) {
          this.originalHref = this._elRef.nativeElement.href;
          this.renderer.removeAttribute(this._elRef.nativeElement, 'href');
        }
      } else if (this.originalHref) {
        this.renderer.setAttribute(this._elRef.nativeElement, 'href', this.originalHref);
      }
    }
  }

  onClassChange(mutationList: MutationRecord[]): void {
    const hasClassMutation = mutationList.filter(item => item.attributeName === 'class').length;

    if (!hasClassMutation) {
      return;
    }

    this.selected = this._elRef.nativeElement.classList.contains('selected');
    this.changeDetector.detectChanges();
  }
}
