import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-body',
  templateUrl: './page-body.component.html',
  styleUrls: ['./page-body.component.scss']
})
export class PageBodyComponent {
  @Input() title: string
  @Input() centered: boolean = false

  constructor() {
  }
}
