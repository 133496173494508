<div #toaster [attr.id]="id" [ngClass]="{ 'bpce-toaster-hide': closing }" cdkTrapFocus class="bpce-toaster">
  <span (click)="onOverlayClick()" class="bpce-toaster-overlay"></span>
  <div #container class="bpce-toaster-container">
    <div class="bpce-toaster-content">
      <ng-template uiToasterContent></ng-template>
    </div>
  </div>
  <button
    (click)="onButtonClick()"
    [ngClass]="{ 'bpce-toaster-button-show': showButton }"
    class="bpce-toaster-button primary"
    type="button"
    ui-button
  >
    {{ buttonText }}
  </button>
</div>
