import { BrowsersEnum, DevicesEnum, OSEnum, OSVersionEnum } from '../models/information.model';
import { ConditionalRegexpModel } from '../models/regexp.model';

export type RegexpMap<T> = ReadonlyMap<T, ConditionalRegexpModel>;

export const OS_RE_MAP: RegexpMap<OSEnum> = new Map([
  [OSEnum.WINDOWS, { and: [{ or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] }, { not: { raw: /\bWindows Phone\b/ } }] }],
  [OSEnum.MAC, { and: [{ raw: /\bMac OS\b/ }, { not: { raw: /Windows Phone/ } }] }],
  [OSEnum.IOS, { and: [{ or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] }, { not: { raw: /Windows Phone/ } }] }],
  [OSEnum.ANDROID, { and: [{ raw: /\bAndroid\b/ }, { not: { raw: /Windows Phone/ } }] }],
  [OSEnum.LINUX, { raw: /\bLinux\b/ }],
  [OSEnum.UNIX, { raw: /\bUNIX\b/ }],
  [OSEnum.FIREFOX_OS, { and: [{ raw: /\bFirefox\b/ }, { raw: /Mobile\b/ }] }],
  [OSEnum.CHROME_OS, { raw: /\bCrOS\b/ }],
  [OSEnum.WINDOWS_PHONE, { or: [/\bIEMobile\b/, /\bWindows Phone\b/] }],
]);

export const BROWSERS_RE_MAP: RegexpMap<BrowsersEnum> = new Map([
  [BrowsersEnum.CHROME, { and: [{ or: [/\bChrome\b/, /\bCriOS\b/] }, { not: { or: [/\bOPR\b/, /\bEdge\b/] } }] }],
  [BrowsersEnum.FIREFOX, { raw: /\bFirefox\b/ }],
  [BrowsersEnum.PHANTOMJS, { raw: /\bPhantomJS\b/ }],
  [
    BrowsersEnum.SAFARI,
    { and: [{ raw: /^((?!CriOS).)*\Safari\b.*$/ }, { not: { or: [/\bOPR\b/, /\bEdge\b/, /Windows Phone/] } }] },
  ],
  [BrowsersEnum.OPERA, { or: [/Opera\b/, /\bOPR\b/] }],
  [BrowsersEnum.IE, { or: [/\bMSIE\b/, /\bTrident\b/, /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\)$/] }],
  [BrowsersEnum.MS_EDGE, { or: [/\bEdge\b/] }],
  [BrowsersEnum.FB_MESSENGER, { raw: /\bFBAN\/MessengerForiOS\b/ }],
]);

export const DEVICES_RE_MAP: RegexpMap<DevicesEnum> = new Map([
  [DevicesEnum.ANDROID, { and: [{ raw: /\bAndroid\b/ }, { not: { raw: /Windows Phone/ } }] }],
  [DevicesEnum.I_PAD, { raw: /\biPad\b/ }],
  [DevicesEnum.IPHONE, { and: [{ raw: /\biPhone\b/ }, { not: { raw: /Windows Phone/ } }] }],
  [DevicesEnum.I_POD, { raw: /\biPod\b/ }],
  [DevicesEnum.BLACKBERRY, { raw: /\bblackberry\b/ }],
  [DevicesEnum.FIREFOX_OS, { and: [{ raw: /\bFirefox\b/ }, { raw: /\bMobile\b/ }] }],
  [DevicesEnum.CHROME_BOOK, { raw: /\bCrOS\b/ }],
  [DevicesEnum.WINDOWS_PHONE, { or: [/\bIEMobile\b/, /\bWindows Phone\b/] }],
  [DevicesEnum.PS4, { raw: /\bMozilla\/5.0 \(PlayStation 4\b/ }],
  [DevicesEnum.CHROME_CAST, { raw: /\bCrKey\b/ }],
  [DevicesEnum.APPLE_TV, { raw: /^iTunes-AppleTV\/4.1$/ }],
  [DevicesEnum.GOOGLE_TV, { raw: /\bGoogleTV\b/ }],
  [DevicesEnum.VITA, { raw: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/ }],
]);

export const OS_VERSIONS_RE_MAP: RegexpMap<OSVersionEnum> = new Map([
  [OSVersionEnum.WINDOWS_3_11, { raw: /Win16/ }],
  [OSVersionEnum.WINDOWS_95, { raw: /(Windows 95|Win95|Windows_95)/ }],
  [OSVersionEnum.WINDOWS_ME, { raw: /(Win 9x 4.90|Windows ME)/ }],
  [OSVersionEnum.WINDOWS_98, { raw: /(Windows 98|Win98)/ }],
  [OSVersionEnum.WINDOWS_CE, { raw: /Windows CE/ }],
  [OSVersionEnum.WINDOWS_2000, { raw: /(Windows NT 5.0|Windows 2000)/ }],
  [OSVersionEnum.WINDOWS_XP, { raw: /(Windows NT 5.1|Windows XP)/ }],
  [OSVersionEnum.WINDOWS_SERVER_2003, { raw: /Windows NT 5.2/ }],
  [OSVersionEnum.WINDOWS_VISTA, { raw: /Windows NT 6.0/ }],
  [OSVersionEnum.WINDOWS_7, { raw: /(Windows 7|Windows NT 6.1)/ }],
  [OSVersionEnum.WINDOWS_8_1, { raw: /(Windows 8.1|Windows NT 6.3)/ }],
  [OSVersionEnum.WINDOWS_8, { raw: /(Windows 8|Windows NT 6.2)/ }],
  [OSVersionEnum.WINDOWS_10, { raw: /(Windows NT 10.0)/ }],
  [OSVersionEnum.WINDOWS_PHONE_7_5, { raw: /(Windows Phone OS 7.5)/ }],
  [OSVersionEnum.WINDOWS_PHONE_8_1, { raw: /(Windows Phone 8.1)/ }],
  [OSVersionEnum.WINDOWS_PHONE_10, { raw: /(Windows Phone 10)/ }],
  [
    OSVersionEnum.WINDOWS_NT_4_0,
    { and: [{ raw: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ }, { not: { raw: /Windows NT 10.0/ } }] },
  ],
  [OSVersionEnum.MACOSX, { raw: /(MAC OS X\s*[^ 0-9])/ }],
  [OSVersionEnum.MACOSX_3, { raw: /(Darwin 10.3|Mac OS X 10.3)/ }],
  [OSVersionEnum.MACOSX_4, { raw: /(Darwin 10.4|Mac OS X 10.4)/ }],
  [OSVersionEnum.MACOSX_5, { raw: /(Mac OS X 10.5)/ }],
  [OSVersionEnum.MACOSX_6, { raw: /(Mac OS X 10.6)/ }],
  [OSVersionEnum.MACOSX_7, { raw: /(Mac OS X 10.7)/ }],
  [OSVersionEnum.MACOSX_8, { raw: /(Mac OS X 10.8)/ }],
  [OSVersionEnum.MACOSX_9, { raw: /(Mac OS X 10.9)/ }],
  [OSVersionEnum.MACOSX_10, { raw: /(Mac OS X 10.10)/ }],
  [OSVersionEnum.MACOSX_11, { raw: /(Mac OS X 10.11)/ }],
  [OSVersionEnum.MACOSX_12, { raw: /(Mac OS X 10.12)/ }],
  [OSVersionEnum.MACOSX_13, { raw: /(Mac OS X 10.13)/ }],
  [OSVersionEnum.MACOSX_14, { raw: /(Mac OS X 10.14)/ }],
  [OSVersionEnum.MACOSX_15, { raw: /(Mac OS X 10.15)}/ }],
]);

export const BROWSER_VERSIONS_RE_MAP_NEW: ReadonlyMap<BrowsersEnum, RegExp[]> = new Map([
  [BrowsersEnum.CHROME, [/\bChrome\/([\d.]+)\b/, /\bCriOS\/([\d.]+)\b/]],
  [BrowsersEnum.FIREFOX, [/\bFirefox\/([\d.]+)\b/]],
  [BrowsersEnum.SAFARI, [/\bVersion\/([\d.]+)\b/]],
  [BrowsersEnum.OPERA, [/\bVersion\/([\d.]+)\b/, /\bOPR\/([\d.]+)\b/]],
  [BrowsersEnum.IE, [/\bMSIE ([\d.]+\w?)\b/, /\brv:([\d.]+\w?)\b/]],
  [BrowsersEnum.MS_EDGE, [/\bEdge\/([\d.]+)\b/]],
]);

export const HUAWEI_REGEX = /\bhuawei\b/i;
