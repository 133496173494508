import {Injectable} from "@angular/core";
import {
  associationChoicesReferentielSelector, commitmentAccountReferentielSelector,
  commitmentCardReferentielSelector,
  commitmentPrinciplesReferentielSelector, donationChoicesReferentielSelector,
  eligibilityReferentielSelector,
  finalReferentielSelector,
  formulaChoicesReferentielSelector,
  helpReferentielSelector, infoLegalesModaleReferentielSelector,
  errorReferentielSelector,
  mailReferentielSelector,
  mailRgpdReferentielSelector,
  mailSavedReferentielSelector,
  quitReferentielSelector,
  stepsPresentationReferentielSelector, summaryReferentielSelector,
  supportingDocumentsReferentielSelector,
  trackingChoicesReferentielSelector,
  transverseReferentielSelector
} from "../store/data.selectors";
import {Store} from "@ngrx/store";
import {filter, first} from "rxjs/operators";

@Injectable()
export class ReferentielService {

  constructor(
    private store: Store
  ) {
  }

  public get errorReferentiel$() {
    return this.store.select(errorReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get transverseReferentiel$() {
    return this.store.select(transverseReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get helpReferentiel$() {
    return this.store.select(helpReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get infoLegalesModaleReferentiel$() {
    return this.store.select(infoLegalesModaleReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get stepsPresentationReferentiel$() {
    return this.store.select(stepsPresentationReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get supportingDocumentsReferentiel$() {
    return this.store.select(supportingDocumentsReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get eligibilityReferentiel$() {
    return this.store.select(eligibilityReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get mailReferentiel$() {
    return this.store.select(mailReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get mailSavedReferentiel$() {
    return this.store.select(mailSavedReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get formulaChoicesReferentiel$() {
    return this.store.select(formulaChoicesReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get commitmnetPrinciplesReferentiel$() {
    return this.store.select(commitmentPrinciplesReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get commitmnetAccountsReferentiel$() {
    return this.store.select(commitmentAccountReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get trackingChoicesReferentiel$() {
    return this.store.select(trackingChoicesReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get commitmentCardReferentiel$() {
    return this.store.select(commitmentCardReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get associationChoicesReferentiel$() {
    return this.store.select(associationChoicesReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get donationChoicesReferentiel$() {
    return this.store.select(donationChoicesReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get summaryChoicesReferentiel$() {
    return this.store.select(summaryReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get finalReferentiel$() {
    return this.store.select(finalReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get rgpdReferentiel$() {
    return this.store.select(mailRgpdReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }

  public get quitReferentiel$() {
    return this.store.select(quitReferentielSelector)
      .pipe(filter(referentiel => referentiel !== undefined))
      .pipe(first());
  }
}
