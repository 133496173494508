export enum CONTRAST_COLOR {
  AMETHYSTE = '#7c5691',
  AMARANTH = '#bf483d',
  AZURE_BLUE = '#1759a4',
  BEIGE_GRAY = '#7c7667',
  BLUE = '#007acc',
  BOTTLE_GREEN = '#00544d',
  BORDEAUX = '#BB142C',
  CARMINE = '#B0435F',
  CAULDRON = '#987001',
  CHROME_GREEN = '#1C2607',
  DARK_BLUE = '#080d3d',
  EMERALD_GREEN = '#506915',
  ELECTRIC_BLUE = '#062499',
  INDIGO = '#521DA5',
  LAVENDER = '#805bed',
  LILAC = '#b0479d',
  MINT = '#00a193',
  MAZARINE = '#252C51',
  PERSIAN_BLUE = '#5048ec',
  PLUM = '#420c11',
  PRUSSIAN_BLUE = '#285066',
  PURPLE = '#8f0e43',
  PINE_GREEN = '#2F3E0C',
  RUBY = '#D1005D',
  VIOLET = '#492578',
  WILDCAT = '#956c65'
}

export enum NO_CONTRAST_COLOR {
  AQUILAIN = '#c6553d',
  AZURIN = '#E6F0F7',
  AMBER = '#eab818',
  BLUE_DUCK = '#51A0A2',
  CORAL = '#e6434b',
  CREAM = '#F3EFED',
  CURRY = '#BD8A00',
  GOLD = '#e5bd77',
  GREEN = '#6e8e24',
  LEAD = '#85979D',
  MENTHE = '#10A091',
  MUSTARD = '#C3811D',
  NASTURTIUM = '#F06468',
  NIGHT_BLUE = '#5F98CD',
  OLIVE = '#989407',
  ORCHID = '#d46ea7',
  ORANGE = '#ec710f',
  PASTEL_BEIGE = '#d8c6bd',
  PASTEL_BLUE = '#89abd7',
  PASTEL_GREEN = '#a6c5cb',
  PASTEL_VIOLET = '#bab2d9',
  PEACOCK_BLUE = '#589496',
  ROYAL_BLUE = '#537dee',
  SKY_BLUE = '#009eba',
  STEEL = '#608DAE'
}
