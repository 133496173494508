import { Injectable } from '@angular/core';
import { fromEventPattern, Observable } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import {
  BROWSER_VERSIONS_RE_MAP_NEW,
  BROWSERS_RE_MAP,
  DEVICES_RE_MAP,
  OS_RE_MAP,
  OS_VERSIONS_RE_MAP,
  RegexpMap,
  HUAWEI_REGEX
} from '../consts/device-pattern.const';
import {
  BrowsersEnum,
  DeviceInfoInterface,
  DevicesEnum,
  OSEnum,
  OSVersionEnum,
  UserAgentPatchInterface
} from '../models/information.model';

import { ReTree } from '../utils/retree.util';

@Injectable({ providedIn: 'root' })
export class DeviceDetectorService {
  readonly isMobileView$: Observable<boolean>;
  isMobileView: boolean;
  private readonly deviceInfo: DeviceInfoInterface;
  private ua: string;
  private readonly query = '(min-width: 960px)';

  constructor() {
    this.ua = window.navigator.userAgent;
    this.deviceInfo = this.computeDeviceInfo(this.ua);
    this.isMobileView$ = this.getViewChangeDetection();
  }

  /**
   * Patch UserAgent with additional values
   * @param content Patch UserAgent with this value
   */
  patchUserAgent(content: string): UserAgentPatchInterface {
    const userAgent = this.ua;
    const newUserAgent = `${this.deviceInfo.userAgent} ${content}`;
    if (window.navigator.userAgent !== newUserAgent) {
      const userAgentProp = {
        get() {
          return newUserAgent;
        }
      };
      try {
        Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
      } catch (e) {
        // eslint-disable-next-line no-global-assign
        window = {
          ...window,
          ...Object.create(navigator, {
            userAgent: userAgentProp
          })
        };
      }
      this.ua = newUserAgent;
      return {
        _userAgent: userAgent,
        userAgent: window.navigator.userAgent
      };
    } else {
      return {
        _userAgent: window.navigator.userAgent,
        userAgent: window.navigator.userAgent
      };
    }
  }

  /**
   * Get device general informations
   */
  getDeviceInfo(): DeviceInfoInterface {
    return this.deviceInfo;
  }

  /**
   * Check if user is using desktop device
   */
  isDesktop(): boolean {
    return [DevicesEnum.PS4, DevicesEnum.CHROME_BOOK, DevicesEnum.UNKNOWN].some(
      item => this.deviceInfo.device === item
    );
  }

  /**
   * Check if user is using tablet device
   */
  isTablet(): boolean {
    return [DevicesEnum.I_PAD, DevicesEnum.FIREFOX_OS].some(item => this.deviceInfo.device === item);
  }

  /**
   * Check if user is using mobile device
   */
  isMobile(): boolean {
    return [
      DevicesEnum.ANDROID,
      DevicesEnum.IPHONE,
      DevicesEnum.I_POD,
      DevicesEnum.BLACKBERRY,
      DevicesEnum.FIREFOX_OS,
      DevicesEnum.WINDOWS_PHONE,
      DevicesEnum.VITA
    ].some(item => this.deviceInfo.device === item);
  }

  /**
   * Check if user device runs under Windows platform
   */
  isWindowsPhone(): boolean {
    return this.deviceInfo.device === DevicesEnum.WINDOWS_PHONE;
  }

  /**
   * Check if user device runs under IOS platform
   */
  isIOS(): boolean {
    return [DevicesEnum.IPHONE, DevicesEnum.I_PAD, DevicesEnum.I_POD].some(item => this.deviceInfo.device === item);
  }

  /**
   * Check if user device runs under Android platform
   */
  isAndroid(): boolean {
    return this.deviceInfo.device === DevicesEnum.ANDROID;
  }

  /**
   * Check if user device runs under Huawei platform
   */
  isHuawei(): boolean {
    return this.deviceInfo.huawei;
  }

  private getViewChangeDetection(): Observable<boolean> {
    const query = window.matchMedia(this.query);
    this.isMobileView = !query.matches;

    // Fix fromEvent issue with mediaQueryList in Safari and IE
    // https://github.com/ReactiveX/rxjs/issues/4749
    // prettier-ignore
    return fromEventPattern(query.addListener.bind(query), query.removeListener.bind(query)).pipe( // NOSONAR
      map(({ matches }: MediaQueryListEvent) => !matches),
      tap((matches: boolean) => (this.isMobileView = matches)),
      startWith(this.isMobileView),
      shareReplay(1)
    );
  }

  private computeDeviceInfo(ua: string): DeviceInfoInterface {
    const browser = this.computeProp(BrowsersEnum, BROWSERS_RE_MAP, ua);
    return {
      userAgent: ua,
      os: this.computeProp(OSEnum, OS_RE_MAP, ua),
      browser,
      device: this.computeProp(DevicesEnum, DEVICES_RE_MAP, ua),
      os_version: this.computeProp(OSVersionEnum, OS_VERSIONS_RE_MAP, ua),
      browser_version: this.computeBrowserVersion(ua, browser),
      huawei: !!ReTree.exec(ua, HUAWEI_REGEX)
    };
  }

  // Enum typing with T[keyof T]
  private computeProp<T>(propEnum: T, regexpMap: RegexpMap<T[keyof T]>, ua: string): T[keyof T] {
    return Object.values(propEnum).find((item: T[keyof T]) => ReTree.test(ua, regexpMap.get(item))) || 'unknown';
  }

  private computeBrowserVersion(ua: string, browser: BrowsersEnum): string {
    const re = BROWSER_VERSIONS_RE_MAP_NEW.get(browser);
    const res = ReTree.exec(ua, re);
    return browser !== BrowsersEnum.UNKNOWN && !!res ? res[1] : '0';
  }
}
