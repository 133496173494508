import {AfterViewInit, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {HelpReferentiel} from "../../../../../../../models/Referentiel";
import {Observable} from "rxjs";
import {ReferentielService} from "../../../data/services/referentiel.service";

@Component({
  selector: 'app-help-modale',
  templateUrl: './help-modale.component.html',
  styleUrls: ['./help-modale.component.scss']
})
export class HelpModaleComponent implements AfterViewInit {
  public readonly closed = new EventEmitter<void>()
  public referentiel$: Observable<HelpReferentiel>;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closed.emit()
  }

  constructor(
    private referentielService: ReferentielService
  ) {
    this.referentiel$ = referentielService.helpReferentiel$
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById('close-help-modale').focus()
    , 100})
  }
}
