import { NgModule } from '@angular/core';
import { BpceDetectorModule } from '@bpce/ng-detector';
import { UiRipple } from './ripple.directive';

@NgModule({
  imports: [BpceDetectorModule],
  exports: [UiRipple],
  declarations: [UiRipple]
})
export class UiRippleModule {}
