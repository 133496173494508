import { Pipe, PipeTransform } from '@angular/core';
import * as replaceSpecialCharacters from "replace-special-characters"


@Pipe({name: 'id'})
export class IdPipe implements PipeTransform {
  transform(value: string): string {
    return this.formatId(value);
  }

  public formatId(id: string) {
    id = id.replace(new RegExp(',', 'g'), '');
    id = id.replace('?', '');
    id = id.replace(new RegExp('!', 'g'), '');
    id = id.replace(new RegExp('\'', 'g'), ' ');
    id = id.replace(new RegExp('’', 'g'), ' ');
    id = id.trim();
    id = id.replace(new RegExp(' ', 'g'), '-');
    return replaceSpecialCharacters(id).toLowerCase();
  }
}
