
<div ui-modal-title>
  <ui-header
    close-title="Fermer la fenêtre de dialogue"
    [showBack]="false"
    [showClose]="true"
    (close)="closed.emit()"
    [showHelp]="false">
    <app-modale-title [title]="title$ | async"></app-modale-title>
  </ui-header>
</div>

<ui-modal-content *ngIf="sanitizedUrl$ | async as url">
  <iframe [title]="iframeTitle$ | async" [src]="url"></iframe>
</ui-modal-content>

<div ui-modal-actions uiButtonV2Group alignment="block">
  <button (click)="closed.emit()" category="primary" uiButtonV2>Fermer</button>
</div>
