<ng-container>

  <div ui-modal-title>
    <ui-header
      close-title="Fermer la fenêtre de dialogue"
      [showBack]="false"
      [showClose]="true"
      (close)="closed.emit()"
      [showHelp]="false">
      <app-modale-title [title]="'Devenir client'"></app-modale-title>
    </ui-header>
  </div>

  <ui-modal-content>
    <section>
      <app-page-title [modale]="true" [big]="true" [title]="'Navigateur mal supporté'"></app-page-title>
      <app-inner-html [content]="'Certaines pages risquent de ne pas s’afficher correctement. Pour votre confort de navigation et votre sécurité, nous vous recommandons d’installer l’un des navigateurs suivants :\n'"></app-inner-html>

      <app-browsers></app-browsers>
    </section>
  </ui-modal-content>

  <div ui-modal-actions uiButtonV2Group alignment="block">
    <button (click)="closed.emit()" category="primary" uiButtonV2>J'ai compris, je continue ma navigation</button>
  </div>

</ng-container>
