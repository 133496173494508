import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiThemeModule } from '@bpce/ui/theming';
import { UiStepperComponent } from './stepper.component';

@NgModule({
  imports: [CommonModule, UiThemeModule],
  declarations: [UiStepperComponent],
  exports: [UiStepperComponent]
})
export class UiStepperModule {}
