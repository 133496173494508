import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DqeConfig, DQE_CONFIG, ServiceNameEnum } from '../dqe/dqe.config';
import {
  AbstractControlWarn,
  DqeAsyncValidationService,
  ServiceUnavailableError,
  ValidationCode,
  ValidationResponse
} from '../dqe/dqe.model';

@Injectable()
export class DqeAddressValidationService implements DqeAsyncValidationService<string> {
  public defaultErrorMessage = 'Veuillez saisir une adresse postale valide.';
  public errorKey = 'address';
  public dqeconfig: DqeConfig;
  public readonly errorCodeList: Record<string, ValidationResponse> = {
    '0': {
      code: 'Adresse_inconnue',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '10': { code: 'Adresse_correcte1', status: ValidationCode.INFO, message: '' },
    '20': { code: 'Adresse_correcte2', status: ValidationCode.WARN, message: '' },
    '21': { code: 'Adresse_correcte_facade_hors_borne', status: ValidationCode.WARN, message: '' },
    '22': { code: 'Adresse_correcte_facade_absente', status: ValidationCode.WARN, message: '' },
    '23': { code: 'Adresse_correcte_facade_hors_borne', status: ValidationCode.WARN, message: '' },
    '24': { code: 'Adresse_correcte_facade_absente', status: ValidationCode.WARN, message: '' },
    '30': {
      code: 'Adresse_voie_non_reconnue1',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '31': {
      code: 'Adresse_voie_non_reconnue2',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '40': {
      code: 'Adresse_voie_absente1',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '41': {
      code: 'Adresse_voie_absente2',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '50': {
      code: 'Adresse_voie_non_reconnue1',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '51': {
      code: 'Adresse_voie_non_reconnue2',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '60': {
      code: 'Adresse_voie_absente3',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '61': {
      code: 'Adresse_voie_absente3',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '70': {
      code: 'Adresse_cp_ville1',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '80': {
      code: 'Adresse_cp_ville2',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '90': {
      code: 'Adresse_internationale',
      status: ValidationCode.ERROR,
      message: this.defaultErrorMessage
    },
    '99': { code: 'Adresse_service_indisponible', status: ValidationCode.WARN, message: '' }
  };

  constructor(private readonly httpClient: HttpClient, @Inject(DQE_CONFIG) dqeconfig: DqeConfig) {
    this.init(dqeconfig);
  }

  // should be taken over by the back-end
  private init(dqeconfig: DqeConfig): void {
    if (!this.errorKey) {
      throw new TypeError('errorKey must be a valid string');
    }

    if (dqeconfig === null || dqeconfig.url === '') {
      throw new TypeError('Invalid DQE config');
    }

    this.dqeconfig = dqeconfig;
  }

  /*
   * value: address to be validated
   * in case of an empty string an error code is returned
   * Returns a code and message, for the moment the returned values are derived from the DQE response
   * eventually should be provided directly by the back-end
   *
   * WARNING Query param names are case sensitive
   */
  validate(c: AbstractControlWarn, value: string): Observable<ValidationErrors | null> {
    // Cater for empty address value
    // Even though should never happen as the address is required
    // Do not send a request when no value has been specified
    if (value === '') {
      return of(null);
    }

    const url = `${this.dqeconfig.url}/${ServiceNameEnum.Address}/`;
    const params = new HttpParams()
      .set('Adresse', value)
      .append('Instance', '0')
      .append('Taille', '38')
      .append('Pays', 'FRA');

    return this.httpClient.get(url, { params }).pipe(
      map(res => this.mapToValidationError(res, c)),
      catchError(() => this.onError(c))
    );
  }

  private mapToValidationError(response: unknown, c: AbstractControlWarn): ValidationErrors | null {
    if (!response || typeof response !== 'object' || typeof response?.[1] !== 'object') {
      throw new ServiceUnavailableError();
    }

    return this.errorMessage(c, response[1].DQECodeDetail || null);
  }

  /**
   * Flag the fact that the service is not available as a warning
   */
  private onError(c: AbstractControlWarn) {
    this.errorMessage(c, null);

    return of(null);
  }

  errorMessage(c: AbstractControlWarn, messageKey: string | null): ValidationErrors | null {
    const key = messageKey || '99';
    const serviceResponse = this.errorCodeList[key];

    if (serviceResponse && serviceResponse.status === ValidationCode.ERROR) {
      return { [this.errorKey]: serviceResponse };
    }

    if (serviceResponse && serviceResponse.status === ValidationCode.WARN) {
      c.warnings = { [this.errorKey]: serviceResponse };
    }

    return null;
  }

  protected buildErrorMessage(error: ValidationResponse): string {
    return error.message;
  }
}
