import { ITown } from './town-suggestion.model';

const ID_LENGTH_VALUE = 5;

export function convertObjectToTown({
  CodePostal,
  Localite: city,
  IDLocalite: idCity,
  Latitude: latitude,
  Longitude: longitude,
  LieuDit: knownAs
}): ITown {
  return {
    zipCode: getOnlyDigit(CodePostal),
    city,
    idCity,
    latitude,
    longitude,
    knownAs
  };
}

export function getOnlyDigit(s: string): string {
  return s.replace(/\D/gi, ''); // we want [0-9] only
}

export function isIdLocalityValid(id: string): boolean {
  return Boolean(
    id && id.length === ID_LENGTH_VALUE && (!isNaN(Number(id)) || id.startsWith('2A') || id.startsWith('2B'))
  );
}
