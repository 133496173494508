import { Injectable, Renderer2 } from '@angular/core';
import { offset, Placement } from '@floating-ui/core';
import { arrow, computePosition } from '@floating-ui/dom';

const TOOLTIP_ARROW_PADDING = 8;

const GLOBAL_PANEL_ID = 'bpce-panels-global-div';

@Injectable()
export class UiFloatingUiService {
  // Creates a single div under body regrouping all the panels
  createPanelsGlobalDiv(renderer: Renderer2, injectElement: Element): HTMLDivElement {
    let tooltipPanelDiv = injectElement.querySelector(`#${GLOBAL_PANEL_ID}`) as HTMLDivElement;
    if (!tooltipPanelDiv) {
      tooltipPanelDiv = renderer.createElement('div');
      renderer.setProperty(tooltipPanelDiv, 'id', GLOBAL_PANEL_ID);
      renderer.appendChild(injectElement, tooltipPanelDiv);
    }
    return tooltipPanelDiv;
  }

  createFloating(
    target: Element,
    popper: HTMLElement,
    arrowElement: HTMLElement,
    placementPosition: Placement
  ): Promise<any> {
    // Make the tooltip appear in the DOM so the floating-ui lib can place it correctly
    popper.setAttribute('floating-compute', '');

    return computePosition(target, popper, {
      placement: placementPosition,
      middleware: [
        offset(12),
        arrow({
          element: arrowElement,
          padding: TOOLTIP_ARROW_PADDING
        })
      ]
    }).then(({ x, y, placement, middlewareData }) => {
      // We place the tooltip
      Object.assign(popper.style, {
        left: `${x}px`,
        top: `${y}px`
      });
      if (middlewareData.arrow) {
        // We place the arrow
        const ax = middlewareData.arrow.x;
        const ay = middlewareData.arrow.y;
        Object.assign(arrowElement.style, {
          left: ax ? `${ax}px` : '',
          top: ay ? `${ay}px` : ''
        });
        // We add the placement attribute used for the css selector
        const staticSide = placement.split('-')[0];
        arrowElement.setAttribute('placement', staticSide);
      }

      // Make the tooltip visible
      popper.setAttribute('floating-show', '');
    });
  }
}
