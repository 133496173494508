<div
  [ngClass]="{
    'bpce-loader-small': mode === types.ENTRY_FIELD,
    'bpce-loader-logo': mode === types.LOGO
  }"
  class="bpce-loader"
>
  <svg aria-hidden="true" class="bpce-loader-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45"></circle>
  </svg>
</div>
