import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgEmbedderComponent } from './svg-embedder.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SvgEmbedderComponent],
  exports: [SvgEmbedderComponent]
})
export class SvgEmbedderModule {}
