<div class="0"></div>
<div [ngSwitch]="state" class="bpce-enhanced-loader-container">
  <div *ngSwitchCase="states.LOADING" class="bpce-enhanced-loader-loading">
    <ui-loader [mode]="loaderType"></ui-loader>
    <p class="bpce-enhanced-loader-text">{{ loadingMessage }}</p>
  </div>
  <div *ngSwitchCase="states.SUCCESS" class="bpce-enhanced-loader-success">
    <div class="bpce-enhanced-loader-icon">
      <ui-icon
        class="bpce-enhanced-loader-icon-check"
        [svgIcon]="'result:success'"
        [svgIconSize]="80"
        [svgIconPadding]="0"
      ></ui-icon>
    </div>
    <p class="bpce-enhanced-loader-text">{{ successMessage }}</p>
  </div>
  <div *ngSwitchCase="states.ERROR" class="bpce-enhanced-loader-error">
    <div class="bpce-enhanced-loader-icon">
      <ui-icon
        class="bpce-enhanced-loader-icon-check"
        [svgIcon]="'result:error'"
        [svgIconSize]="80"
        [svgIconPadding]="0"
      ></ui-icon>
    </div>
    <p class="bpce-enhanced-loader-text">{{ errorMessage }}</p>
  </div>
</div>
