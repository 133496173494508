export namespace UiButtonV2 {
  export type Alignment = 'block' | 'inline' | 'flex';

  export enum AlignmentEnum {
    block = 'block',
    inline = 'inline',
    flex = 'flex'
  }

  export type Category = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

  export enum CategoryEnum {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    quaternary = 'quaternary'
  }
}
