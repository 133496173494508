import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { RippleRef } from './ripple-ref';
import { RippleAnimationConfig, RippleConfig, RippleRenderer, RippleTarget } from './ripple-renderer';

/** Configurable options for `uiRipple`. */
export interface RippleGlobalOptions {
  /**
   * Whether ripples should be disabled. Ripples can be still launched manually by using
   * the `launch()` method. Therefore focus indicators will still show up.
   */
  disabled?: boolean;

  /**
   * Configuration for the animation duration of the ripples. There are two phases with different
   * durations for the ripples. The animation durations will be overwritten if the
   * `NoopAnimationsModule` is being used.
   */
  animation?: RippleAnimationConfig;

  /**
   * Whether ripples should start fading out immediately after the mouse our touch is released. By
   * default, ripples will wait for the enter animation to complete and for mouse or touch release.
   */
  terminateOnPointerUp?: boolean;
}

/** Injection token that can be used to specify the global ripple options. */
export const UI_RIPPLE_GLOBAL_OPTIONS = new InjectionToken<RippleGlobalOptions>('ui-ripple-global-options');

@Directive({
  selector: '[ui-ripple], [uiRipple]',
  exportAs: 'uiRipple'
})
export class UiRipple implements OnInit, OnDestroy, RippleTarget {
  @HostBinding('class.ui-ripple') uiRippleClass = true;

  /** Custom color for all ripples. */
  @Input() uiRippleColor: string;

  /**
   * Whether the ripple always originates from the center of the host element's bounds, rather
   * than originating from the location of the click event.
   */
  @Input() uiRippleCentered: boolean;

  /**
   * If set, the radius in pixels of foreground ripples when fully expanded. If unset, the radius
   * will be the distance from the center of the ripple to the furthest corner of the host element's
   * bounding rectangle.
   */
  @Input() uiRippleRadius = 0;

  /**
   * Configuration for the ripple animation. Allows modifying the enter and exit animation
   * duration of the ripples. The animation durations will be overwritten if the
   * `NoopAnimationsModule` is being used.
   */
  @Input() uiRippleAnimation: RippleAnimationConfig;
  /** Renderer for the ripple DOM manipulations. */
  private readonly _rippleRenderer: RippleRenderer;
  /** Options that are set globally for all ripples. */
  private readonly _globalOptions: RippleGlobalOptions;
  /** Whether ripple directive is initialized and the input bindings are set. */
  private _isInitialized = false;

  constructor(
    private readonly _elementRef: ElementRef,
    ngZone: NgZone,
    @Optional() @Inject(UI_RIPPLE_GLOBAL_OPTIONS) globalOptions: RippleGlobalOptions,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string
  ) {
    this._globalOptions = globalOptions || {};
    this._rippleRenderer = new RippleRenderer(this, ngZone, this._elementRef);

    if (animationMode === 'NoopAnimations') {
      this._globalOptions.animation = { enterDuration: 0, exitDuration: 0 };
    }
  }

  private _disabled = false;

  /**
   * Whether click events will not trigger the ripple. Ripples can be still launched manually
   * by using the `launch()` method.
   */
  @Input('uiRippleDisabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this._setupTriggerEventsIfEnabled();
  }

  private _trigger: HTMLElement;

  /**
   * The element that triggers the ripple when click events are received.
   * Defaults to the directive's host element.
   */
  @Input('uiRippleTrigger')
  get trigger(): HTMLElement {
    return this._trigger || this._elementRef.nativeElement;
  }

  set trigger(trigger: HTMLElement) {
    this._trigger = trigger;
    this._setupTriggerEventsIfEnabled();
  }

  /**
   * Ripple configuration from the directive's input values.
   * @docs-private Implemented as part of RippleTarget
   */
  get rippleConfig(): RippleConfig {
    return {
      centered: this.uiRippleCentered,
      radius: this.uiRippleRadius,
      color: this.uiRippleColor,
      animation: { ...this._globalOptions.animation, ...this.uiRippleAnimation },
      terminateOnPointerUp: this._globalOptions.terminateOnPointerUp
    };
  }

  /**
   * Whether ripples on pointer-down are disabled or not.
   * @docs-private Implemented as part of RippleTarget
   */
  get rippleDisabled(): boolean {
    return this.disabled || !!this._globalOptions.disabled;
  }

  ngOnInit(): void {
    this._isInitialized = true;
    this._setupTriggerEventsIfEnabled();
  }

  ngOnDestroy(): void {
    this._rippleRenderer._removeTriggerEvents();
  }

  /** Fades out all currently showing ripple elements. */
  fadeOutAll(): void {
    this._rippleRenderer.fadeOutAll();
  }

  /**
   * Launches a manual ripple using the specified ripple configuration.
   * @param config Configuration for the manual ripple.
   */
  launch(config: RippleConfig): RippleRef;

  /**
   * Launches a manual ripple at the specified coordinates within the element.
   * @param x Coordinate within the element, along the X axis at which to fade-in the ripple.
   * @param y Coordinate within the element, along the Y axis at which to fade-in the ripple.
   * @param config Optional ripple configuration for the manual ripple.
   */
  launch(x: number, y: number, config?: RippleConfig): RippleRef;

  /** Launches a manual ripple at the specified coordinated or just by the ripple config. */
  launch(configOrX: number | RippleConfig, y = 0, config?: RippleConfig): RippleRef {
    if (typeof configOrX === 'number') {
      return this._rippleRenderer.fadeInRipple(configOrX, y, { ...this.rippleConfig, ...config });
    } else {
      return this._rippleRenderer.fadeInRipple(0, 0, { ...this.rippleConfig, ...configOrX });
    }
  }

  /** Sets up the the trigger event listeners if ripples are enabled. */
  private _setupTriggerEventsIfEnabled() {
    if (!this.disabled && this._isInitialized) {
      this._rippleRenderer.setupTriggerEvents(this.trigger);
    }
  }
}
