import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DataActions} from '../../data/store/data.actions';
import {AppRoutes} from '../models/Routes.enum';
import {ReferentielService} from '../../data/services/referentiel.service';

@Injectable()
export class ReferentielResolverService implements Resolve<any> {
  constructor(
    private referentielService: ReferentielService,
    private store: Store,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(DataActions.fetchTransverseLocalReferentiel());
    switch (route.routeConfig.path.split('/')[0]) {
      case AppRoutes.ERROR:
        return this.referentielService.errorReferentiel$;
      case AppRoutes.STEPS_PRESENTATION:
        this.store.dispatch(DataActions.fetchStepsPresentationReferentiel());
        this.store.dispatch(DataActions.fetchMailReferentiel());
        return this.referentielService.stepsPresentationReferentiel$;
      case AppRoutes.MAIL:
        this.store.dispatch(DataActions.fetchStepsPresentationReferentiel());
        this.store.dispatch(DataActions.fetchMailReferentiel());
        this.store.dispatch(DataActions.fetchMailSavedReferentiel());
        return this.referentielService.mailReferentiel$;
      case AppRoutes.MAIL_SAVED:
        this.store.dispatch(DataActions.fetchMailReferentiel());
        this.store.dispatch(DataActions.fetchMailSavedReferentiel());
        this.store.dispatch(DataActions.fetchFormulaChoiceReferentiel());
        return this.referentielService.mailSavedReferentiel$;
      case AppRoutes.MAIL_ALREADY_EXISTS:
        this.store.dispatch(DataActions.fetchMailReferentiel());
        this.store.dispatch(DataActions.fetchMailSavedReferentiel());
        return this.referentielService.mailSavedReferentiel$;
      case AppRoutes.FORMULA_CHOICES:
        this.store.dispatch(DataActions.fetchMailSavedReferentiel());
        this.store.dispatch(DataActions.fetchFormulaChoiceReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentPrinciplesReferentiel());
        return this.referentielService.formulaChoicesReferentiel$;
      case AppRoutes.COMMITMENT_PRINCIPLES:
        this.store.dispatch(DataActions.fetchFormulaChoiceReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentPrinciplesReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentAccountReferentiel());
        return this.referentielService.commitmnetPrinciplesReferentiel$;
      case AppRoutes.COMMITMENT_ACCOUNT:
        this.store.dispatch(DataActions.fetchCommitmentPrinciplesReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentAccountReferentiel());
        this.store.dispatch(DataActions.fetchTrackingChoicesReferentiel());
        return this.referentielService.commitmnetAccountsReferentiel$;
      case AppRoutes.TRACKING_CHOICES:
        this.store.dispatch(DataActions.fetchCommitmentAccountReferentiel());
        this.store.dispatch(DataActions.fetchTrackingChoicesReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentCardReferentiel());
        return this.referentielService.commitmentCardReferentiel$;
      case AppRoutes.COMMITMENT_CARD:
        this.store.dispatch(DataActions.fetchTrackingChoicesReferentiel());
        this.store.dispatch(DataActions.fetchCommitmentCardReferentiel());
        this.store.dispatch(DataActions.fetchAssociationChoicesReferentiel());
        return this.referentielService.commitmentCardReferentiel$;
      case AppRoutes.ASSOCIATION_CHOICES:
        this.store.dispatch(DataActions.fetchCommitmentCardReferentiel());
        this.store.dispatch(DataActions.fetchAssociationChoicesReferentiel());
        this.store.dispatch(DataActions.fetchDonationChoicesReferentiel());
        return this.referentielService.associationChoicesReferentiel$;
      case AppRoutes.DONATION_CHOICE:
        this.store.dispatch(DataActions.fetchAssociationChoicesReferentiel());
        this.store.dispatch(DataActions.fetchDonationChoicesReferentiel());
        this.store.dispatch(DataActions.fetchSummaryReferentiel());
        return this.referentielService.donationChoicesReferentiel$;
      case AppRoutes.SUMMARY:
        this.store.dispatch(DataActions.fetchFormulaChoiceReferentiel());
        this.store.dispatch(DataActions.fetchDonationChoicesReferentiel());
        this.store.dispatch(DataActions.fetchSummaryReferentiel());
        this.store.dispatch(DataActions.fetchFinalReferentiel());
        return this.referentielService.summaryChoicesReferentiel$;
      case AppRoutes.FINAL:
        this.store.dispatch(DataActions.fetchSummaryReferentiel());
        this.store.dispatch(DataActions.fetchFinalReferentiel());
        return this.referentielService.finalReferentiel$;
    }
    return undefined;
  }
}
