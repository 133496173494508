import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route, Router} from '@angular/router';
import {Observable, of} from 'rxjs';

@Injectable()
export class PreloadStrategyService implements PreloadingStrategy {
  constructor(
    private router: Router
  ) {}

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    const url = this.router.url.replace('/', '');
    if (route.data['preloadOn'].includes(url)) {
      return fn()
    } else {
      return of(null);
    }
  }
}
