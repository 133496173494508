

<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6" uiButtonV2Group alignment="block">
      <button
        tabindex="0" (click)="primaryButtonClicked.emit();$event.stopPropagation()" *ngIf="showPrimaryButton" [disabled]="primaryButtonDisabled" category="primary"
              uiButtonV2>{{primaryButtonLabel}}</button>
      <button
        tabindex="0" (click)="secondaryButtonClicked.emit();$event.stopPropagation()" *ngIf="showSecondaryButton" [disabled]="secondaryButtonDisabled"
              category="secondary" uiButtonV2>{{secondaryButtonLabel}}</button>
    </div>
  </div>
</div>
