import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {MatomoTracker} from "@ngx-matomo/tracker";
import {LoadingService} from "../../core-modules/shared/services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './root-eer.component.html',
  styleUrls: ['./root-eer.component.scss']
})
export class RootEerComponent implements OnInit {

  constructor(
    private viewContainerRef: ViewContainerRef, // seems essential for intersticial
    private readonly tracker: MatomoTracker,
    public readonly loadingService: LoadingService
  ) {}

  public ngOnInit() {

  }

  onActivate(event) {}
}
