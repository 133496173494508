import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { DQE_CONFIG, DqeConfig, ServiceNameEnum } from '../dqe/dqe.config';
import { DqeEmailAbstractService } from './dqe-email-abstract-service';
import { DQEEmailLookUpResponse, DQEEmailLookUpUnoBody } from './dqe-email-types';
import { AsyncValidationService } from './validators';

@Injectable()
/*
 * validates an email using DQE services in POST instead of GET
 * should be taken over by the back-end
 */
export class DqeEmailV2Service extends DqeEmailAbstractService implements AsyncValidationService<string> {
  private readonly dqeResponseListener: Subject<DQEEmailLookUpUnoBody> = new Subject();

  get dqeResponseListener$(): Observable<DQEEmailLookUpUnoBody> {
    return this.dqeResponseListener.asObservable();
  }

  constructor(private readonly httpClient: HttpClient, @Optional() @Inject(DQE_CONFIG) dqeconfig: DqeConfig) {
    super();
    this.init(dqeconfig);
  }

  validate(emailToValidate: string): Observable<ValidationErrors | null> {
    if (emailToValidate === '' || !this.dqeconfig.url) {
      return of(null);
    }

    const url = `${this.dqeconfig.url}/${ServiceNameEnum.Email}/`;
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    const params = new URLSearchParams();
    params.set('Rectify', '1');
    params.set('Email', emailToValidate);

    return this.httpClient.post<DQEEmailLookUpResponse>(url, params.toString(), options).pipe(
      filter(response => !!response),
      map(response => {
        this.dqeResponseListener.next(response['1']);
        return this.buildErrorMessage(response['1'], this.errorKey);
      }),
      catchError(() => of(this.buildErrorMessage(null, this.errorKey)))
    );
  }
}
