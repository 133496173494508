import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { UiButtonV2Component } from './button-v2.component';
import { UiButtonV2 } from './button-v2.model';

@Component({
  selector: '[uiButtonV2Group], [ui-button-v2-group]',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./button-v2-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiButtonV2GroupComponent implements OnChanges, AfterContentInit {
  @HostBinding('class.bpce-button-v2-group')
  readonly _mainClass = true;

  @Input()
  noMobileBorder = false;

  @Input()
  alignment: UiButtonV2.Alignment = UiButtonV2.AlignmentEnum.inline;

  @ContentChildren(UiButtonV2Component)
  buttons: QueryList<UiButtonV2Component>;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.noMobileBorder || changes.alignment) {
      this.updateButtonProps();
    }
  }

  ngAfterContentInit(): void {
    this.updateButtonProps();
  }

  updateButtonProps(): void {
    if (this.buttons && this.buttons.length) {
      setTimeout(() => {
        this.buttons.forEach(btn => {
          btn.noMobileBorder = this.noMobileBorder;
          btn.alignment = this.alignment;
        });
        this.changeDetector.markForCheck();
      }, 0);
    }
  }
}
