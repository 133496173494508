import { ValidationErrors } from '@angular/forms';
import { DqeConfig } from '../dqe/dqe.config';
import { ValidationCode } from '../dqe/dqe.model';
import { DQEEmailLookUpUnoBody } from './dqe-email-types';

export abstract class DqeEmailAbstractService {
  private static readonly emailKeyError = 'email';

  errorKey: string;
  dqeconfig: DqeConfig;

  readonly dqeEmailCodeEnum = {
    '91': { code: 'error_syntaxe', status: ValidationCode.ERROR },
    '92': { code: 'domaine_inconnu', status: ValidationCode.ERROR },
    '93': { code: 'domaine_blacklist', status: ValidationCode.ERROR },
    '94': { code: 'utilisateur_non_autorise', status: ValidationCode.WARN },
    '95': { code: 'jetable', status: ValidationCode.ERROR },
    '99': { code: 'service_indisponible', status: ValidationCode.WARN },
    '00': { code: 'valide', status: ValidationCode.INFO },
    '01': { code: 'non_controle', status: ValidationCode.WARN },
    '02': { code: 'non_trouve', status: ValidationCode.ERROR },
    '03': { code: 'softbounce', status: ValidationCode.WARN },
    '04': { code: 'non_specifie', status: ValidationCode.WARN }
  };

  protected buildErrorMessage(response: DQEEmailLookUpUnoBody | null, errorKey: string): ValidationErrors | null {
    const errorInfo = this.dqeEmailCodeEnum[response ? response.IdError : '99'];

    // ToDo replace when backend in place
    if (errorInfo.status === ValidationCode.ERROR) {
      return { [`${errorKey}_${errorInfo.code}`]: true };
    }

    return null;
  }

  protected init(dqeconfig?: DqeConfig): void {
    // should be taken over by the back-end
    this.errorKey = DqeEmailAbstractService.emailKeyError;
    if (dqeconfig && dqeconfig.url !== '') {
      this.dqeconfig = dqeconfig;
    } else {
      this.dqeconfig = new DqeConfig('');
    }
  }
}
