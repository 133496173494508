export interface CountryPrefix {
  country: string;
  prefix: string;
  isDisplay?: boolean;
  leadingZero?: boolean;
  mask?: Array<RegExp | string>;
  minDigits?: number;
  code?: string;
}

// Per country lists
//      the name (in French),
//      the prefix as a number (no +)
//  optionally
//      if it allows a leading zero (which is filtered out on blur)
//      the mask to be applied which drives the format and includes any constraints (eg has to start with 9)
//      the minimum amount of digits (space, leading zero not included) for variable format
// if no mask is specified a minimum of 5 digits is required but no more than 15 digits and no format is applied

export const commonCountryPrefixes: CountryPrefix[] = [
  { country: 'Afghanistan', prefix: '+93', code: 'AF' },
  { country: 'Afrique du Sud', prefix: '+27', code: 'ZA' },
  { country: 'Albanie', prefix: '+355', code: 'AL' },
  { country: 'Algérie', prefix: '+213', code: 'DZ' },
  { country: 'Angola', prefix: '+244', code: 'AO' },
  { country: 'Anguilla', prefix: '+1264', code: '??' },
  { country: 'Antarctique', prefix: '+672', code: '??' },
  { country: 'Antigua-et-Barbuda', prefix: '+1268', code: 'AG' },
  { country: 'Arabie saoudite', prefix: '+966', code: 'SA' },
  { country: 'Argentine', prefix: '+54', code: 'AR' },
  { country: 'Arménie', prefix: '+374', code: 'AM' },
  { country: 'Aruba', prefix: '+297', code: 'AW' },
  { country: 'Ascension', prefix: '+247', code: '??' },
  { country: 'Australie', prefix: '+61', code: 'AU' },
  { country: 'Autriche', prefix: '+43', code: 'AT' },
  { country: 'Azerbaïdjan', prefix: '+994', code: 'AZ' },
  { country: 'Bahamas', prefix: '+1242', code: 'BS' },
  { country: 'Bahreïn', prefix: '+973', code: '??' },
  { country: 'Bangladesh', prefix: '+880', code: 'BD' },
  { country: 'Barbade', prefix: '+1246', code: 'BB' },
  { country: 'Belize', prefix: '+501', code: 'BZ' },
  { country: 'Bénin', prefix: '+229', code: 'BJ' },
  { country: 'Bermudes', prefix: '+1441', code: '??' },
  { country: 'Bhoutan', prefix: '+975', code: 'BT' },
  { country: 'Biélorussie', prefix: '+375', code: 'BY' },
  { country: 'Birmanie', prefix: '+95', code: 'MM' },
  { country: 'Bolivie', prefix: '+591', code: 'BO' },
  { country: 'Bosnie-Herzégovine', prefix: '+387', code: 'BA' },
  { country: 'Botswana', prefix: '+267', code: 'BW' },
  { country: 'Brésil', prefix: '+55', code: 'BR' },
  { country: 'Brunei', prefix: '+673', code: 'BN' },
  { country: 'Bulgarie', prefix: '+359', code: 'BG' },
  { country: 'Burkina Faso', prefix: '+226', code: '??' },
  { country: 'Burundi', prefix: '+257', code: 'BI' },
  { country: 'Cambodge', prefix: '+855', code: 'KH' },
  { country: 'Cameroun', prefix: '+237', code: 'CM' },
  { country: 'Canada', prefix: '+1', code: 'CA' },
  { country: 'Cap-Vert', prefix: '+238', code: 'CV' },
  { country: 'Chili', prefix: '+56', code: 'CL' },
  { country: 'Chine (République populaire de Chine)', prefix: '+86', code: '??' },
  { country: 'Chypre', prefix: '+357', code: 'CY' },
  { country: 'Colombie', prefix: '+57', code: 'CO' },
  { country: 'Comores', prefix: '+269', code: 'KM' },
  { country: 'Corée du Nord', prefix: '+850', code: 'KP' },
  { country: 'Corée du Sud', prefix: '+82', code: '??' },
  { country: 'Costa Rica', prefix: '+506', code: 'CR' },
  { country: "Côte d'Ivoire", prefix: '+225', code: 'CI' },
  { country: 'Croatie', prefix: '+385', code: 'HR' },
  { country: 'Cuba', prefix: '+53', code: 'CU' },
  { country: 'Curaçao', prefix: '+599', code: '??' },
  { country: 'Danemark', prefix: '+45', code: 'DK' },
  { country: 'Djibouti', prefix: '+253', code: 'DJ' },
  { country: 'Dominique', prefix: '+1767', code: 'DM' },
  { country: 'Egypte', prefix: '+20', code: '??' },
  { country: 'Emirats arabes unis', prefix: '+971', code: '??' },
  { country: 'Equateur', prefix: '+593', code: '??' },
  { country: 'Erythrée', prefix: '+291', code: '??' },
  { country: 'Estonie', prefix: '+372', code: 'EE' },
  { country: 'Etats-Unis', prefix: '+1', code: '??' },
  { country: 'Ethiopie', prefix: '+251', code: '??' },
  { country: 'Fidji', prefix: '+679', code: 'FJ' },
  { country: 'Finlande', prefix: '+358', code: 'FI' },
  { country: 'Gabon', prefix: '+241', code: 'GA' },
  { country: 'Gambie', prefix: '+220', code: 'GM' },
  { country: 'Géorgie', prefix: '+995', code: 'GE' },
  { country: 'Géorgie du Sud-et-les îles Sandwich du Sud', prefix: '+500', code: '??' },
  { country: 'Ghana', prefix: '+233', code: 'GH' },
  { country: 'Gibraltar', prefix: '+350', code: '??' },
  { country: 'Grèce', prefix: '+30', code: 'GR' },
  { country: 'Grenade', prefix: '+1473', code: 'GD' },
  { country: 'Groenland', prefix: '+299', code: '??' },
  { country: 'Guadeloupe', prefix: '+590', code: '??' },
  { country: 'Guam', prefix: '+1671', code: '??' },
  { country: 'Guatemala', prefix: '+502', code: 'GT' },
  { country: 'Guinée', prefix: '+224', code: 'GN' },
  { country: 'Guinée équatoriale', prefix: '+240', code: 'GQ' },
  { country: 'Guinée-Bissau', prefix: '+245', code: '??' },
  { country: 'Guyana', prefix: '+592', code: 'GY' },
  { country: 'Guyane', prefix: '+594', code: '??' },
  { country: 'Haïti', prefix: '+509', code: 'HT' },
  { country: 'Honduras', prefix: '+504', code: 'HN' },
  { country: 'Hong Kong', prefix: '+852', code: '??' },
  { country: 'Hongrie', prefix: '+36', code: 'HU' },
  { country: 'Ile Bouvet', prefix: '+47', code: '??' },
  { country: 'Ile Christmas', prefix: '+672', code: '??' },
  { country: 'Ile Norfolk', prefix: '+6723', code: '??' },
  { country: 'Iles Åland (Finlande)', prefix: '+358', code: '??' },
  { country: 'Iles Caïmans', prefix: '+1345', code: '??' },
  { country: 'Iles Cocos', prefix: '+672', code: '??' },
  { country: 'Iles Cook', prefix: '+682', code: '??' },
  { country: 'Iles Féroé', prefix: '+298', code: '??' },
  { country: 'Iles Heard-et-MacDonald', prefix: '+672', code: '??' },
  { country: 'Iles Mariannes du Nord', prefix: '+1670', code: '??' },
  { country: 'Iles Marshall', prefix: '+692', code: '??' },
  { country: 'Iles Pitcairn', prefix: '+64', code: '??' },
  { country: 'Iles Turques-et-Caïques', prefix: '+1649', code: '??' },
  { country: 'Iles Vierges britanniques', prefix: '+1284', code: '??' },
  { country: 'Iles Vierges des Etats-Unis', prefix: '+1340', code: '??' },
  { country: 'Inde', prefix: '+91', code: 'IN' },
  { country: 'Indonésie', prefix: '+62', code: 'ID' },
  { country: 'Irak', prefix: '+964', code: '??' },
  { country: 'Iran', prefix: '+98', code: 'IR' },
  { country: 'Irlande', prefix: '+353', code: 'IE' },
  { country: 'Islande', prefix: '+354', code: 'IS' },
  { country: 'Israël', prefix: '+972', code: 'IL' },
  { country: 'Jamaïque', prefix: '+1876', code: 'JM' },
  { country: 'Jan Mayen', prefix: '+47', code: '??' },
  { country: 'Japon', prefix: '+81', code: 'JP' },
  { country: 'Jordanie', prefix: '+962', code: 'JO' },
  { country: 'Kazakhstan', prefix: '+7', code: 'KZ' },
  { country: 'Kenya', prefix: '+254', code: 'KE' },
  { country: 'Kirghizistan', prefix: '+996', code: 'KG' },
  { country: 'Kiribati', prefix: '+686', code: 'KI' },
  { country: 'Kosovo', prefix: '+383', code: '??' },
  { country: 'Koweït', prefix: '+965', code: 'KW' },
  { country: 'La Réunion', prefix: '+262', code: '??' },
  { country: 'Laos', prefix: '+856', code: 'LA' },
  { country: 'Lesotho', prefix: '+266', code: 'LS' },
  { country: 'Lettonie', prefix: '+371', code: 'LV' },
  { country: 'Liban', prefix: '+961', code: 'LB' },
  { country: 'Liberia', prefix: '+231', code: 'LR' },
  { country: 'Libye', prefix: '+218', code: 'LY' },
  { country: 'Liechtenstein', prefix: '+423', code: 'LI' },
  { country: 'Lituanie', prefix: '+370', code: 'LT' },
  { country: 'Macao', prefix: '+853', code: '??' },
  { country: 'Madagascar', prefix: '+261', code: 'MG' },
  { country: 'Malaisie', prefix: '+60', code: 'MY' },
  { country: 'Malawi', prefix: '+265', code: 'MW' },
  { country: 'Maldives', prefix: '+960', code: 'MV' },
  { country: 'Mali', prefix: '+223', code: 'ML' },
  { country: 'Malouines', prefix: '+500', code: '??' },
  { country: 'Malte', prefix: '+356', code: 'MT' },
  { country: 'Maroc', prefix: '+212', code: 'MA' },
  { country: 'Martinique', prefix: '+596', code: '??' },
  { country: 'Maurice', prefix: '+230', code: 'MU' },
  { country: 'Mauritanie', prefix: '+222', code: 'MR' },
  { country: 'Mayotte', prefix: '+262', code: '??' },
  { country: 'Mexique', prefix: '+52', code: 'MX' },
  { country: 'Micronésie', prefix: '+691', code: 'FM' },
  { country: 'Moldavie', prefix: '+373', code: 'MD' },
  { country: 'Mongolie', prefix: '+976', code: 'MN' },
  { country: 'Monténégro', prefix: '+382', code: '??' },
  { country: 'Montserrat', prefix: '+1664', code: '??' },
  { country: 'Mozambique', prefix: '+258', code: 'MZ' },
  { country: 'Namibie', prefix: '+264', code: 'NA' },
  { country: 'Nauru', prefix: '+674', code: 'NR' },
  { country: 'Népal', prefix: '+977', code: 'NP' },
  { country: 'Nicaragua', prefix: '+505', code: 'NI' },
  { country: 'Niger', prefix: '+227', code: 'NE' },
  { country: 'Nigeria', prefix: '+234', code: 'NG' },
  { country: 'Niue', prefix: '+683', code: 'NU' },
  { country: 'Norvège', prefix: '+47', code: 'NO' },
  { country: 'Nouvelle-Calédonie', prefix: '+687', code: '??' },
  { country: 'Nouvelle-Zélande', prefix: '+64', code: 'NZ' },
  { country: 'Oman', prefix: '+968', code: 'OM' },
  { country: 'Ouganda', prefix: '+256', code: 'UG' },
  { country: 'Ouzbékistan', prefix: '+998', code: 'UZ' },
  { country: 'Pakistan', prefix: '+92', code: 'PK' },
  { country: 'Palaos', prefix: '+680', code: 'PW' },
  { country: 'Palestine', prefix: '+970', code: '??' },
  { country: 'Panama', prefix: '+507', code: 'PA' },
  { country: 'Papouasie-Nouvelle-Guinée', prefix: '+675', code: '??' },
  { country: 'Paraguay', prefix: '+595', code: 'PY' },
  { country: 'Pays-Bas', prefix: '+31', code: 'NL' },
  { country: 'Pays-Bas caribéens', prefix: '+599', code: '??' },
  { country: 'Pérou', prefix: '+51', code: 'PE' },
  { country: 'Philippines', prefix: '+63', code: 'PH' },
  { country: 'Pologne', prefix: '+48', code: 'PL' },
  { country: 'Polynésie française', prefix: '+689', code: '??' },
  { country: 'Porto Rico', prefix: '+1787', code: '??' },
  { country: 'Porto Rico', prefix: '+1939', code: '??' },
  { country: 'Qatar', prefix: '+974', code: 'QA' },
  { country: 'République arabe sahraouie démocratique (Sahara occidental)', prefix: '+212', code: '??' },
  { country: 'République centrafricaine', prefix: '+236', code: 'CF' },
  { country: 'République de Macédoine', prefix: '+389', code: '??' },
  { country: 'République démocratique du Congo', prefix: '+243', code: '??' },
  { country: 'République dominicaine', prefix: '+1809', code: 'DO' },
  { country: 'République dominicaine', prefix: '+1829', code: 'DO' },
  { country: 'République dominicaine', prefix: '+1849', code: 'DO' },
  { country: 'République du Congo', prefix: '+242', code: '??' },
  { country: 'République tchèque', prefix: '+420', code: 'CZ' },
  { country: 'Roumanie', prefix: '+40', code: 'RO' },
  { country: 'Russie', prefix: '+7', code: 'RU' },
  { country: 'Rwanda', prefix: '+250', code: 'RW' },
  { country: 'Saint-Barthélemy', prefix: '+590', code: '??' },
  { country: 'Saint-Christophe-et-Niévès', prefix: '+1869', code: 'KN' },
  { country: 'Saint-Marin', prefix: '+378', code: 'SM' },
  { country: 'Saint-Martin', prefix: '+590', code: 'SX' },
  { country: 'Saint-Martin (Royaume des Pays Bas)', prefix: '+1721', code: '??' },
  { country: 'Saint-Pierre-et-Miquelon', prefix: '+508', code: '??' },
  { country: 'Saint-Vincent-et-les-Grenadines', prefix: '+1784', code: '??' },
  { country: 'Sainte-Hélène, Ascension et Tristan da Cunha', prefix: '+290', code: '??' },
  { country: 'Sainte-Lucie', prefix: '+1758', code: 'LC' },
  { country: 'Salomon', prefix: '+677', code: 'SB' },
  { country: 'Salvador', prefix: '+503', code: '??' },
  { country: 'Samoa', prefix: '+685', code: '??' },
  { country: 'Samoa américaines', prefix: '+1684', code: '??' },
  { country: 'Sao Tomé-et-Principe', prefix: '+239', code: 'ST' },
  { country: 'Sénégal', prefix: '+221', code: 'SN' },
  { country: 'Serbie', prefix: '+381', code: 'RS' },
  { country: 'Seychelles', prefix: '+248', code: 'SC' },
  { country: 'Sierra Leone', prefix: '+232', code: 'SL' },
  { country: 'Singapour', prefix: '+65', code: 'SG' },
  { country: 'Slovaquie', prefix: '+421', code: 'SK' },
  { country: 'Slovénie', prefix: '+386', code: 'SI' },
  { country: 'Somalie', prefix: '+252', code: 'SO' },
  { country: 'Soudan', prefix: '+249', code: 'SD' },
  { country: 'Soudan du Sud', prefix: '+211', code: '??' },
  { country: 'Sri Lanka', prefix: '+94', code: 'LK' },
  { country: 'Suède', prefix: '+46', code: 'SE' },
  { country: 'Suriname', prefix: '+597', code: 'SR' },
  { country: 'Swaziland', prefix: '+268', code: 'SZ' },
  { country: 'Syrie', prefix: '+963', code: 'SY' },
  { country: 'Tadjikistan', prefix: '+992', code: 'TJ' },
  { country: 'Taïwan (République de Chine)', prefix: '+886', code: '??' },
  { country: 'Tanzanie', prefix: '+255', code: 'TZ' },
  { country: 'Tchad', prefix: '+235', code: 'TD' },
  { country: 'Terres australes et antarctiques françaises', prefix: '+262', code: '??' },
  { country: "Territoire britannique de l'océan Indien", prefix: '+246', code: '??' },
  { country: 'Thaïlande', prefix: '+66', code: 'TH' },
  { country: 'Timor oriental', prefix: '+670', code: 'TL' },
  { country: 'Togo', prefix: '+228', code: 'TG' },
  { country: 'Tokelau', prefix: '+690', code: '??' },
  { country: 'Tonga', prefix: '+676', code: 'TO' },
  { country: 'Trinité-et-Tobago', prefix: '+1868', code: 'TT' },
  { country: 'Tunisie', prefix: '+216', code: 'TN' },
  { country: 'Turkménistan', prefix: '+993', code: 'TM' },
  { country: 'Turquie', prefix: '+90', code: 'TR' },
  { country: 'Tuvalu', prefix: '+688', code: 'TV' },
  { country: 'Ukraine', prefix: '+380', code: 'UA' },
  { country: 'Uruguay', prefix: '+598', code: 'UY' },
  { country: 'Vanuatu', prefix: '+678', code: 'VU' },
  { country: 'Vatican (Saint-Siège)', prefix: '+379', code: '??' },
  { country: 'Venezuela', prefix: '+58', code: 'VE' },
  { country: 'Viêt Nam', prefix: '+84', code: 'VN' },
  { country: 'Wallis-et-Futuna', prefix: '+681', code: '??' },
  { country: 'Yémen', prefix: '+967', code: 'YE' },
  { country: 'Zambie', prefix: '+260', code: 'ZM' },
  { country: 'Zimbabwe', prefix: '+263', code: 'ZW' },
  { country: 'Territoires extérieurs de l’Australie', prefix: '+672', code: '??' }
];

const landlinePrefixes: CountryPrefix[] = [
  {
    country: 'Allemagne',
    prefix: '+49',
    mask: [/\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    minDigits: 8,
    code: 'DE'
  },
  { country: 'Andorre', prefix: '+376', mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/], code: 'AD' },
  {
    country: 'Belgique',
    prefix: '+32',
    mask: [/\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'BE'
  },
  {
    country: 'Espagne',
    prefix: '+34',
    mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    code: 'ES'
  },
  {
    country: 'France',
    prefix: '+33',
    leadingZero: true,
    mask: [/[1-589]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'FR'
  },
  {
    country: 'Guernesey',
    prefix: '+44',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    minDigits: 9
  },
  {
    country: 'Ile de Man',
    prefix: '+44',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    minDigits: 9
  },
  {
    country: 'Italie',
    prefix: '+39',
    mask: [/[0]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    minDigits: 6,
    code: 'IT'
  },
  {
    country: 'Jersey',
    prefix: '+44',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    minDigits: 9
  },
  {
    country: 'Luxembourg',
    prefix: '+352',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    minDigits: 6,
    code: 'LU'
  },
  {
    country: 'Monaco',
    prefix: '+377',
    mask: [/[9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'MC'
  },
  {
    country: 'Portugal',
    prefix: '+351',
    mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    code: 'PT'
  },
  {
    country: 'Royaume-Uni',
    prefix: '+44',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    minDigits: 9,
    code: 'GB'
  },
  {
    country: 'Suisse',
    prefix: '+41',
    mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'CH'
  }
];

const mobilePrefixes: CountryPrefix[] = [
  { country: 'Allemagne', prefix: '+49', code: 'DE' },
  { country: 'Andorre', prefix: '+376', code: 'AD' },
  { country: 'Belgique', prefix: '+32', code: 'BE' },
  { country: 'Espagne', prefix: '+34', code: 'ES' },
  {
    country: 'France',
    prefix: '+33',
    leadingZero: true,
    mask: [/[67]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'FR'
  },
  { country: 'Guernesey', prefix: '+44', code: '??' },
  { country: 'Ile de Man', prefix: '+44', code: '??' },
  { country: 'Italie', prefix: '+39', code: 'IT' },
  { country: 'Jersey', prefix: '+44', code: '??' },
  { country: 'Luxembourg', prefix: '+352', code: 'LU' },
  { country: 'Monaco', prefix: '+377', code: 'MC' },
  { country: 'Portugal', prefix: '+351', code: 'PT' },
  { country: 'Royaume-Uni', prefix: '+44', code: 'GB' },
  { country: 'Suisse', prefix: '+41', code: 'CH' }
];

const universalPrefixes: CountryPrefix[] = [
  { country: 'Allemagne', prefix: '+49', code: 'DE' },
  { country: 'Andorre', prefix: '+376', code: 'AD' },
  { country: 'Belgique', prefix: '+32', code: 'BE' },
  { country: 'Espagne', prefix: '+34', code: 'ES' },
  {
    country: 'France',
    prefix: '+33',
    leadingZero: true,
    mask: [/[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    code: 'FR'
  },
  { country: 'Guernesey', prefix: '+44', code: '??' },
  { country: 'Ile de Man', prefix: '+44', code: '??' },
  { country: 'Italie', prefix: '+39', code: 'IT' },
  { country: 'Jersey', prefix: '+44', code: '??' },
  { country: 'Luxembourg', prefix: '+352', code: 'LU' },
  { country: 'Monaco', prefix: '+377', code: 'MC' },
  { country: 'Portugal', prefix: '+351', code: 'PT' },
  { country: 'Royaume-Uni', prefix: '+44', code: 'GB' },
  { country: 'Suisse', prefix: '+41', code: 'CH' }
];

export const countryPrefixes: { [key: string]: CountryPrefix[] } = {
  common: commonCountryPrefixes,
  landline: landlinePrefixes,
  mobile: mobilePrefixes,
  universal: universalPrefixes
};
