import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiThemeModule } from '@bpce/ui/theming';
import { UiToasterContentDirective } from './toaster-content.directive';
import { UiToasterComponent } from './toaster.component';

@NgModule({
  imports: [CommonModule, UiThemeModule, A11yModule],
  declarations: [UiToasterComponent, UiToasterContentDirective],
  entryComponents: [UiToasterComponent],
  exports: [UiToasterComponent, UiToasterContentDirective]
})
export class UiToasterModule {}
