<div class="tablist-container">
  <div
    role="tablist"
    [attr.aria-labelledby]="ariaLabelledBy"
    class="tablist automatic"
    [attr.data-default-tab]="'tab-' + default"
  >
    <ng-content></ng-content>
  </div>
</div>
