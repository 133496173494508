

<div class="container" [class.centered]="centered">
  <div class="row justify-content-center">
    <app-page-title *ngIf="title" [title]="title" [big]="true" class="col-12 col-md-10 col-lg-8"></app-page-title>

    <div class="col-12 col-md-8 col-lg-6">
      <ng-content></ng-content>
    </div>
  </div>
</div>
