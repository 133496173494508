import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UiIconRegistry } from './icon-registry.service';

/**
 * @deprecated Use UiIconV2, UiIcon will be removed in @bpce/ui@14.0.0
 */
@Component({
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiIconComponent implements OnInit, OnChanges {
  /**
   * fontIcon : the name of the font-icon
   * fontIconSize : the size of the font-icon
   */
  @Input() fontIcon: string;
  @Input() fontIconSize: string;

  /**
   * svgIcon : the name of the svg icon
   * svgIconSize : the size of the svg icon
   * svgIconPadding : the padding of the svg icon
   */
  @Input() svgIcon: string;
  @Input() svgIconSize: string | number;
  @Input() svgIconPadding: string | number = '16';

  @Input() ariaLabel: string;

  svgFile$: Observable<SafeHtml>;

  @ViewChild('svgIconRef') svgIconRef: ElementRef;

  namespace = '';
  name = '';
  iconClass: string[] = [];

  constructor(private readonly iconRegistry: UiIconRegistry, private readonly sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.svgIcon && changes.svgIcon.previousValue) || (changes.fontIcon && changes.fontIcon.previousValue)) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    if (this.fontIcon) {
      this.iconClass = ['bpce-icon', 'font-icon', this.fontIcon];
    }
    if (this.svgIcon) {
      const nameIcon = this.svgIcon.split(':');
      this.svgFile$ = this.iconRegistry
        .getNamedSvgIcon(nameIcon[1], nameIcon[0])
        .pipe(map(data => this.getSafeHtmlSvg(data)));
    }
  }

  getSafeHtmlSvg(data: SVGElement): SafeHtml {
    // we need to use XmlSerializer to display correctly svg in ie 11. The innerHtml does not work very well.
    return this.sanitizer.bypassSecurityTrustHtml(new XMLSerializer().serializeToString(data));
  }
}
