/*
 * Public API ui/modal
 */
export { UiModalContentWrapper, UiModalTitle, UiModalContent, UiModalActions } from './modal-content-directives';
export {
  ModalMode,
  ModalState,
  ModalConfig,
  ModalContent,
  ModalRef,
  DialogPolyfill,
  IEvent,
  MODAL_STATE,
  BPCE_MODAL_DATA,
  BPCE_MODAL_OVERLAY_CLICK,
  BPCE_MODAL_CLOSE_EVENT
} from './modal.model';
export { ModalComponent } from './modal.component';
export {
  bpceModalDataSubject,
  bpceModalOverlayClickSubject,
  bpceModalCloseEventSubject,
  UiModalModule
} from './modal.module';
export { ModalBuilderService } from './services/modal.builder.service';
export { ModalService } from './services/modal.service';
