import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input('index') index;
  @Input('isVisible') isVisible = false;

  constructor() {}

  ngOnInit(): void {}

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }

  getClasses() {
    return [this.isVisible ? '' : 'is-hidden', 'tabpanel'];
  }
}
