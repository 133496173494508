import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CcoopApiService} from "./ccoop-api.service";

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {

  constructor(
    private apiService: CcoopApiService,
  ) {
  }


  public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.apiService.bearerToken !== undefined) {
      httpRequest = httpRequest.clone({
        setHeaders: {bearer: this.apiService.bearerToken}
      });
    }

    if (this.apiService.password !== undefined) {
      httpRequest = httpRequest.clone({
        setHeaders: {password: this.apiService.password}
      });
    }

    return next
      .handle(httpRequest)
  }
}
