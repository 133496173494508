import { ComponentType } from '@angular/cdk/portal';
import { ComponentFactoryResolver, ComponentRef, Directive, Injector, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[uiToasterContent]',
  exportAs: 'uiToasterContent'
})
export class UiToasterContentDirective {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  attach<T>(cmp: ComponentType<T>, injector: Injector | undefined): ComponentRef<T> {
    const cmpFactory = this.componentFactoryResolver.resolveComponentFactory<T>(cmp);
    return this.viewContainerRef.createComponent(cmpFactory, this.viewContainerRef.length, injector);
  }
}
