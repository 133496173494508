import { Inject, InjectionToken, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { provideDqeConfigService } from '../dqe/dqe.config';
import { provideUrlDqmService, URL_DQM } from '../dqe/urlInjectionToken';
import { DqeEmailV2Service } from './dqe-email-v2.service';
import { EMAIL_VALIDATION_SERVICE, EMAIL_VALIDATOR } from './email-validation.service';
import { AsyncValidationService, BpceValidators } from './validators';

const DEBOUNCE_TIME = 500;

// prettier-ignore
export function emailValidatorFactory(service: AsyncValidationService<any>): any { // NOSONAR
  return BpceValidators.fromService(service, DEBOUNCE_TIME, true);
}

export function provideEmailValidationService(): Provider {
  return [
    {
      provide: EMAIL_VALIDATION_SERVICE,
      useClass: DqeEmailV2Service
    },
    {
      provide: EMAIL_VALIDATOR,
      useFactory: emailValidatorFactory,
      deps: [EMAIL_VALIDATION_SERVICE]
    },
    DqeEmailV2Service
  ];
}

// ToDo must be possible for the projects to pass a specific provider
@NgModule({})
export class UiEmailValidationModule {
  static forRoot(): ModuleWithProviders<UiEmailValidationModule> {
    return {
      ngModule: UiEmailValidationModule,
      providers: [provideUrlDqmService(''), provideDqeConfigService(URL_DQM), provideEmailValidationService()]
    };
  }

  static url(urlDQM: string): ModuleWithProviders<UiEmailValidationModule> {
    return {
      ngModule: UiEmailValidationModule,
      providers: [provideUrlDqmService(urlDQM), provideDqeConfigService(URL_DQM), provideEmailValidationService()]
    };
  }

  static urlToken(@Inject(URL_DQM) urlDQMToken: InjectionToken<string>): ModuleWithProviders<UiEmailValidationModule> {
    return {
      ngModule: UiEmailValidationModule,
      providers: [provideDqeConfigService(urlDQMToken), provideEmailValidationService()]
    };
  }
}
