import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { UiFabService } from '../fab.service';

/**
 * @name UiFabActionComponent
 * @description
 * Componsant FAB Action
 * @dynamic
 */

const PICTO_SIZE = 54;
@Component({
  selector: 'ui-fab-action',
  templateUrl: './fab-action.component.html',
  styleUrls: ['./fab-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFabActionComponent implements OnInit {
  // label is for accessibility
  @Input()
  label = 'Bouton FAB ACTION';

  @Input()
  scrollContainer: Element;

  @Input()
  picto = 'bpce:question_chat';

  isFabDisplayed = true;
  isMobile = true;
  isOpened = false;
  readonly pictoSize = PICTO_SIZE;

  private readonly previousScrollY = 0;
  private readonly screenH: number;
  private innerHeight = 0;

  private readonly scrollEvent$: Observable<Event>;
  private readonly resizeEvent$: Observable<Event> = fromEvent(window, 'resize');

  constructor(
    @Inject(DOCUMENT) private readonly document: any, // NOSONAR
    private readonly fabService: UiFabService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fabService.init(this);
  }
}
