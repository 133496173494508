<ng-container *ngIf="referentiel$ | async as referentiel">

  <div ui-modal-title>
    <ui-header
      close-title="Fermer la fenêtre de dialogue"
      [showBack]="false"
      [showClose]="true"
      (close)="closed.emit()"
      [showHelp]="false">
      <app-modale-title [title]="referentiel.title"></app-modale-title>
    </ui-header>
  </div>

  <ui-modal-content>
    <ul>
      <li
        *ngFor="let supportingDocument of referentiel.supportingDocuments">
        <app-document-bulletpoint
          [pictogram]="supportingDocument.pictogram"
          [name]="supportingDocument.name"
          [description]="supportingDocument.description">
        </app-document-bulletpoint>
      </li>
    </ul>

  </ui-modal-content>

  <div ui-modal-actions uiButtonV2Group alignment="block">
    <button (click)="closed.emit()" category="primary" uiButtonV2>{{referentiel.dockedButtonLabel}}</button>
  </div>

</ng-container>
