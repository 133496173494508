import { Injectable } from '@angular/core';
import { BankInfo } from './bank-info.model';
import { bankInfoList } from './bank-info.data';

@Injectable({
  providedIn: 'root'
})
export class BankInfoService {
  public get(bicCode: string): BankInfo | undefined {
    return bankInfoList.find(item => item.bic === bicCode);
  }

  public getCustom(comparator: (item: BankInfo) => boolean): BankInfo | undefined {
    return bankInfoList.find(comparator);
  }

  public search(query: string, field: string): BankInfo | undefined {
    return bankInfoList.find(item => typeof item[field] === 'string' && `${item[field]}`.includes(query));
  }

  public getAll(): BankInfo[] {
    return bankInfoList;
  }
}
