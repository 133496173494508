import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BpceA11yModule } from '@bpce/a11y';
import { UiIconV2Module } from '@bpce/ui/icon-v2';
import { UiButtonV2GroupComponent } from './button-v2-group.component';
import { UiButtonV2Component } from './button-v2.component';

@NgModule({
  declarations: [UiButtonV2Component, UiButtonV2GroupComponent],
  exports: [UiButtonV2Component, UiButtonV2GroupComponent],
  imports: [CommonModule, BpceA11yModule, UiIconV2Module]
})
export class UiButtonV2Module {}
