// THIS FILE IS GENERATED, PLEASE DO NOT EDIT DIRECTLY
export const COMMONS_VARIABLES = {
  colors: {
    black: '#000000',
    g1: '#333333',
    g2: '#666666',
    g3: '#999999',
    g4: '#cccccc',
    g5: '#f5f5f5',
    g6: '#f9f9fb',
    white: '#ffffff',
    success: '#398538',
    textSuccess: '#398538',
    warning: '#fa5c00',
    textWarning: '#666666',
    error: '#e91b06',
    textError: '#e91b06'
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};
