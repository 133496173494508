import { Injectable } from '@angular/core';
import { DeviceDetectorService } from '@bpce/ng-detector';
import { UnsubscriberComponent } from '@bpce/utils';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

const TEN = 10;
const DB_TIME = 25;
@Injectable()
export class UiFabService extends UnsubscriberComponent {
  constructor(private readonly deviceDetector: DeviceDetectorService) {
    super();
  }

  /**
   * @description Determine if fab can be displayed or not, used during scroll
   * @param {number} yOffset Number of pixel currently scrolled along the vertical axis
   * @param {number} previousOffset Previous value of yOffset
   * @param {number} screenHeight Screen height
   */
  canDisplayFab(yOffset: number, previousOffset: number, screenHeight: number): boolean {
    const deltaY = yOffset - previousOffset;
    // when scroll is at the end (between screenHeight-10 and screenHeight)
    const isScrollEnd = yOffset >= screenHeight - TEN;
    // when scroll is at the beginning (between 0 and 10)
    const isScrollStart = yOffset < TEN;
    // when scrolling down
    const isScrollingDown = deltaY > 0;
    return isScrollEnd || isScrollStart ? true : isScrollingDown ? false : true;
  }

  // prettier-ignore
  init(thisArg: any): void { // NOSONAR
    this.deviceDetector.isMobileView$.pipe(this.autoUnsubscriber()).subscribe(value => (thisArg.isMobile = value));
    if (thisArg.scrollContainer) {
      thisArg.scrollEvent$ = fromEvent(thisArg.scrollContainer, 'scroll');
      thisArg.screenH = thisArg.scrollContainer.scrollHeight - thisArg.document.documentElement.clientHeight;
      thisArg.previousScrollY = thisArg.scrollContainer.scrollTop;
    } else {
      thisArg.scrollEvent$ = fromEvent(window, 'scroll');
      thisArg.screenH = thisArg.document.body.clientHeight;
      thisArg.innerHeight = window.innerHeight;
      thisArg.previousScrollY = window.pageYOffset;
    }

    thisArg.scrollEvent$
      .pipe(
        this.autoUnsubscriber(),
        filter(() => thisArg.isMobile),
        debounceTime(DB_TIME)
      )
      .subscribe(() => this.onScroll(this));

    thisArg.resizeEvent$
      .pipe(
        this.autoUnsubscriber(),
        filter(() => thisArg.isMobile),
        debounceTime(DB_TIME)
      )
      .subscribe(() => this.onResize(this));
  }

  /**
   * @description Function called on scroll. Handle fab display
   */
  // prettier-ignore
  onScroll(thisArg: any): void { // NOSONAR
    const previousValue = thisArg.isFabDisplayed;
    if (thisArg.isMobile && !thisArg.isOpened) {
      const yOffset = thisArg.scrollContainer ? thisArg.scrollContainer.scrollTop : window.pageYOffset + innerHeight;
      thisArg.isFabDisplayed = this.canDisplayFab(yOffset, thisArg.previousScrollY, thisArg.screenH);
      thisArg.previousScrollY = yOffset;
    }
    if (previousValue !== thisArg.isFabDisplayed) {
      thisArg.cdr.markForCheck();
    }
  }

  /**
   * @description Function called on page resize. Handle screen size attribute
   */
  // prettier-ignore
  onResize(thisArg: any): void { // NOSONAR
    thisArg.screenH = thisArg.scrollContainer
      ? thisArg.scrollContainer.scrollHeight - thisArg.document.documentElement.clientHeight
      : thisArg.document.body.clientHeight;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thisArg.innerHeight = window.innerHeight;
  }
}
