<dialog
  #modal
  [attr.id]="id"
  [ngClass]="{ 'bpce-modal-footer-is-sticky': hasStickyFooter }"
  class="bpce-modal {{ modalStyle }}"
  (mousedown)="onModalMouseDown($event)"
>
  <!-- Overlay -->
  <div class="bpce-modal-backdrop"></div>

  <!-- Injected modal content -->
  <div #modalAnimateContainer class="bpce-modal-animate-container" cdkTrapFocus>
    <ng-template uiModalContentWrapper></ng-template>
  </div>
</dialog>
