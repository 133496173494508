import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { KeyboardUtils } from '@bpce/utils';
import { fromEvent, Observable } from 'rxjs';
import { UiFabService } from '../fab.service';

const PICTO_SIZE = 54;
const ICON_SIZE = 32;

/**
 * @name UiFabCustomComponent
 * @description
 * Componsant FAB Custom
 * @dynamic
 */
@Component({
  selector: 'ui-fab-custom',
  templateUrl: './fab-custom.component.html',
  styleUrls: ['./fab-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFabCustomComponent implements OnInit {
  // label is for accessibility
  @Input()
  label = 'Bouton FAB CUSTOM';

  @Input()
  scrollContainer: Element;

  @Input()
  picto = 'bpce:question_chat';

  @Output()
  opened = new EventEmitter<Event>();

  @Output()
  closed = new EventEmitter<Event>();

  @HostBinding('class.bpce-fab-open')
  isOpened = false;

  isFabDisplayed = true;
  isMobile = true;
  readonly closeIcon = 'close';
  readonly pictoSize = PICTO_SIZE;
  readonly closeIconSize = ICON_SIZE;

  private readonly previousScrollY = 0;
  private readonly screenH: number;
  private innerHeight = 0;

  scrollEvent$: Observable<Event>;
  resizeEvent$: Observable<Event> = fromEvent(window, 'resize');

  constructor(
    @Inject(DOCUMENT) private readonly document: any, // NOSONAR
    private readonly fabService: UiFabService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fabService.init(this);
  }

  /**
   * @description Function called on FAB button click. Handle opening/closing menu
   * @param event MouseEvent
   */
  onFabClick(event: MouseEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isOpened ? this.closeMenu(event) : this.openMenu(event);
  }

  /**
   * @description Function called on FAB button keydown. Handle closing menu
   * @param event MouseEvent
   */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (KeyboardUtils.isEscapeKey(event)) {
      this.closeMenu(event);
    }
  }

  /**
   * @description Function called on FAB button. Handle closing menu on overlay
   * @param event MouseEvent
   */
  onOverlayClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.closeMenu(event);
  }

  /**
   * @description Open menu from fab
   * @param event MouseEvent
   */
  private openMenu(event: Event): void {
    this.isOpened = true;
    this.opened.emit(event);
  }

  /**
   * @description Close menu from fab
   * @param event MouseEvent
   */
  private closeMenu(event: Event): void {
    this.isOpened = false;
    this.closed.emit(event);
  }
}
