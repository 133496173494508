import { ModuleWithProviders, NgModule } from '@angular/core';
import { URL_DQE } from './url-dqe-phone.service';
import { IDqeConf } from './dqe.model';

@NgModule({})
export class UiPhoneValidationModule {
  static url(urlPhoneDQE: IDqeConf): ModuleWithProviders<UiPhoneValidationModule> {
    return {
      providers: [{ provide: URL_DQE, useValue: urlPhoneDQE }],
      ngModule: UiPhoneValidationModule
    };
  }
}
