import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface BpceSubscribeContext<T> {
  bpceSubscriber: T;
}

@Directive({
  selector: '[bpceSubscriber]'
})
export class BpceSubscriberDirective<T> implements OnInit, OnDestroy {
  private readonly context: BpceSubscribeContext<T | null> = { bpceSubscriber: null };
  private subscription: Subscription;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly templateRef: TemplateRef<unknown>
  ) {}

  @Input()
  set bpceSubscriber(inputObservable: Observable<T>) {
    this.registerObservable(inputObservable);
  }

  ngOnInit() {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private registerObservable(inputObservable: Observable<T>) {
    this.getNewSubscription().add(
      inputObservable
        .pipe(
          tap((value: T) => {
            this.context.bpceSubscriber = value;
            this.changeDetectorRef.markForCheck();
          })
        )
        .subscribe()
    );
  }

  private getNewSubscription(): Subscription {
    this.unsubscribe();
    this.subscription = new Subscription();
    return this.subscription;
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
