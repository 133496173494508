import {NgModule} from '@angular/core';
import {DockedButtonComponent} from './components/docked-button/docked-button.component';
import {UiButtonV2Module} from '@bpce/ui/button-v2';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {PageBodyComponent} from './components/page-body/page-body.component';
import {PageTemplateComponent} from './templates/page-template/page-template.component';
import {UiHeaderModule} from '@bpce/ui/header';
import {CommonModule} from '@angular/common';
import {InnerHtmlComponent} from './components/inner-html/inner-html.component';
import {UiFabModule} from '@bpce/ui/fab';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {UiIllustrationModule} from '@bpce/ui/illustration';
import {PageFooterComponent} from './components/page-footer/page-footer.component';
import {UiFooterModule} from '@bpce/ui/footer';
import {HelpModaleComponent} from './components/help-modale/help-modale.component';
import {UiModalModule} from '@bpce/ui/modal';
import {UiInterstitialModule} from '@bpce/ui/interstitial';
import {FabHelpButtonComponent} from './components/fab-help-button/fab-help-button.component';
import {QuitModaleComponent} from './components/quit-modale/quit-modale.component';
import {ModaleTitleComponent} from './components/modale-title/modale-title.component';
import {UiStepperModule} from '@bpce/ui/stepper';
import {ExternalLinkComponent} from './components/external-link/external-link.component';
import {UiIconV2Module} from '@bpce/ui/icon-v2';
import {IFrameModaleComponent} from './components/iframe-modale/i-frame-modale.component';
import {IdPipe} from './pipes/IdPipe';
import {DocumentsModaleComponent} from './components/documents-modale/documents-modale.component';
import {DocumentBulletPointComponent} from './components/documents-modale/document-bulletpoint/document-bullet-point.component';
import {UiPictogramModule} from '@bpce/ui/pictogram';
import {InfoLegalesModaleComponent} from './components/info-legales-modale/info-legales-modale.component';
import {LoaderOverlayComponent} from './components/loader-overlay/loader-overlay.component';
import {UiLoaderModule} from '@bpce/ui/loader';
import {LoadingService} from './services/loading.service';
import {NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {BrowserSupportModaleComponent} from './components/browser-support-modale/browser-support-modale.component';
import {BrowsersComponent} from './components/browsers/browsers.component';
import {SegmentedControlComponent} from './components/segmented-control/segmented-control.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {TabComponent} from './components/tabs/tab/tab.component';
import {SegmentComponent} from './components/segmented-control/segment/segment.component';

const uiModules = [
  UiIllustrationModule,
  UiFooterModule,
  UiModalModule,
  UiInterstitialModule,
  UiFabModule,
  UiButtonV2Module,
  UiHeaderModule,
  UiStepperModule,
  UiIconV2Module,
  UiPictogramModule,
];

@NgModule({
  declarations: [
    BrowsersComponent,
    BrowserSupportModaleComponent,
    DocumentsModaleComponent,
    DocumentBulletPointComponent,
    IdPipe,
    IFrameModaleComponent,
    ExternalLinkComponent,
    ModaleTitleComponent,
    InfoLegalesModaleComponent,
    QuitModaleComponent,
    InnerHtmlComponent,
    DockedButtonComponent,
    PageTitleComponent,
    PageFooterComponent,
    PageBodyComponent,
    PageTemplateComponent,
    InnerHtmlComponent,
    HelpModaleComponent,
    PageHeaderComponent,
    FabHelpButtonComponent,
    LoaderOverlayComponent,
    SegmentedControlComponent,
    TabsComponent,
    TabComponent,
    SegmentComponent,
  ],
  imports: [...uiModules, CommonModule, UiLoaderModule, NgxMatomoTrackerModule],
  providers: [LoadingService],
  exports: [
    LoaderOverlayComponent,
    PageTitleComponent,
    HelpModaleComponent,
    PageTemplateComponent,
    PageBodyComponent,
    PageHeaderComponent,
    InnerHtmlComponent,
    ModaleTitleComponent,
    ExternalLinkComponent,
    IdPipe,
    DocumentsModaleComponent,
    FabHelpButtonComponent,
    PageFooterComponent,
    LoaderOverlayComponent,
    LoaderOverlayComponent,
    BrowsersComponent,
    SegmentedControlComponent,
    SegmentComponent,
    TabsComponent,
    TabComponent,
  ],
})
export class SharedModule {}
