import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, shareReplay, startWith } from 'rxjs/operators';
import { Breakpoint } from './breakpoint.enum';
import variables from './common-variables';

const breakpoints = variables.breakpoints;

@Injectable()
/**
 * Detects the current breakpoint and if you are on a mobile resolution
 */
export class BreakpointsService {
  private current$: Observable<Breakpoint>;

  /**
   * @deprecated since VERSIONS 11.13.0 / 12.3.0 Will be removed in 14.0.0
   */
  public currentBreakpoint(): Observable<string> {
    return this.get();
  }

  public isMobile(): Observable<boolean> {
    return this.get().pipe(
      map(breakpoint => breakpoint === Breakpoint.XS || breakpoint === Breakpoint.SM),
      distinctUntilChanged()
    );
  }

  public get(): Observable<Breakpoint> {
    if (!this.current$) {
      const isXS$ = this.fromMedia(`(max-width: ${breakpoints.sm - 1}px)`, Breakpoint.XS);
      const isSM$ = this.fromMedia(
        `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
        Breakpoint.SM
      );
      const isMD$ = this.fromMedia(
        `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
        Breakpoint.MD
      );
      const isLG$ = this.fromMedia(
        `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
        Breakpoint.LG
      );
      const isXL$ = this.fromMedia(`(min-width: ${breakpoints.xl}px)`, Breakpoint.XL);

      this.current$ = merge(isXS$, isSM$, isMD$, isLG$, isXL$).pipe(shareReplay(1));
    }

    return this.current$;
  }

  private fromMedia(query: string, breakpoint: Breakpoint): Observable<Breakpoint> {
    const queryList = window.matchMedia(query);
    return fromEvent<MediaQueryListEvent>(queryList, 'change').pipe(
      startWith(queryList),
      filter(event => event.matches),
      mapTo(breakpoint)
    );
  }
}
