export enum AppRoutes {
  ERROR = 'error',
  STEPS_PRESENTATION = 'steps-presentation',
  MAIL = 'mail',
  MAIL_SAVED = 'mail-saved',
  MAIL_ALREADY_EXISTS = 'mail-already-exists',
  FORMULA_CHOICES = 'formula-choices',
  COMMITMENT_PRINCIPLES = 'commitment-principles',
  COMMITMENT_ACCOUNT = 'commitment-account',
  TRACKING_CHOICES = 'tracking-choices',
  COMMITMENT_CARD = 'commitment-card',
  ASSOCIATION_CHOICES = 'association-choices',
  DONATION_CHOICE = 'donations-choices',
  SUMMARY = 'summary',
  FINAL = 'final',
}
