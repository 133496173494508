export const numberSpaceRegex = new RegExp(/[^\d\s]/gi);

/**
 * Insert the new character in the correct position
 * @param actualVal
 * @param newCharacter
 * @param startPosition
 * @param endPosition
 */
export function insertCharacter(
  actualVal: string,
  newCharacter: string,
  startPosition: number,
  endPosition: number
): string {
  return actualVal.substring(0, startPosition) + newCharacter + actualVal.substring(endPosition, actualVal.length);
}

/**
 * Remove all spaces in the phone number
 * @param phoneNumber
 */
export function removeSpacing(phoneNumber: string): string | null {
  return phoneNumber ? phoneNumber.replace(/\s+/g, '') : null;
}

function compareStrings(a: string, b: string): number {
  a = a.toLowerCase().trim();
  b = b.toLowerCase().trim();

  return a < b ? -1 : a > b ? 1 : 0;
}

// prettier-ignore
export function sortListByKey(list: any[], key: string): unknown[] { // NOSONAR
  return list.sort((a, b) => compareStrings(a[key], b[key]));
}
