import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {BPCE_MODAL_DATA, ModalContent} from "@bpce/ui/modal";
import {BehaviorSubject, Observable} from "rxjs";
import {SupportingDocumentsReferentiel} from '../../../../../../../models/Referentiel';
import { StepsPresentationService } from '../../../../pages-modules/1-presentation-etapes/services/steps-presentation.service';
import {ReferentielService} from "../../../data/services/referentiel.service";

@Component({
  selector: 'app-browsers',
  templateUrl: './browsers.component.html',
  styleUrls: ['./browsers.component.scss']
})
export class BrowsersComponent {

  constructor() {}

}
