import {
  asyncScheduler,
  Observable,
  Observer,
  ReplaySubject,
  Subject,
  Subscription,
  timer as observableTimer
} from 'rxjs';

import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { bufferCount, first, mergeMap, tap } from 'rxjs/operators';
import { AbstractControlWarn } from '../dqe/dqe.model';

// https://github.com/angular/angular/issues/6895
// Async validators don't unsubscribe when called repeatedly
// Thus the need for these factories
// ToDo add dedicated testing
type AsyncValidatorFactory = (service: (value: any) => Observable<any | null>, debounce?: number) => AsyncValidatorFn;

const asyncValidatorFactory: AsyncValidatorFactory = (
  service: (value: any) => Observable<any | null>,
  debounce?: number
): AsyncValidatorFn => {
  let subscription: Subscription = Subscription.EMPTY;
  return (input: AbstractControlWarn) => {
    subscription.unsubscribe();
    return new Observable((observer: Observer<any | null>) => {
      subscription = observableTimer(debounce)
        .pipe(mergeMap(() => service(input.value)))
        .subscribe(observer);
      return () => subscription.unsubscribe();
    });
  };
};

// To save a roundtrip to backend in case the "value changed to the same value",
// Can do so by keeping an internal cache of the latest validation result.
const cachingAsyncValidatorFactory: AsyncValidatorFactory = (
  service: (value: any) => Observable<any | null>,
  debounce?: number
): AsyncValidatorFn => {
  let subscription: Subscription = Subscription.EMPTY;
  const sampler = new Subject<any>();
  const validationCache = new ReplaySubject<any>(1, undefined, asyncScheduler);
  const samplerCache = new ReplaySubject<any>(1);
  sampler.pipe(bufferCount(2, 1)).subscribe(samplerCache);
  sampler.next(null); // prime/invalidate 'samplerCache' with a dummy value
  return (input: AbstractControlWarn) => {
    subscription.unsubscribe();
    return new Observable((observer: Observer<any | null>) => {
      subscription = observableTimer(debounce)
        .pipe(
          mergeMap(() => {
            sampler.next(input.value);
            return samplerCache.pipe(
              first(),
              mergeMap((sample: [any, any]) => {
                if (sample[0] === sample[1]) {
                  return validationCache.pipe(first());
                } else {
                  // introduce side effect via do() by piggybacking on service call result
                  return service(sample[1]).pipe(
                    tap(
                      value => {
                        validationCache.next(value); // cache successfull result into validationCache
                      },
                      () => {
                        sampler.next(null); // invalidate samplerCache due to service error
                      }
                    )
                  );
                }
              })
            );
          })
        )
        .subscribe(observer);
      return () => subscription.unsubscribe();
    });
  };
};

export interface AsyncValidationService<TValue> {
  errorKey: string;

  validate(value: TValue): Observable<ValidationErrors | null>;
}

export class BpceValidators {
  static fromService(
    service: AsyncValidationService<any>,
    debounce: number = 0,
    cache: boolean = false
  ): AsyncValidatorFn {
    if (!cache) {
      return cachingAsyncValidatorFactory(value => service.validate(value), debounce);
    } else {
      return asyncValidatorFactory(value => service.validate(value), debounce);
    }
  }
}
