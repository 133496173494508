import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  SimpleChanges,
  OnChanges,
  Renderer2
} from '@angular/core';
import { SvgEmbedderService } from './svg-embedder.service';

@Component({
  selector: 'ui-svg-embedder',
  template: '<ng-container></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgEmbedderComponent implements OnChanges {
  private svgElement: SVGElement;

  @Input() id = '';
  @Input() src = '';
  @Input() height: number;
  @Input() width: number;
  @Input() classList: string[] = [];

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly svgEmbedderService: SvgEmbedderService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.src) {
      return this.getSvg();
    }

    if (changes && (changes.height || changes.width)) {
      return this.setDimensions();
    }
  }

  private getSvg() {
    if (!this.src || this.svgElement) {
      this.removeSvg();
    }

    this.svgEmbedderService.get(this.src).subscribe(
      svgContent => this.renderSvg(svgContent),
      () => this.removeSvg()
    );
  }

  private renderSvg(svgContent: string): void {
    this.svgElement = this.id ? this.svgElementFromSprite(svgContent) : this.svgElementFromString(svgContent);
    this.setDimensions();
    this.renderer.appendChild(this.elementRef.nativeElement, this.svgElement);
    this.classList.forEach(className => this.renderer.addClass(this.svgElement, className));
  }

  private setDimensions() {
    if (!this.svgElement) {
      return;
    }

    if (!isNaN(this.height)) {
      this.svgElement.setAttribute('height', `${this.height}px`);
    }

    if (!isNaN(this.width)) {
      this.svgElement.setAttribute('width', `${this.width}px`);
    }
  }

  private svgElementFromString(svgContent: string): SVGElement {
    if (!svgContent) {
      return document.createElementNS('http://www.w3.org/2000/svg', 'path');
    }

    const div = this.renderer.createElement('div');
    div.innerHTML = svgContent;

    return div.querySelector('svg') || document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }

  private svgElementFromSprite(svgContent: string): SVGElement {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const regexSymbol = new RegExp(`<symbol viewBox="\\d+ \\d+ \\d+ \\d+" id="${this.id}".*?>(.*?)<\\/symbol>`, 'gi');
    const regExpArray = regexSymbol.exec(svgContent);
    const innerHTML = regExpArray ? regExpArray[1] : '';
    svg.innerHTML = innerHTML;

    const regexViewbox = new RegExp('viewBox="(\\d+ \\d+ \\d+ \\d+)', 'gi');
    const viewboxRegExpArray = regExpArray ? regexViewbox.exec(regExpArray[0]) : null;
    if (viewboxRegExpArray) {
      svg.setAttribute('viewBox', viewboxRegExpArray[1]);
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
    }

    return svg;
  }

  private removeSvg(): void {
    if (this.elementRef.nativeElement && this.svgElement) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.svgElement);
    }
  }
}
