import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InfoBoxType} from "@bpce/ui/infobox";

@Component({
  selector: 'app-document-bulletpoint',
  templateUrl: './document-bullet-point.component.html',
  styleUrls: ['./document-bullet-point.component.scss']
})
export class DocumentBulletPointComponent {
  public InfoBoxType = InfoBoxType;

  @Input() pictogram: string;
  @Input() name: string;
  @Input() description: string;

}
