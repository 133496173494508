import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgEmbedderModule } from '@bpce/ui/utils';

import { UiPictogramComponent } from './pictogram.component';

@NgModule({
  declarations: [UiPictogramComponent],
  imports: [CommonModule, SvgEmbedderModule],
  exports: [UiPictogramComponent]
})
export class UiPictogramModule {}
