import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export enum AutoFocusOption {
  BACK = 'back',
  CLOSE = 'close',
  HELP = 'help'
}

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiHeaderComponent {
  /**
   * If the back button is displayed or not
   */
  @Input() showBack = true;

  /**
   * If the close button is displayed or not
   */
  @Input() showClose = true;

  /**
   * If the help button is displayed or not
   */
  @Input() showHelp = true;

  /**
   * Display a customized right content and ignores 'showHelp' and 'showClose' Inputs.
   */
  @Input() customRightContent: TemplateRef<unknown>;

  /**
   * If the border bottom is displayed or not
   */
  @Input() hasBorderBottom = true;

  /**
   * If the background color is displayed
   */
  @Input() hasBackground = true;

  /**
   * Apply a custom background color
   */
  @Input() backgroundColor = '';

  /**
   * If the shadow is displayed
   */
  @Input() hasShadow = false;

  /**
   * If the focus is hidden on click
   */
  @Input() applyBpceAriaButton = false;

  /**
   * If the acessibility focus is displayed by default on the element
   */
  @Input() applyBpceAutoFocus: AutoFocusOption;

  /**
   * If the label of back button is hidden or not
   */
  @Input() hideBackLabel = false;

  /**
   * Label for the left side button
   */
  @Input() leftButtonLabel?: string;

  /**
   * Pictogram for the left side button
   */
  @Input() leftButtonIcon?: string;

  /**
   * Accessibilty message for the close button
   */
  @Input()
  closeButtonAriaLabel = 'Fermer';

  /**
   * Triggered when the back button is clicked
   */
  @Output() back = new EventEmitter<void>();

  /**
   * Triggered when the close button is clicked
   */
  @Output() close = new EventEmitter<void>();

  /**
   * Triggered when the help button is clicked
   */
  @Output() help = new EventEmitter<void>();

  autoFocusOption = AutoFocusOption;

  mustShowCustomLeftButton(): boolean {
    return this.leftButtonLabel !== undefined || this.leftButtonIcon !== undefined;
  }
  mustShowBackButton(): boolean {
    if (this.mustShowCustomLeftButton()) {
      return false;
    }
    return this.showBack;
  }
}
