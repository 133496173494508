import { ComponentRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UiToasterComponent } from '@bpce/ui/toaster';
import { UiEnhancedLoaderComponent } from '../enhanced-loader/enhanced-loader.component';
import { UiEnhancedLoaderState } from '../enhanced-loader/enhanced-loader.model';

export interface UiToasterLoaderConfig {
  id?: string;
  errorMessage?: string;
  successMessage?: string;
  loadingMessage?: string;
  showSuccessButton?: boolean;
  showErrorButton?: boolean;
  autoCloseOnSuccess?: boolean;
  successButtonText?: string;
  errorButtonText?: string;
  viewContainerRef?: ViewContainerRef;
}

export class UiToasterLoaderRef {
  toasterRef: ComponentRef<UiToasterComponent>;
  loaderRef: ComponentRef<UiEnhancedLoaderComponent>;
  autoCloseOnSuccess = true;
  showSuccessButton = false;
  showErrorButton = true;
  successButtonText: string;
  errorButtonText: string;

  private readonly AUTO_CLOSE_DURATION = 1000;

  constructor([toasterRef, cmpRef]: [ComponentRef<UiToasterComponent>, ComponentRef<UiEnhancedLoaderComponent>]) {
    this.toasterRef = toasterRef;
    this.loaderRef = cmpRef;
  }

  backdropClick(): Observable<void> {
    return this.toasterRef.instance.backdropClick;
  }

  successButtonClick(): Observable<void> {
    return this.toasterRef.instance.buttonClick.pipe(
      filter(_ => this.loaderRef.instance.state === UiEnhancedLoaderState.SUCCESS)
    );
  }

  errorButtonClick(): Observable<void> {
    return this.toasterRef.instance.buttonClick.pipe(
      filter(_ => this.loaderRef.instance.state === UiEnhancedLoaderState.ERROR)
    );
  }

  afterClose(): Observable<void> {
    return this.toasterRef.instance.afterClose;
  }

  close(): void {
    return this.toasterRef.instance.close();
  }

  success(): void {
    this.loaderRef.instance.success();
    this.toasterRef.instance.showButton = this.showSuccessButton;
    this.toasterRef.instance.buttonText = this.successButtonText;

    if (this.autoCloseOnSuccess) {
      setTimeout(() => this.toasterRef.instance.close(), this.AUTO_CLOSE_DURATION);
    }
  }

  error(): void {
    this.loaderRef.instance.error();
    this.toasterRef.instance.showButton = this.showErrorButton;
    this.toasterRef.instance.buttonText = this.errorButtonText;
  }

  // Check if still working
  updateToasterLoader(newValues: { [name: string]: unknown }): void {
    Object.assign(this.loaderRef.instance, newValues);
    this.loaderRef.instance.markForCheck();
  }
}
