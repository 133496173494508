import {Injectable} from '@angular/core';
import {PartnerOffer} from '../../../../../../models/Referentiel';
import {PartnerOfferValidator} from '../helper/partner-offer.validator';
import {CcoopApiService} from '../../api/services/ccoop-api.service';
import {PartnerStoreService} from '../../data/services/partner-store.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  constructor(
    private apiService: CcoopApiService,
    private partnerStoreService: PartnerStoreService,
  ) {}

  public fetchPartnerOffersByCode$(code: string): Observable<PartnerOffer[]> {
    return this.apiService.getPartnerOffers$(code);
  }

  public setValidPartnerOffers(partnerOffers: PartnerOffer[]) {
    const validPartnerOffers: PartnerOffer[] = [];
    if (partnerOffers?.length > 0) {
      partnerOffers.forEach((partnerOffer) => {
        if (PartnerOfferValidator.validate(partnerOffer)) {
          validPartnerOffers.push(partnerOffer);
        }
      });
      this.partnerStoreService.setPartnerOffers(validPartnerOffers);
    }
  }

  public clearParnertOffers() {
    this.partnerStoreService.setPartnerOffers(undefined);
  }
}
