import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Step } from './stepper.model';

@Component({
  selector: 'ui-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiStepperComponent {
  // Static variable used to generate IDs differentbetween
  private static NB_INSTANCE = 0;

  @Input() pathLabel: string;
  @Input() currentStepIndex = 0;
  @Input() currentSubStepIndex = 0;

  @Output() selectedStep = new EventEmitter<Step>();

  @Output() currentStepIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() currentSubStepIndexChange: EventEmitter<number> = new EventEmitter<number>();

  // Arrays used only to iterate with *ngFor and generate the substeps
  subStepsLength: void[][];

  private _steps: Step[];
  @Input()
  get steps(): Step[] {
    return this._steps;
  }
  set steps(steps: Step[]) {
    this.subStepsLength = steps.map<void[]>(step => new Array(step.subStepCount === 0 ? 1 : step.subStepCount));
    this._steps = steps;
  }

  nbInstance: number;

  get currentStep(): Step {
    return this.steps[this.currentStepIndex];
  }

  constructor() {
    this.nbInstance = ++UiStepperComponent.NB_INSTANCE;
  }

  canJump(stepIndex: number): boolean {
    const step = this._steps[stepIndex];
    if (!step.selectable) {
      return false;
    }
    // We can select the current step if we are on one of its sub steps (except the first one).
    if (stepIndex === this.currentStepIndex) {
      return step.subStepCount > 1 && this.currentSubStepIndex > 0;
    }
    return true;
  }

  computeSubStepType(stepIndex: number, subStepIndex: number): string {
    if (stepIndex < this.currentStepIndex) {
      return 'visited';
    }
    if (
      stepIndex === this.currentStepIndex &&
      this.currentSubStepIndex < this._steps[stepIndex].subStepCount &&
      subStepIndex < this.currentSubStepIndex
    ) {
      return 'visited';
    }
    return 'future';
  }

  selectStep(stepIndex: number): void {
    this.currentStepIndex = stepIndex;
    this.currentStepIndexChange.emit(stepIndex);
    this.currentSubStepIndex = 0;
    this.currentSubStepIndexChange.emit(0);
    this.selectedStep.emit(this.steps[stepIndex]);
  }
}
