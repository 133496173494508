<section class="bpce-fab">
  <button id="fabBtn" class="bpce-fab-button" [class.bpce-fab-button-disappear]="!isFabDisplayed">
    <div class="bpce-fab-button-grid">
      <span class="bpce-fab-button-label"> {{ label }} </span>
      <span class="bpce-fab-button-icon-wrapper">
        <ui-icon
          class="bpce-fab-icon bpce-fab-icon-help"
          [svgIconSize]="pictoSize"
          [svgIcon]="picto"
          aria-hidden="true"
        ></ui-icon>
      </span>
    </div>
  </button>
</section>
