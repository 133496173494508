import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

// prettier-ignore
export class ExtendableError extends Error {
  constructor(message: any) { // NOSONAR
    super(message);
    this.name = this.constructor.name;
    if (typeof (Error as any).captureStackTrace === 'function') { // NOSONAR
      (Error as any).captureStackTrace(this, this.constructor); // NOSONAR
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

// prettier-ignore
export class ServiceUnavailableError extends ExtendableError {
  readonly httpErrorResponse?: HttpErrorResponse;

  constructor(arg: HttpErrorResponse | ServiceUnavailableError | any = 'Service responded with empty string...') { // NOSONAR
    super(
      arg instanceof HttpErrorResponse
        ? 'Service is unavailable.'
        : arg instanceof ServiceUnavailableError
        ? arg.message
        : arg
    );
    if (arg instanceof HttpErrorResponse) {
      this.httpErrorResponse = arg;
    } else if (arg instanceof ServiceUnavailableError) {
      this.httpErrorResponse = arg.httpErrorResponse;
    }
  }
}

export interface DqeAsyncValidationService<TValue> {
  errorKey: string;

  validate(c: AbstractControlWarn, value: TValue): Observable<ValidationErrors | null>;
}

export interface AbstractControlWarn extends AbstractControl {
  warnings?: any;

  setValue(value: any, options?: Record<string, unknown>): void;

  patchValue(value: any, options?: Record<string, unknown>): void;

  reset(value?: any, options?: Record<string, unknown>): void;
}

export interface SuggestionService<TValue, TReturn> {
  suggest(value: TValue, option?: boolean): Observable<TReturn[] | null>;

  validValue(value: TValue): boolean;
}

export interface MessageErrors {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: string | Function;
}

export interface FormMessageErrors {
  [key: string]: MessageErrors;
}

/**
 * The available options returned by external validation.
 */
export enum ValidationCode {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4
}

export interface ValidationResponse {
  code: string;
  status: ValidationCode;
  message: string;
  suggestion?: string;
}
