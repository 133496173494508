<span
  [title]="title ? title : label + (iframeModale ? ' - Fenêtre de dialogue' : ' - Lien')"
  (keydown.enter)="open()"
  (keydown.space)="open()"
  [attr.role]="isLink ? 'link' : 'button'"
  tabindex="0" (click)="open()">
  {{label}}
  <ui-icon-v2 [name]="'arrow-right'" *ngIf="showArrow"></ui-icon-v2>
</span>

