import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UiBreakpointsModule } from '@bpce/ui/breakpoints';
import { UiThemeModule } from '@bpce/ui/theming';
import { UiModalActions, UiModalContent, UiModalContentWrapper, UiModalTitle } from './modal-content-directives';
import { ModalComponent } from './modal.component';
import { BPCE_MODAL_CLOSE_EVENT, BPCE_MODAL_DATA, BPCE_MODAL_OVERLAY_CLICK } from './modal.model';
import { ModalBuilderService } from './services/modal.builder.service';

export const bpceModalDataSubject = new BehaviorSubject<any>(null); // NOSONAR
export const bpceModalOverlayClickSubject: Subject<string> = new Subject();
export const bpceModalCloseEventSubject: Subject<string> = new Subject();

@NgModule({
  imports: [CommonModule, UiBreakpointsModule, UiThemeModule, A11yModule],
  declarations: [ModalComponent, UiModalTitle, UiModalContent, UiModalActions, UiModalContentWrapper],
  providers: [
    ModalBuilderService,
    { provide: BPCE_MODAL_DATA, useValue: bpceModalDataSubject },
    { provide: BPCE_MODAL_OVERLAY_CLICK, useValue: bpceModalOverlayClickSubject },
    { provide: BPCE_MODAL_CLOSE_EVENT, useValue: bpceModalCloseEventSubject }
  ],
  entryComponents: [ModalComponent],
  exports: [ModalComponent, UiModalTitle, UiModalContent, UiModalActions, UiModalContentWrapper]
})
export class UiModalModule {}
