import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

export type onClickCallBack = (event: Event) => void;

const THROTTLE_TIME = 800;
@Directive({
  selector: '[bpceThrottleClick]',
})
export class BpceThrottleClickDirective implements OnInit, OnDestroy {
  @Input() throttleTime = THROTTLE_TIME;
  @Output() bpceThrottleClick: EventEmitter<Event> = new EventEmitter();
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  onElemClick(event: Event): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  getClickObservable(element: HTMLElement, onClick: onClickCallBack): Observable<Event> {
    const cb: onClickCallBack = onClick; // fix ng-packager error
    return fromEvent(element, 'click').pipe(
      throttleTime(this.throttleTime),
      tap((event: Event) => cb(event))
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.getClickObservable(this.elementRef.nativeElement, (event: Event) => {
        this.onElemClick(event);
        this.bpceThrottleClick.emit(event);
      }).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
