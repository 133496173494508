<footer
  class="bpce-footer"
  [class.bpce-footer-sticky]="sticky"
  [attr.role]="role || null"
  [ngStyle]="{ 'margin-top': marginTop + 'px' }"
>
  <ul class="bpce-footer-legals">
    <li class="bpce-footer-legals-item" *ngFor="let link of linkList">
      <a class="bpce-footer-legals-link" [id]="link.id" (click)="onLinkClick($event, link)">
        {{ link.label }}
      </a>
    </li>
  </ul>
</footer>
