import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {HelpReferentiel, InfoLegaleModaleReferentiel, QuitReferentiel} from "../../../../../../../models/Referentiel";
import {Observable} from "rxjs";
import {ReferentielService} from "../../../data/services/referentiel.service";
import {NavigationService} from "../../../routing/services/navigation.service";
import { ProspectService } from 'src/app/core-modules/data/services/prospect.service';
import {map, mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-info-legales-modale',
  templateUrl: './info-legales-modale.component.html',
  styleUrls: ['./info-legales-modale.component.scss']
})
export class InfoLegalesModaleComponent implements AfterViewInit {
  public readonly closed = new EventEmitter<void>()
  public referentiel$: Observable<InfoLegaleModaleReferentiel>;

  public expandedIndex = 0

  constructor(
    public navigationService: NavigationService,
    private referentielService: ReferentielService,
  ) {
    this.referentiel$ = referentielService.infoLegalesModaleReferentiel$
  }

  public clickElement(index: number) {
    if(this.expandedIndex === index) this.expandedIndex = undefined
    else this.expandedIndex = index
  }

  public ngAfterViewInit() {

  }
}
