import { ComponentRef, EventEmitter, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalComponent } from './modal.component';

export type ModalMode = 'fullscreen' | 'contextual' | 'dialog';

export type ModalState = 'open' | 'opened' | 'mobile-opened' | 'closing' | 'close' | 'closed';

// prettier-ignore
export interface ModalConfig<T = any> { // NOSONAR
  mode: ModalMode;
  data?: T;
  id?: string;
  hasStickyFooter?: boolean;
}

export interface ModalContent {
  closed: EventEmitter<any>; // NOSONAR
}

// prettier-ignore
export class ModalRef<T extends ModalContent, H = any> { // NOSONAR
  containerRef: ComponentRef<ModalComponent>;
  componentRef: ComponentRef<T> | undefined;

  constructor(containerRef: ComponentRef<ModalComponent>, cmpRef?: ComponentRef<T>) {
    this.containerRef = containerRef;
    this.componentRef = cmpRef;
  }

  close(): void {
    return this.containerRef.instance.close();
  }

  afterClose(): Observable<H> {
    return this.containerRef.instance.afterClose.asObservable() as Observable<H>;
  }

  afterOpen(): Observable<ModalState> {
    return this.containerRef.instance.status.asObservable();
  }

  updateView(): void {
    return this.containerRef.instance.updateView();
  }

  updateFooter(value: boolean): void {
    return this.containerRef.instance.updateFooter(value);
  }
}

export const BPCE_MODAL_DATA: InjectionToken<any> = new InjectionToken('bpceModalData'); // NOSONAR
export const BPCE_MODAL_OVERLAY_CLICK: InjectionToken<string> = new InjectionToken('bpceModalOverlayClick');
export const BPCE_MODAL_CLOSE_EVENT: InjectionToken<string> = new InjectionToken('bpceModalCloseEvent');

export interface DialogPolyfill {
  registerDialog(element: any); // NOSONAR
}

export interface IEvent extends Event {
  target: IEventTarget;
}

interface IEventTarget extends EventTarget {
  innerHeight: number;
}

export enum MODAL_STATE {
  OPEN = 'open',
  OPENED = 'opened',
  MOBILE_OPENED = 'mobile-opened',
  CLOSING = 'closing',
  CLOSE = 'close',
  CLOSED = 'closed'
}

export interface HtmlDialogElement extends HTMLElement {
  showModal: () => void;
  close: () => void;
  classList: DOMTokenList;
}
