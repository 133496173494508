import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { IDqeConf } from './dqe.model';

export const URL_DQE = new InjectionToken<IDqeConf>('URL_DQE_CONF');

@Injectable({
  providedIn: 'root'
})
export class UrlDqeConfService {
  config: IDqeConf;

  constructor(@Inject(URL_DQE) @Optional() config: IDqeConf) {
    this.config = config;
  }
}
