import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule } from '@bpce/ui/button';
import { UiButtonV2Module } from '@bpce/ui/button-v2';
import { UiIconModule } from '@bpce/ui/icon';
import { UiIconV2Module } from '@bpce/ui/icon-v2';

@NgModule({
  imports: [CommonModule, UiIconModule, UiIconV2Module, UiButtonModule, UiButtonV2Module],
  exports: [UiIconModule, UiIconV2Module, UiButtonModule, UiButtonV2Module]
})
export class UiThemeModule {}
