import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from "rxjs";
import {CcoopApiService} from "../../api/services/ccoop-api.service";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {NavigationService} from "./navigation.service";
import * as Bowser from "bowser";
import {
  EligibilityIntersticielComponent
} from "../../../pages-modules/1-presentation-etapes/components/eligibility-intersticiel/eligibility-intersticiel.component";
import {ModalBuilderService} from "@bpce/ui/modal";
import {
  BrowserSupportModaleComponent
} from "../../shared/components/browser-support-modale/browser-support-modale.component";

@Injectable()
export class BrowserResolver implements Resolve<any> {
  public isWarn = false
  constructor(
    private store: Store,
    private modalBuilder: ModalBuilderService,
    private navigationService: NavigationService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const browser = Bowser.getParser(window.navigator.userAgent);

    // declare browsers per OS
    // windows: {
    //   "internet explorer": ">10",
    // },
    // macos: {
    //   safari: ">10.1"
    // },
    //
    // // per platform (mobile, desktop or tablet)
    // mobile: {
    //   safari: '>=9',
    //   'android browser': '>3.10'
    // },
    // 'android browser': '>=9',

    const shouldWarn = browser.satisfies({
      desktop: {
        'chrome': '<=95',
        'firefox': '<=93',
        'edge': '<=95',
        'safari': '<14.1',
      }, tablet: {
        'chrome': '<=95',
        'firefox': '<=93',
        'safari': '<=14.1',
      }, mobile: {
        'chrome': '<=95',
        'firefox': '<=93',
        'safari': '<=14.1',
        'UC Browser': '>=11.1',
        'samsung_internet': '>=15'
      }
    });


    const shouldError = browser.satisfies({
      desktop: {
        'chrome': '<=60',
        'firefox': '<60',
        'edge': '<95',
        'safari': '<13',
      }, tablet: {
        'chrome': '<81',
        'firefox': '<55',
        'safari': '<13',
      }, mobile: {
        'chrome': '81',
        'firefox': '<55',
        'safari': '<13',
        'UC Browser': '<11.1',
        'samsung_internet': '<15'
      }
    });


    if(shouldError) {
      this.navigationService.goToBrowserError()
    } else if(shouldWarn && this.isWarn === false) {
      this.openBrowserSupportModale()
      this.isWarn = true
    }

    console.log(window.navigator.userAgent)
    console.log(browser.getOSName(), browser.getOSVersion())
    console.log(browser.getBrowserName(), browser.getBrowserVersion())
    return browser.getBrowser().name
  }


  public openBrowserSupportModale() {
    this.modalBuilder.create(BrowserSupportModaleComponent).open({
      mode: 'contextual',
      hasStickyFooter: true
    })
  }
}
