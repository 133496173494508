import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { KeyboardUtils } from '@bpce/utils';

@Directive({
  selector: '[bpceAriaNavigable]',
})
export class BpceAriaNavigableDirective implements OnInit, OnChanges {
  @Input() bpceAriaNavigable = true;

  @HostBinding('attr.tabindex') attrTabIndex: number;

  private applied = false;
  private originAttrTabIndex: number;

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit(): void {
    this.manageDirective();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bpceAriaNavigable) {
      this.manageDirective();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (!this.applied) {
      return;
    }

    if (KeyboardUtils.isAccessibilityKey(event)) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      this.elementRef.nativeElement.click();
    }
  }

  private manageDirective() {
    if (typeof this.bpceAriaNavigable !== 'boolean' || this.bpceAriaNavigable === undefined || this.bpceAriaNavigable) {
      if (!this.applied) {
        this.originAttrTabIndex = this.attrTabIndex;
      }
      if (!this.attrTabIndex) {
        this.attrTabIndex = 0;
      }
      this.applied = true;
    } else {
      if (this.applied) {
        this.attrTabIndex = this.originAttrTabIndex;
      }
      this.applied = false;
    }
  }
}
