<ng-container *ngIf="referentiel$ | async as referentiel">


  <div ui-modal-title>
    <ui-header
      close-title="Fermer la fenêtre de dialogue"
      [showBack]="false"
      [showClose]="true"
      (close)="closed.emit()"
      [showHelp]="false">
      <app-modale-title [title]="referentiel.title"></app-modale-title>
    </ui-header>
  </div>


  <ui-modal-content>
    <div class="element" *ngFor="let element of referentiel.elements; let i=index">
      <div
        tabindex="0"
        (keydown.enter)="clickElement(i)"
        (keydown.space)="clickElement(i)"
        (click)="clickElement(i)"
        class="element-title" role="button"
        [attr.aria-expanded]="expandedIndex === i" [attr.aria-controls]="i">
        <ui-icon-v2 [name]="'chevron-down'" *ngIf="expandedIndex !== i"></ui-icon-v2>
        <ui-icon-v2 [name]="'chevron-up'" *ngIf="expandedIndex === i"></ui-icon-v2>
        {{element.title}}
      </div>
      <app-inner-html [id]="i" *ngIf="expandedIndex === i" [content]="element.html"></app-inner-html>
    </div>
  </ui-modal-content>

  <div ui-modal-actions>
  </div>

</ng-container>
