import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';

@Injectable()
export abstract class UnsubscriberComponent implements OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject();

  protected autoUnsubscriber = (): MonoTypeOperatorFunction<any> => takeUntil(this.destroyed$); // NOSONAR

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
