import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavigationService} from './services/navigation.service';
import {routes} from './models/routes-definitions';
import {PreloadStrategyService} from './services/preload.strategy';
import {ReferentielResolverService} from './services/referentiel.resolver';
import {UiModalModule} from '@bpce/ui/modal';
import {BearerTokenGuardService} from './services/auth.guard';
import {ProspectResolver} from './services/prospect.resolver';
import {BrowserResolver} from './services/browser.resolver';

@NgModule({
  imports: [
    UiModalModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadStrategyService,
      useHash: true,
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  declarations: [],
  providers: [
    BrowserResolver,
    NavigationService,
    BearerTokenGuardService,
    PreloadStrategyService,
    ReferentielResolverService,
    ProspectResolver,
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
