import { ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { UnsubscriberComponent } from '@bpce/utils';
import { UiToasterComponent } from './toaster.component';
import { UiToasterConfig, UiToasterRef, UI_TOASTER_DATA } from './toaster.model';

@Injectable({
  providedIn: 'root'
})
export class UiToasterBuilderService extends UnsubscriberComponent {
  private component: ComponentType<any> | null = null; // NOSONAR

  constructor(
    protected applicationRef: ApplicationRef,
    protected injector: Injector,
    protected resolver: ComponentFactoryResolver
  ) {
    super();
  }

  get container(): ViewContainerRef {
    const appInstance = this.applicationRef.components[0].instance;
    if (!appInstance.viewContainerRef) {
      const appName = this.applicationRef.componentTypes[0].name;
      throw new Error(`Missing 'viewContainerRef' declaration in ${appName} constructor`);
    }

    return appInstance.viewContainerRef;
  }

  public create<T>(component: ComponentType<T>): UiToasterBuilderService {
    this.component = component;
    return this;
  }

  public open<T, K>(toasterConfig: UiToasterConfig<K>): UiToasterRef<T> {
    if (this.component === null) {
      throw new Error('You must call create() before calling open()');
    }

    const toasterRef = this.createToaster(
      toasterConfig.data,
      toasterConfig.viewContainerRef ? toasterConfig.viewContainerRef : this.container,
      this.resolver,
      this.injector,
      this.component
    );
    this.initToaster(toasterRef, toasterConfig);

    if (toasterRef && toasterRef.containerRef && toasterRef.containerRef.changeDetectorRef) {
      // this solves the issue of toasters that don't show their content - DESY-1138
      setTimeout(() => {
        toasterRef.containerRef.changeDetectorRef.markForCheck();
      }, 0);
    }

    this.component = null;
    return toasterRef;
  }

  private createToaster<T, K>(
    data: K,
    container: ViewContainerRef,
    resolver: ComponentFactoryResolver,
    injector: Injector,
    componentType: ComponentType<T>
  ): UiToasterRef<T> {
    const containerFactory = resolver.resolveComponentFactory<UiToasterComponent>(UiToasterComponent);
    const containerRef = container.createComponent<UiToasterComponent>(containerFactory, 0, injector);
    const toasterRef = new UiToasterRef<T>(containerRef);
    const componentInjector = Injector.create({
      providers: [
        { provide: UI_TOASTER_DATA, useValue: data },
        { provide: UiToasterRef, useValue: toasterRef }
      ],
      parent: injector
    });
    toasterRef.componentRef = containerRef.instance.attachContent(componentType, componentInjector);
    return toasterRef;
  }

  private initToaster<T, K>(
    toasterRef: UiToasterRef<T>,
    { id, showButton = false, buttonText = 'Retour' }: UiToasterConfig<K>
  ): void {
    // Bind toasterComponent inputs
    toasterRef.containerRef.instance.id = id || '';
    toasterRef.containerRef.instance.showButton = showButton;
    toasterRef.containerRef.instance.buttonText = buttonText;

    // When after close event is fired, destroy modal component
    toasterRef.containerRef.instance.afterClose.pipe(this.autoUnsubscriber()).subscribe(() => {
      if (toasterRef.componentRef) {
        toasterRef.componentRef.destroy();
      }
      toasterRef.containerRef.destroy();
    });
  }
}
