import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, first, mergeMap, tap } from 'rxjs/operators';

@Directive({
  selector: '[bpceAutoFocus]',
})
export class BpceAutoFocusDirective implements AfterViewInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();
  private readonly focus$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private readonly elementRef: ElementRef, private readonly ngZone: NgZone) {}

  private _activateFocus: boolean;

  @Input() get activateFocus(): boolean {
    return this._activateFocus;
  }

  set activateFocus(value: boolean) {
    this._activateFocus = !!value;
    this.focus$.next(this._activateFocus);
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.ngZone.onStable
        .asObservable()
        .pipe(
          first(),
          delay(0),
          mergeMap(() => this.focus$.asObservable()),
          tap(trigger => trigger && this.elementRef.nativeElement.focus())
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
