import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiLinkItem } from './footer.model';

const MARGIN_TOP = 40;

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class UiFooterComponent {
  @Input() role = '';
  @Input() linkList: UiLinkItem[] = [];
  @Input() sticky = false;
  @Input() marginTop = MARGIN_TOP;
  @Output() linkClick = new EventEmitter<UiLinkItem>();

  onLinkClick(event: MouseEvent, item: UiLinkItem): void {
    this.linkClick.emit(item);
    event.preventDefault();
  }
}
