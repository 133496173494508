import { Injectable } from '@angular/core';
import equal from 'fast-deep-equal';
import { KEY, KeyboardUtils } from '../keyboard/keyboard.util';

@Injectable()
export class UiInputListService {
  writeValue(
    value: Record<string, unknown> | unknown,
    thisArg: any // NOSONAR
  ): void {
    if (value) {
      // search only by reference for performance
      let element = thisArg.suggestions.find(suggestion => suggestion === value);
      if (!element) {
        // if not found, deep comparison
        element = thisArg.suggestions.find(suggestion => equal(suggestion, value));
      }
      if (element) {
        thisArg.selectedSuggestion = element;
      }
      thisArg.query =
        element && !thisArg.isStringList
          ? element[thisArg.searchedProperties[thisArg.searchedPropertyIndexToDisplay]]
          : (value as string);
    } else {
      thisArg.query = '';
      thisArg.selectedSuggestion = '';
    }
  }

  // prettier-ignore
  onKeyUp(event: KeyboardEvent, thisArg: any): void { // NOSONAR
    if (KeyboardUtils.wasKeyPressed([KEY.ESCAPE], event)) {
      thisArg.handleClose(false, true);
    }
    thisArg.keyUp.emit(event);
  }

  // prettier-ignore
  onKeyDown(event: KeyboardEvent, thisArg: any, condition: boolean): void {// NOSONAR
    if (KeyboardUtils.isArrowUp(event)) {
      event.preventDefault();
      thisArg.focusSearchInput();
      thisArg.moveThroughList(-1);
    } else if (KeyboardUtils.isArrowDown(event)) {
      event.preventDefault();
      thisArg.focusSearchInput();
      thisArg.moveThroughList(+1);
    } else if (KeyboardUtils.isEnterKey(event)) {
      if (condition) {
        event.preventDefault();
        thisArg.onEnter();
      }
    } else if (KeyboardUtils.isDeleteCharacter(event)) {
      thisArg.handleOpen();
    }
    thisArg.keyDown.emit(event);
  }

  // prettier-ignore
  updateQuery(value: string, thisArg: any, isInputListV2: boolean): void {// NOSONAR
    thisArg.query = value;
    thisArg.activeDescendantId = '';
    thisArg.inputChanged.emit(value);

    thisArg.writeValue(value);

    if (!thisArg.query) {
      thisArg.notFound = false;
      thisArg.inputCleared.emit();
    }
    thisArg.isNewQuery = this.hasQueryChanged(thisArg);

    const condition = isInputListV2
      ? !thisArg.isMobileView && thisArg.minQueryLength > 0 && thisArg.isQueryValid
      : !thisArg.isMobileView && (!thisArg.query || thisArg.query.length < thisArg.minQueryLength);

    if (condition) {
      thisArg.handleClose();
    } else {
      if (!thisArg.isOpened) {
        thisArg.handleOpen();
      } else {
        thisArg.filterList();
      }

      if (thisArg.notFound && (!thisArg.suggestions || thisArg.suggestions.length === 0)) {
        thisArg.isOpened = true;
      }
    }
  }

  // prettier-ignore
  private hasQueryChanged(thisArg: any): boolean {// NOSONAR
    return !thisArg.isStringList && thisArg.selectedSuggestion
      ? thisArg.query !== thisArg.selectedSuggestion[thisArg.searchedProperties[thisArg.searchedPropertyIndexToDisplay]]
      : thisArg.query !== thisArg.selectedSuggestion;
  }

  // Select the whole content of the input field if it comes from a selection
  // (for example, with a list of countries, it will select "France" but not "fra" since it corresponds to several elements)
  // prettier-ignore
  selectAllIfNeeded(thisArg: any): boolean | undefined { // NOSONAR
    if (!thisArg.shouldSelectAllInputContent()) {
      return;
    }

    const stringCorresponds = thisArg.isStringList && thisArg.filteredSuggestions.some(sug => sug === thisArg.query);
    const objectCorresponds =
      !thisArg.isStringList &&
      thisArg.filteredSuggestions.some(sug => sug[thisArg.searchedProperties[thisArg.searchedPropertyIndexToDisplay]] === thisArg.query);

    return stringCorresponds || objectCorresponds;
  }

  getBoundingClientRect(element: HTMLElement): DOMRect {
    try {
      return element.getBoundingClientRect();
    } catch (e) {
      const top = element.offsetTop;
      return {
        top: element.offsetTop,
        bottom: top + element.offsetHeight,
        height: -1,
        width: -1,
        x: -1,
        y: -1,
        left: -1,
        right: -1,
        toJSON: () => null,
      };
    }
  }

  moveThroughList(
    increment: number,
    totalNumItem: number,
    thisArg: any // NOSONAR
  ): void {
    if (totalNumItem === 0) {
      thisArg.activeSuggestionIndex = -1;
      thisArg.activeDescendantId = '';
      return;
    }

    const initalPosition = 0;
    const lastPosition = totalNumItem - 1;
    const position = +thisArg.activeSuggestionIndex + increment;

    if (position > lastPosition) {
      thisArg.activeSuggestionIndex = initalPosition;
    } else if (position < initalPosition) {
      thisArg.activeSuggestionIndex = lastPosition;
    } else {
      thisArg.activeSuggestionIndex = position;
    }

    thisArg.activeDescendantId = thisArg.filteredListElement.nativeElement.children[thisArg.activeSuggestionIndex].id;
    thisArg.scrollToFocusedItem(thisArg.activeSuggestionIndex);
  }
}
