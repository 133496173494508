import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import {ReferentielService} from '../../../data/services/referentiel.service';
import {SegmentComponent} from './segment/segment.component';

@Component({
  selector: 'app-segmented-control',
  templateUrl: './segmented-control.component.html',
  styleUrls: ['./segmented-control.component.scss'],
})
export class SegmentedControlComponent implements OnInit, AfterContentInit {
  @Input('default') default;
  @Input('aria-labelledby') ariaLabelledBy;
  @Output() clicked = new EventEmitter<number>();

  @ContentChildren(SegmentComponent, {descendants: true}) segments: QueryList<SegmentComponent>;

  constructor(public referentielService: ReferentielService) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.listenSegmentOnClick();
    this.listenSegmentOnKeydown();
  }

  private listenSegmentOnClick() {
    this.segments.forEach((segment) => {
      segment.clicked.subscribe((index) => {
        this.clicked.emit(index);
        this.changeSegment(index);
      });
    });
  }

  private listenSegmentOnKeydown() {
    this.segments.forEach((segment) => {
      segment.navigate.subscribe(({index, key}) => {
        switch (key) {
          case 'ArrowLeft':
            this.goToPreviousSegment(index);
            break;

          case 'ArrowRight':
            this.goToNextSegment(index);
            break;

          case 'Home':
            this.changeSegment(0);
            this.clicked.emit(0);
            break;

          case 'End':
            this.changeSegment(this.segments.length - 1);
            this.clicked.emit(this.segments.length - 1);
            break;

          default:
            break;
        }
      });
    });
  }

  private goToNextSegment(index: number) {
    let next = index + 1;
    if (index === this.segments.length - 1) {
      next = 0;
    }
    this.changeSegment(next);
    this.clicked.emit(next);
  }

  private goToPreviousSegment(index: number) {
    let next = index - 1;
    if (index === 0) {
      next = this.segments.length - 1;
    }
    this.changeSegment(next);
    this.clicked.emit(next);
  }

  private changeSegment(index: number) {
    this.segments.forEach((s) => {
      if (index === s.index) {
        s.setFocus(true);
      } else {
        s.setFocus(false);
      }
    });
  }
}
