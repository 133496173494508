import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@bpce/ui/icon';
import { UiPictogramModule } from '@bpce/ui/pictogram';
import { UiFabActionComponent } from './fab-action/fab-action.component';
import { UiFabCustomActionComponent } from './fab-custom-action/fab-custom-action.component';
import { UiFabCustomComponent } from './fab-custom/fab-custom.component';
import { UiFabListComponent } from './fab-list/fab-list.component';
import { UiFabService } from './fab.service';

@NgModule({
  imports: [CommonModule, UiIconModule, UiPictogramModule],
  declarations: [UiFabActionComponent, UiFabCustomActionComponent, UiFabCustomComponent, UiFabListComponent],
  exports: [UiFabActionComponent, UiFabCustomActionComponent, UiFabCustomComponent, UiFabListComponent],
  providers: [UiFabService]
})
export class UiFabModule {}
