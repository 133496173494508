export interface ITown {
  zipCode: string;
  city: string;
  knownAs: string;
  idCity: string;
  latitude: string;
  longitude: string;
}

export class Town implements ITown {
  zipCode: string;
  city: string;
  knownAs: string;
  idCity: string;
  latitude: string;
  longitude: string;

  get displayLabel(): string {
    if (!this.zipCode || !this.city) {
      return '';
    }

    return `${this.zipCode} (${this.city})`;
  }

  get displayLabelWithKnownAs(): string {
    if (!this.zipCode || !this.city) {
      return '';
    }

    return `${this.zipCode} (${this.city}${this.knownAs ? `, ${this.knownAs}` : ''})`;
  }

  constructor(data: Partial<Town>) {
    Object.assign(this, data);
  }

  public static isValidTown(val: unknown): boolean {
    if (!val || typeof val !== 'object') {
      return false;
    }

    const hasZipCode = (val as Town).zipCode !== undefined;
    const hasCity = (val as Town).city !== undefined;
    const hasIdCity = (val as Town).idCity !== undefined;

    return hasZipCode && hasCity && hasIdCity;
  }

  public toString(): string {
    return this.displayLabel;
  }
}
