import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {PartnerOffer} from '../../../../../../models/Referentiel';
import {DataActions} from '../store/data.actions';
import {partnerOffersSelector, partnerSelector} from '../store/data.selectors';
import {Observable} from 'rxjs';

@Injectable()
export class PartnerStoreService {
  constructor(private store: Store) {}

  public get partner$() {
    return this.store.select(partnerSelector);
  }

  public setPartnerOffers(partnerOffers: PartnerOffer[]) {
    this.store.dispatch(DataActions.setPartnerOffers({partnerOffers}));
  }

  public getPartnerOffers$(): Observable<PartnerOffer[]> {
    return this.store.select(partnerOffersSelector);
  }
}
