import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit, AfterViewInit {
  @Input('index') index;
  @Input('isDefault') isDefault = false;

  @Output() clicked = new EventEmitter<number>();
  @Output() navigate = new EventEmitter<{index: number; key: string}>();

  @ViewChild('button') button: ElementRef;

  ariaSelected;
  tabIndex;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.isDefault) {
      this.setFocus(true);
    }
  }

  setFocus(isFocus: boolean) {
    this.ariaSelected = isFocus;
    if (isFocus) {
      this.tabIndex = 0;
      this.button.nativeElement.focus();
    } else {
      this.tabIndex = -1;
    }
  }

  @HostListener('click')
  private onClick() {
    this.clicked.emit(this.index);
  }

  @HostListener('keydown', ['$event'])
  private onKeydown($event) {
    this.navigate.emit({index: this.index, key: $event.key});
  }
}
