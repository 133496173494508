import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {CcoopApiService} from "../../api/services/ccoop-api.service";
import {environment} from "../../../../environments/environment";
import {EnvMode} from "../../../../environments/EnvMode";


@Injectable()
export class BearerTokenGuardService implements CanActivate {
  constructor(public ccoopApiService: CcoopApiService, public router: Router) {}

  canActivate(): boolean {
    if(this.ccoopApiService.bearerToken === undefined && environment.mode !== EnvMode.WORKING ) {
      this.router.navigateByUrl('')
    }
    return true;
  }
}
