import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UiIconRegistry } from './icon-registry.service';
import { UiIconComponent } from './icon.component';

/**
 * @deprecated Use UiIconV2, UiIcon will be removed in @bpce/ui@^14.0.0
 */
@NgModule({
  imports: [CommonModule],
  declarations: [UiIconComponent],
  exports: [UiIconComponent]
})
export class UiIconModule {
  constructor(iconRegistry: UiIconRegistry, sanitizer: DomSanitizer) {
    // Icon
    iconRegistry.setDefaultFontSetClass('ui-icon');

    // Bi-colors icons
    iconRegistry.addSvgIconSetInNamespace(
      'bpce',
      sanitizer.bypassSecurityTrustResourceUrl('svg/icons.svg') // path to SVG
    );

    // Monochrome
    iconRegistry.addSvgIconSetInNamespace(
      'monochrome',
      sanitizer.bypassSecurityTrustResourceUrl('svg/monochrome.svg') // path to SVG
    );

    // Transactions Category
    iconRegistry.addSvgIconSetInNamespace(
      'trans-cat',
      sanitizer.bypassSecurityTrustResourceUrl('svg/transaction-categories.svg') // path to SVG
    );

    // Pro categories icons
    iconRegistry.addSvgIconSetInNamespace(
      'pro-cat',
      sanitizer.bypassSecurityTrustResourceUrl('svg/pro-categories.svg')
    );

    iconRegistry
      .addSvgIconInNamespace('result', 'success', sanitizer.bypassSecurityTrustResourceUrl('loading-success.svg'))
      .addSvgIconInNamespace('result', 'error', sanitizer.bypassSecurityTrustResourceUrl('loading-error.svg'));
  }
}
