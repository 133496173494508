import {Component, EventEmitter, Input, Output} from '@angular/core';

import * as replaceSpecialCharacters from "replace-special-characters"

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {

  @Input() title: string;
  @Input() big: boolean = false;
  @Input() modale: boolean = false;
  @Output() clicked = new EventEmitter()

  public get id() {
    if(!this.title) return '';
    let output = this.title
    output = output.replace(new RegExp(',', 'g'), '');
    output = output.replace('?', '');
    output = output.replace(new RegExp('!', 'g'), '');
    output = output.replace(new RegExp('\'', 'g'), ' ');
    output = output.replace(new RegExp('’', 'g'), ' ');
    output = output.trim();
    output = output.replace(new RegExp(' ', 'g'), '-');
    return replaceSpecialCharacters(output).toLowerCase();
  }
}
