import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UiLinkItem} from "@bpce/ui/footer";

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  @Input() link: string
  @Output() linkClicked = new EventEmitter()

  public linkList: UiLinkItem[]

  public ngOnInit() {
    this.linkList = [{
      label: this.link,
      id: this.link
    }]
  }
}
