import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ModalContent, ModalBuilderService, ModalRef } from '@bpce/ui/modal';

@Injectable({ providedIn: 'root' })
export class InterstitialService {
  constructor(private readonly modalBuilder: ModalBuilderService) {}

  // prettier-ignore
  open<T extends ModalContent, K = any, H = any>(component: ComponentType<T>, data?: K, id?: string): ModalRef<T, H> { // NOSONAR
    return this.modalBuilder.create(component).open({
      mode: 'fullscreen',
      id,
      data,
    });
  }
}
