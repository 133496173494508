import {PartnerOffer} from '../../../../../../models/Referentiel';
import {stringToDate} from './date.helper';

export enum PartnerOfferError {
  INVALID = 1,
  EXPIRED = 2,
}

export class PartnerOfferValidator {
  static validate(partnerOffer: PartnerOffer): boolean {
    const error = this.getError(partnerOffer);
    return !error;
  }

  static getError(partnerOffer: PartnerOffer): PartnerOfferError {
    if (!partnerOffer || !partnerOffer.isActivated) return PartnerOfferError.INVALID;
    if (partnerOffer.code.length != 8) {
      return PartnerOfferError.INVALID;
    }
    if (new Date() < stringToDate(partnerOffer.startDate)) {
      return PartnerOfferError.INVALID;
    }
    if (new Date() > stringToDate(partnerOffer.endDate)) {
      return PartnerOfferError.EXPIRED;
    }
    return null;
  }
}
