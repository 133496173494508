import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Prospect} from '../../../../../../models/Prospect';
import {PartnerOffer} from '../../../../../../models/Referentiel';

@Injectable()
export class CcoopApiService {
  public bearerToken: string;
  public password: string;

  constructor(private httpClient: HttpClient) {}

  public setToken(token: string) {
    this.bearerToken = token;
  }

  public setPassword(password: string) {
    this.password = password;
  }

  public fetchReferentiel$<T>(key: string): Observable<T> {
    return this.httpClient.get<T>(environment.apiUrl + '/referentiel/' + key);
  }

  public authProspect$(): Observable<Prospect> {
    return this.httpClient.get<Prospect>(environment.apiUrl + '/auth');
  }

  public getProspect$(email: string): Observable<Prospect> {
    return this.httpClient.get<Prospect>(environment.apiUrl + '/prospects/' + email);
  }

  public putProspect(prospect: Prospect): Observable<string> {
    return this.httpClient
      .put<{bearerToken: string}>(environment.apiUrl + '/prospects', prospect)
      .pipe(
        map((response) => {
          return response.bearerToken;
        }),
      );
  }

  public postProspect(email: string, code?: string, partner?: string): Observable<string> {
    return this.httpClient
      .post<{bearerToken: string}>(environment.apiUrl + '/prospects/' + email, {
        code: code,
        partner: partner,
      })
      .pipe(
        map((response) => {
          return response.bearerToken;
        }),
      );
  }

  public getPartnerOffers$(code: string): Observable<PartnerOffer[]> {
    if (code) {
      return this.httpClient.get<PartnerOffer[]>(environment.apiUrl + '/partner/' + code);
    }
    return EMPTY;
  }

  public downloadPartnersInExcelFile$() {
    return this.httpClient.get(environment.apiUrl + '/partners', {responseType: 'blob'});
  }
}
