import { NgModule } from '@angular/core';
import { BpceAriaButtonDirective } from './directives/bpce-aria-button.directive';
import { BpceAriaNavigableDirective } from './directives/bpce-aria-navigable.directive';
import { BpceAutoFocusDirective } from './directives/bpce-auto-focus.directive';
import { BpceAriaLinkDirective } from './directives/bpce-aria-link.directive';

@NgModule({
  declarations: [BpceAutoFocusDirective, BpceAriaButtonDirective, BpceAriaNavigableDirective, BpceAriaLinkDirective],
  exports: [BpceAutoFocusDirective, BpceAriaButtonDirective, BpceAriaNavigableDirective, BpceAriaLinkDirective],
})
export class BpceA11yModule {}
