import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'app-inner-html',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.scss']
})
export class InnerHtmlComponent {
  @Input() content: string
  @Input() escapeParagraph = false

  constructor() {
      if(this.escapeParagraph) {
        this.content = this.postTraitmentContent
      }
  }

  public get postTraitmentContent() {
    if(this.escapeParagraph) {
      let output = this.content.replace(new RegExp('<p>', 'g'), '');
      output = output.replace(new RegExp('</p><p>', 'g'), '<br><br>');
      output = output.replace(new RegExp('</p>', 'g'), '');
      return output;
    }
    return this.content
  }
}
