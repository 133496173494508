import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FabListItem } from './fab-list-item.model';

const ICON_SIZE = 24;

/**
 * @name UiFabListComponent
 * @description
 * Componsant FAB List
 */
@Component({
  selector: 'ui-fab-list',
  templateUrl: './fab-list.component.html',
  styleUrls: ['./fab-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFabListComponent {
  readonly fontIconSize = ICON_SIZE;
  // label is for accessibility
  @Input()
  label = 'Bouton FAB LIST';

  @Input()
  icon = 'bpce:question_chat';

  @Input()
  items: FabListItem[] = [];

  @Input()
  scrollContainer: Element;

  @Output()
  itemClicked = new EventEmitter<FabListItem>();

  @Output()
  opened = new EventEmitter<Event>();

  @Output()
  closed = new EventEmitter<Event>();

  /**
   * @description Function called on FAB item click. return FabListItem
   * @param event MouseEvent
   * @param item FabListItem
   */
  onItemClick(event: MouseEvent, item: FabListItem): void {
    event.stopPropagation();
    this.itemClicked.emit(item);
  }

  onOpened(event: MouseEvent): void {
    this.opened.emit(event);
  }

  onClosed(event: MouseEvent): void {
    this.closed.emit(event);
  }
}
