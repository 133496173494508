/*
 * Public API ui/ripple
 */
export { RippleGlobalOptions, UI_RIPPLE_GLOBAL_OPTIONS, UiRipple } from './ripple.directive';
export { RippleState, RippleRef } from './ripple-ref';
export {
  RippleConfig,
  RippleAnimationConfig,
  RippleTarget,
  defaultRippleAnimationConfig,
  RippleRenderer
} from './ripple-renderer';
export { UiRippleModule } from './ripple.module';
