<span class="bpce-stepper-mobile">
  <span class="bpce-stepper-mobile-title">{{ currentStep.label }}</span>
</span>

<ul class="bpce-stepper" [attr.aria-label]="pathLabel">
  <li
    *ngFor="let step of steps; let stepIdx = index"
    id="bpce-stepper-{{ nbInstance }}-{{ step.id }}"
    class="bpce-stepper-step"
    [ngClass]="{ 'bpce-stepper-step-on': stepIdx === currentStepIndex }"
    [attr.aria-current]="stepIdx === currentStepIndex ? 'step' : false"
  >
    <button *ngIf="canJump(stepIdx); else unjumpable" class="bpce-stepper-step-margin" (click)="selectStep(stepIdx)">
      <span class="bpce-stepper-step-label">{{ step.label }}</span>
    </button>

    <ng-template #unjumpable>
      <button class="bpce-stepper-step-margin" disabled>
        <span *ngIf="!canJump(stepIdx)" class="bpce-stepper-step-label">{{ step.label }}</span>
      </button>
    </ng-template>

    <span class="bpce-stepper-step-dot"></span>

    <span *ngIf="stepIdx < steps.length" class="bpce-stepper-sub-step">
      <span
        *ngFor="let subStep of subStepsLength[stepIdx]; let subStepIdx = index"
        class="bpce-stepper-sub-step-line bpce-stepper-sub-step-line-{{ computeSubStepType(stepIdx, subStepIdx) }}"
      ></span>
    </span>
  </li>
</ul>
