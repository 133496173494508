import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-fab-help-button',
  templateUrl: './fab-help-button.component.html',
  styleUrls: ['./fab-help-button.component.scss']
})
export class FabHelpButtonComponent {
  @Input() container: Element;

  constructor() {
  }
}
