import { DebugElement } from '@angular/core';
import { By } from '@angular/platform-browser';

/**
 * Helper class that should help simplify tests.
 */
export class DebugElementHelper {
  private readonly element: DebugElement;

  /**
   * Dom element from where query and others actions are performed.
   * @param element dom element
   */
  constructor(element: DebugElement) {
    this.element = element;
  }

  /**
   * Function used to query a specific dom element.
   * @param selector css selector used to target specific dom element to find
   */
  public byCss(selector: string | DebugElement): DebugElement {
    return typeof selector === 'string' ? this.element.query(By.css(selector)) : selector;
  }

  /**
   * Function used to query all node that match the provided css selector.
   * @param selector css selector used to target specific dom elements to find
   */
  public allByCss(selector: string): DebugElement[] {
    return this.element.queryAll(By.css(selector));
  }

  /**
   * Function used to query text content of a specific dom element.
   * @param selector css selector used to target specific dom element to find
   */
  public text(selector: string | DebugElement): string {
    return this.byCss(selector).nativeElement.innerHTML;
  }

  /**
   * Function used to get the number of dom elements that match the provided css selector.
   * @param selector css selector used to target specific dom elements to find
   */
  public count(selector: string): number {
    return this.allByCss(selector).length;
  }

  /**
   * Function used to query the component instance of a specific dom element.
   * @param selector css selector used to target the component to find
   */
  public component<T>(selector: string): T {
    return this.element.query(By.css(selector)).componentInstance as T;
  }

  /**
   * Function used to trigger an event on specific dom element.
   * @param selector css selector used to target specific dom element to find
   * @param event the event to trigger (ex: click, mousemove, ...etc)
   * @param data the optional data to provide to the event
   */
  // prettier-ignore
  public trigger(selector: string, event: string, data: { [k: string]: any } = {}): void { // NOSONAR
    this.byCss(selector).nativeElement.dispatchEvent(new Event(event, data));
  }
}
