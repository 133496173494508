import { InjectionToken, Provider } from '@angular/core';
import { IConfig } from './provider.config';

export const DQE_CONFIG = new InjectionToken<DqeConfig>('dqe.config');

export function provideDqeConfigService(urlDQM: InjectionToken<string>): Provider {
  return [
    {
      provide: DQE_CONFIG,
      useFactory: dqeFactory,
      deps: [urlDQM]
    }
  ];
}

export class DqeConfig implements IConfig {
  url: string;
  options: Map<string, string>;

  constructor(url: string) {
    this.url = url;
    this.options = new Map<string, string>(); // might be useful someday
  }
}

/*
 * should be managed by the back-end as otherwise the dqe server and licence is hard coded
 * the licence is not encoded!
 */
export function dqeFactory(url: string): DqeConfig {
  if (!url.endsWith('/dqm')) {
    url = `${url}/dqm`;
  }

  return new DqeConfig(url);
}

export type ServiceNameEnum = 'DQEEMAILLOOKUP' | 'MAILSUGGEST' | 'TEL' | 'SINGLEV2' | 'RNVP' | 'CP';

// WARNING IS CASE SENSITIVE
// eslint-disable-next-line no-redeclare
export const ServiceNameEnum = {
  Email: 'DQEEMAILLOOKUP' as ServiceNameEnum,
  EmailSuggest: 'MAILSUGGEST' as ServiceNameEnum,
  Phone: 'TEL' as ServiceNameEnum,
  Address: 'RNVP' as ServiceNameEnum,
  AddressSuggest: 'SINGLEV2' as ServiceNameEnum,
  ZipCode: 'CP' as ServiceNameEnum
};
