<div [ngClass]="['bpce-infobox', 'bpce-infobox-' + type]" [ngStyle]="{ 'margin-bottom': marginBottom + 'px' }">
  <div class="bpce-infobox-header">
    <ui-icon-v2 class="bpce-infobox-icon" [name]="fontIcon"></ui-icon-v2>
    <p class="bpce-infobox-title">{{ title }}</p>
  </div>
  <p class="bpce-infobox-content">
    <ng-content></ng-content>
  </p>
  <button *ngIf="showButton" uiButtonV2 class="bpce-infobox-action" category="tertiary" (click)="buttonClick.emit()">
    {{ buttonLabel }}
  </button>
</div>
