export namespace UiPictogram {
  export enum SizeType {
    SM = 'sm', // 40px
    MD = 'md', // 48px
    LG = 'lg', // 56px
    XL = 'xl' // 80px
  }

  export type Size = 'sm' | 'md' | 'lg' | 'xl';
}
