import { Inject, InjectionToken, ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { provideUrlDqmService, URL_DQM } from '../dqe/urlInjectionToken';
import { provideDqeConfigService } from '../dqe/dqe.config';
import { DqeTownSuggestService, TOWN_SUGGESTION_SERVICE } from './town-suggestion.service';

export function provideTownSuggestionService(): Provider {
  return [
    {
      provide: TOWN_SUGGESTION_SERVICE,
      useClass: DqeTownSuggestService
    },
    DqeTownSuggestService
  ];
}

@NgModule({})
export class UiTownSuggestionModule {
  static url(urlDQM: string): ModuleWithProviders<UiTownSuggestionModule> {
    return {
      ngModule: UiTownSuggestionModule,
      providers: [provideUrlDqmService(urlDQM), provideDqeConfigService(URL_DQM), provideTownSuggestionService()]
    };
  }

  static urlToken(@Inject(URL_DQM) urlDQMToken: InjectionToken<string>): ModuleWithProviders<UiTownSuggestionModule> {
    return {
      ngModule: UiTownSuggestionModule,
      providers: [provideDqeConfigService(urlDQMToken), provideTownSuggestionService()]
    };
  }
}
