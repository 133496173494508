import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiLoaderMode } from '../loader.model';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiLoaderComponent {
  @Input()
  mode: UiLoaderMode = UiLoaderMode.IN_PAGE;

  types = UiLoaderMode;
}
