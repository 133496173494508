import {Injectable} from '@angular/core';
import {prospectSelector} from '../store/data.selectors';
import {Store} from '@ngrx/store';
import {DataActions} from '../store/data.actions';
import {Association} from '../../../../../../models/Referentiel';

@Injectable()
export class ProspectService {
  constructor(private store: Store) {}

  public get prospect$() {
    return this.store.select(prospectSelector);
  }

  public save() {
    this.store.dispatch(DataActions.saveProspect());
  }

  public setEmail(email: string) {
    this.store.dispatch(DataActions.setEmail({email}));
  }

  public setCode(code: string) {
    this.store.dispatch(DataActions.setCode({code}));
  }

  public selectOffer(longTitle: string, formula: string, card: string) {
    return this.store.dispatch(
      DataActions.setOffer({offer: {longTitle, formula: {formula, card}}}),
    );
  }

  public selectTrackingChoice(trackingChoice: string) {
    return this.store.dispatch(DataActions.setSelectedTrackingChoices({trackingChoice}));
  }

  public unselectTrackingChoice() {
    return this.store.dispatch(DataActions.setSelectedTrackingChoices({trackingChoice: undefined}));
  }

  public selectAssociation(association: Association) {
    this.store.dispatch(DataActions.setSelectedAssociation({association: association.name}));
  }

  public initDonation() {
    this.store.dispatch(DataActions.initDonation());
  }

  public switchDonation() {
    this.store.dispatch(DataActions.switchDonation());
  }

  public setDonation(donationPerOperation: number, donationCeiling: number) {
    this.store.dispatch(
      DataActions.setDonation({donation: true, donationPerOperation, donationCeiling}),
    );
  }

  public kycRedirected() {
    this.store.dispatch(DataActions.kycRedirected());
  }
}
