import { Pipe, PipeTransform } from '@angular/core';
import { ENUMREDACTIONTYPE, RedactionRule } from '../model/model';
import { TokenizerHelper } from '../services/hideCaracterHelper';

const TWO = 2;
const THREE = 3;
const FIVE = 5;
@Pipe({ name: 'bpceCustomRedaction' })
export class BpceCustomRedaction implements PipeTransform {
  transform(value: string, redactionRules: RedactionRule[]): string {
    return TokenizerHelper.setCaviardageString(value, redactionRules);
  }
}

@Pipe({ name: 'bpceEmailRedaction' })
export class BpceEmailRedaction implements PipeTransform {
  transform(value: string | null): string {
    if (!value) {
      return '';
    }
    const caviardageRules = getRedactionRules(ENUMREDACTIONTYPE.EMAIL, value);
    return TokenizerHelper.setCaviardageString(value, caviardageRules);
  }
}

@Pipe({ name: 'bpcePhoneRedaction' })
export class BpcePhoneRedaction implements PipeTransform {
  transform(value: string | null): string {
    if (!value) {
      return '';
    }
    const caviardageRules = getRedactionRules(ENUMREDACTIONTYPE.TEL, value);
    return TokenizerHelper.setCaviardageString(value, caviardageRules);
  }
}

@Pipe({ name: 'bpceBankCardRedaction' })
export class BpceBankCardRedaction implements PipeTransform {
  transform(value: string | null): string {
    if (!value) {
      return '';
    }
    const caviardageRules = getRedactionRules(ENUMREDACTIONTYPE.CREDITCARD, value);
    return TokenizerHelper.setCaviardageString(value, caviardageRules);
  }
}

const regexCarteBancaire = new RegExp(/^\d{4}[-|\s]?\d{4}$/);

function getNumberofSapce(value: string): number {
  const findSpaces = value.match(/\s/g);
  return findSpaces ? findSpaces.length : 0;
}

export function getRedactionRules(type: ENUMREDACTIONTYPE, value: string): RedactionRule[] {
  let firstIndex = 1;
  let lastIndex: number;

  switch (type) {
    case ENUMREDACTIONTYPE.EMAIL: // c••••••••s@email.fr
      lastIndex = value.indexOf('@') - TWO;
      break;
    case ENUMREDACTIONTYPE.TEL: // 06 •• •• 34 89
      firstIndex = TWO;
      lastIndex = value.length - FIVE - getNumberofSapce(value.substr(value.length - FIVE, value.length));
      break;
    case ENUMREDACTIONTYPE.CREDITCARD: // •••• 1234
      if (type === ENUMREDACTIONTYPE.CREDITCARD && regexCarteBancaire.test(value)) {
        firstIndex = 0;
        lastIndex = THREE;
      } else {
        throw new Error("Format de la carte bancaire n'est pas autorisé");
      }
      break;
  }
  return [{ firstIndex, lastIndex }];
}
