import {Routes} from '@angular/router';
import {AppRoutes} from './Routes.enum';
import {ReferentielResolverService} from '../services/referentiel.resolver';
import {BearerTokenGuardService} from '../services/auth.guard';
import {ProspectResolver} from '../services/prospect.resolver';
import {BrowserResolver} from '../services/browser.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRoutes.STEPS_PRESENTATION,
  },
  {
    path: AppRoutes.ERROR,
    data: {preloadOn: []},
    loadChildren: () =>
      import('../../../pages-modules/0-error/error.module').then((module) => module.ErrorModule),
  },
  {
    path: AppRoutes.STEPS_PRESENTATION + '/:code',
    data: {preloadOn: [AppRoutes.MAIL]},
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/1-presentation-etapes/steps-presentation.module').then(
        (module) => module.StepsPresentationModule,
      ),
  },
  {
    path: AppRoutes.MAIL,
    data: {preloadOn: [AppRoutes.STEPS_PRESENTATION, AppRoutes.MAIL_SAVED]},
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/2-mail/mail.module').then((module) => module.MailModule),
  },
  {
    path: AppRoutes.STEPS_PRESENTATION,
    data: {preloadOn: [AppRoutes.MAIL]},
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/1-presentation-etapes/steps-presentation.module').then(
        (module) => module.StepsPresentationModule,
      ),
  },
  {
    path: AppRoutes.MAIL,
    data: {preloadOn: [AppRoutes.STEPS_PRESENTATION, AppRoutes.MAIL_SAVED]},
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/2-mail/mail.module').then((module) => module.MailModule),
  },
  {
    path: AppRoutes.MAIL_ALREADY_EXISTS,
    data: {preloadOn: [AppRoutes.MAIL]},
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/3.1-mail-already-exists/mail-alredady-exists.module').then(
        (module) => module.MailAlredadyExistsModule,
      ),
  },
  {
    path: AppRoutes.MAIL_SAVED,
    data: {preloadOn: [AppRoutes.MAIL, AppRoutes.FORMULA_CHOICES]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/3-mail-saved/mail-saved.module').then(
        (module) => module.MailSavedModule,
      ),
  },
  {
    path: AppRoutes.FORMULA_CHOICES,
    data: {preloadOn: [AppRoutes.MAIL_SAVED, AppRoutes.COMMITMENT_PRINCIPLES]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/4-formula-choices/formula-choices.module').then(
        (module) => module.FormulaChoicesModule,
      ),
  },
  {
    path: AppRoutes.COMMITMENT_PRINCIPLES,
    data: {preloadOn: [AppRoutes.FORMULA_CHOICES, AppRoutes.COMMITMENT_ACCOUNT]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/5-commitment-principles/commitment-principles.module').then(
        (module) => module.CommitmentPrinciplesModule,
      ),
  },
  {
    path: AppRoutes.COMMITMENT_ACCOUNT,
    data: {preloadOn: [AppRoutes.COMMITMENT_PRINCIPLES, AppRoutes.TRACKING_CHOICES]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/6-commitment-account/commitment-account.module').then(
        (module) => module.CommitmentAccountModule,
      ),
  },
  {
    path: AppRoutes.TRACKING_CHOICES,
    data: {preloadOn: [AppRoutes.COMMITMENT_ACCOUNT, AppRoutes.COMMITMENT_CARD]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/7-tracking-choices/tracking-account.module').then(
        (module) => module.TrackingAccountModule,
      ),
  },
  {
    path: AppRoutes.COMMITMENT_CARD,
    data: {preloadOn: [AppRoutes.TRACKING_CHOICES, AppRoutes.ASSOCIATION_CHOICES]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/8-commitment-card/commitment-card.module').then(
        (module) => module.CommitmentCardModule,
      ),
  },
  {
    path: AppRoutes.ASSOCIATION_CHOICES,
    data: {preloadOn: [AppRoutes.COMMITMENT_CARD, AppRoutes.DONATION_CHOICE]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/9-association-choices/association-choices.module').then(
        (module) => module.AssociationChoicesModule,
      ),
  },
  {
    path: AppRoutes.DONATION_CHOICE,
    data: {preloadOn: [AppRoutes.ASSOCIATION_CHOICES, AppRoutes.SUMMARY]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/10-donation-choices/donation-choices.module').then(
        (module) => module.DonationChoicesModule,
      ),
  },
  {
    path: AppRoutes.SUMMARY,
    data: {preloadOn: [AppRoutes.DONATION_CHOICE, AppRoutes.FINAL]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/11-summary/summary.module').then(
        (module) => module.SummaryModule,
      ),
  },
  {
    path: AppRoutes.FINAL,
    data: {preloadOn: [AppRoutes.SUMMARY]},
    canActivate: [BearerTokenGuardService],
    resolve: {
      referentiel: ReferentielResolverService,
      browser: BrowserResolver,
      prospect: ProspectResolver,
    },
    loadChildren: () =>
      import('../../../pages-modules/12-final/final.module').then((module) => module.FinalModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/' + AppRoutes.STEPS_PRESENTATION,
  },
];
