import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiRippleModule } from '@bpce/ui/core/ripple';
import { UiButtonComponent } from './button.component';

/**
 * @deprecated Use UiButtonV2, UiButton will be removed in @bpce/ui@^14.0.0
 */
@NgModule({
  imports: [CommonModule, UiRippleModule],
  declarations: [UiButtonComponent],
  exports: [UiButtonComponent]
})
export class UiButtonModule {}
