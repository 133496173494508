import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AppRoutes} from "../models/Routes.enum";
import {environment} from "../../../../environments/environment";
import {Store} from "@ngrx/store";
import {first} from "rxjs/operators";
import {ReferentielService} from "../../data/services/referentiel.service";
import {ProspectService} from "../../data/services/prospect.service";
import {ModalBuilderService} from "@bpce/ui/modal";
import {QuitModaleComponent} from "../../shared/components/quit-modale/quit-modale.component";
import {MatomoTracker} from "@ngx-matomo/tracker";
import {EnvMode} from "../../../../environments/EnvMode";


@Injectable()
export class NavigationService {

  private gobackToSumary = false;

  constructor(
    private referentielService: ReferentielService,
    private prospectService: ProspectService,
    private router: Router,
    private store: Store,
    private modalBuilder: ModalBuilderService,
    private tracker: MatomoTracker
  ) {}

  public startSumaryEdition() {
    this.gobackToSumary = true;
  }

  public goToLinkExpired() {
    this.tracker.trackEvent('Navigation', 'GoTo', 'Lien expiré');
    this.router.navigateByUrl('/' + AppRoutes.ERROR + '?expired-link')
  }

  public goToBrowserError() {
    this.tracker.trackEvent('Navigation', 'GoTo', 'Navigateur non supporté');
    if(environment.mode === EnvMode.DEVELOPMENT) {
      document.location.href = window.location.protocol + '//' + window.location.host + '/ccoop/eer/assets/navigateur-non-supporte.html'
    } else {
      document.location.href = window.location.protocol + '//' + window.location.host + '/assets/navigateur-non-supporte.html'
    }
  }

  public goToStepsPresentation() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.STEPS_PRESENTATION);
      this.router.navigateByUrl('/' + AppRoutes.STEPS_PRESENTATION)
    }
  }

  public goToMail() {
    this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.MAIL);
    this.router.navigateByUrl('/' + AppRoutes.MAIL)
  }

  public goToMailSaved() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.MAIL_SAVED);
      this.router.navigateByUrl('/' + AppRoutes.MAIL_SAVED)
    }
  }

  public goToMailAlreadyExists() {
    this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.MAIL_ALREADY_EXISTS);
    this.router.navigateByUrl('/' + AppRoutes.MAIL_ALREADY_EXISTS)
  }

  public goToFormulaChoices() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.FORMULA_CHOICES);
      this.router.navigateByUrl('/' + AppRoutes.FORMULA_CHOICES)
    }
  }

  public goToCommitmentPrinciples() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.COMMITMENT_PRINCIPLES);
      this.router.navigateByUrl('/' + AppRoutes.COMMITMENT_PRINCIPLES)
    }
  }

  public goToCommitmentAccount() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.COMMITMENT_ACCOUNT);
      this.router.navigateByUrl('/' + AppRoutes.COMMITMENT_ACCOUNT)
    }
  }

  public goToTrackingChoices() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.COMMITMENT_CARD);
      this.router.navigateByUrl('/' + AppRoutes.TRACKING_CHOICES)
    }
  }

  public goToCommitmentCard() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.COMMITMENT_CARD);
      this.router.navigateByUrl('/' + AppRoutes.COMMITMENT_CARD)
    }
  }

  public goToAssociationChoices() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.ASSOCIATION_CHOICES);
      this.router.navigateByUrl('/' + AppRoutes.ASSOCIATION_CHOICES)
    }
  }

  public goToDonationChoices() {
    if(this.gobackToSumary) this.goToSumary()
    else {
      this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.DONATION_CHOICE);
      this.router.navigateByUrl('/' + AppRoutes.DONATION_CHOICE)
    }
  }

  public goToSumary() {
    this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.SUMMARY);
    this.router.navigateByUrl('/' + AppRoutes.SUMMARY)
    this.gobackToSumary = false
  }

  public goToFinal() {
    this.tracker.trackEvent('Navigation', 'GoTo', AppRoutes.FINAL);
    this.router.navigateByUrl('/' + AppRoutes.FINAL)
  }

  public async redirectToPortailCCoop() {
    this.tracker.trackEvent('Navigation', 'Redirect', 'Portail CCOOP');
    const referentiel = await this.referentielService.transverseReferentiel$.pipe(first()).toPromise()
    if(referentiel.portailLink) {
      document.location.href = referentiel.portailLink
    } else {
      window.close()
    }
  }

  public async redirectToErrorOutput() {
    this.tracker.trackEvent('Navigation', 'Redirect', 'Erreur');
    const referentiel = await this.referentielService.errorReferentiel$.pipe(first()).toPromise()
    document.location.href = referentiel.link
  }


  public async redirectToKyc() {
    const referentiel = await this.referentielService.transverseReferentiel$.pipe(first()).toPromise()
    this.tracker.trackEvent('Navigation', 'Exit', 'KYC');
    if(referentiel.kycLink === undefined) return
    document.location.href = referentiel.kycLink
  }

  public async close() {
    this.tracker.trackEvent('Navigation', 'Exit', 'Button fermer');
    this.modalBuilder.create(QuitModaleComponent).open({
        mode: 'dialog'
      })
  }
}
