/**
 * Rewrite of [createNumberMask addon](https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js)
 *  of official text-mask package
 */

/**
 * Define options interface
 */
export interface MaskCreationOptions {
  prefix?: string;
  suffix?: string;
  thousandsSeparatorSymbol?: string;
  decimalSymbol?: string;
  allowDecimal?: boolean;
  decimalLimit?: number;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  integerLimit?: number | null;
}
interface MaskCreationOptionsFull {
  prefix: string;
  suffix: string;
  thousandsSeparatorSymbol: string;
  decimalSymbol: string;
  allowDecimal: boolean;
  decimalLimit: number;
  requireDecimal: boolean;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
  integerLimit: number | null;
}

/**
 * Define default options
 */
const defaultOptions: MaskCreationOptionsFull = {
  prefix: '$',
  suffix: '',
  thousandsSeparatorSymbol: ' ',
  allowDecimal: false,
  decimalSymbol: ',',
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: null
};

/**
 * Define script scoped const values
 */
const minusRegExp = /-/;
const nonDigitsRegExp = /\D+/g;
const digitRegExp = /\d/;
const nonZeroDigitRegExp = /[1-9]/;
const caretTrap = '[]';

function getInteger(
  rawValue: string,
  decimalIndex: number,
  valueLength: number,
  options: MaskCreationOptionsFull
): string | [] {
  let integer = rawValue.slice(0, decimalIndex > -1 ? decimalIndex : valueLength);

  if (integer.length === 0) {
    return [];
  }

  if (!options.allowLeadingZeroes) {
    /**
     * Remove leading zeroes if unauthorized and after removing the €, remove the ending zeroes
     */
    integer = integer
      .replace(/^0+(0$|[^0])/, '$1')
      .replace('€', '')
      .trim();
  }
  if (typeof options.integerLimit === 'number') {
    const thousandsSeparatorRegex =
      options.thousandsSeparatorSymbol === '.' ? '[.]' : `[${options.thousandsSeparatorSymbol}]`;
    const numberOfThousandSeparators = (integer.match(new RegExp(thousandsSeparatorRegex, 'g')) || []).length;
    // Only keep numbers from index 0 to index (limit + number of thousands separator multiplied by their length) + 1 because of the zero
    integer = integer.slice(
      0,
      options.integerLimit + 1 + numberOfThousandSeparators * options.thousandsSeparatorSymbol.length
    );
  }
  if (options.thousandsSeparatorSymbol.length > 0) {
    integer = integer
      .replace(new RegExp(options.thousandsSeparatorSymbol, 'g'), '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return integer;
}

function getIntegerMask(integer: string, options: MaskCreationOptionsFull): Array<string | RegExp> {
  let integerMask: Array<string | RegExp> = [];

  integerMask = integerMask.concat(
    integer
      .split('')
      // only chars allowed are numbers and thousand separator placeholder (space)
      .filter(d => d === ' ' || digitRegExp.test(d))
      .map(d => (digitRegExp.test(d) ? digitRegExp : options.thousandsSeparatorSymbol))
  );
  if (integerMask.length === 0) {
    integerMask = [digitRegExp];
  } else if (!options.allowLeadingZeroes && integerMask.length >= 1) {
    integerMask[0] = integer === '0' ? digitRegExp : nonZeroDigitRegExp;
  }

  return integerMask;
}

function getFractionMask(
  hasDecimalSymbol: boolean,
  options: MaskCreationOptionsFull,
  rawValue: string,
  decimalIndex: number,
  valueLength: number,
  hasDoubleDecimalSymbol: boolean
): Array<string | RegExp> {
  let fractionMask: Array<string | RegExp> = [];

  if (hasDecimalSymbol) {
    if (options.allowDecimal) {
      const fractionPart = rawValue.slice(decimalIndex + 1, valueLength).replace(nonDigitsRegExp, '');
      fractionMask = fractionMask.concat(
        checkDoubleDecimalSymbol(hasDoubleDecimalSymbol), // if double decimal symbol -> we can remove the trap
        [options.decimalSymbol],
        fractionPart
          .split('')
          .map(d => checkForDigits(d))
          .filter((d, i) => options.decimalLimit > i)
      );
      if (fractionMask.length === 0) {
        fractionMask = [options.decimalSymbol, digitRegExp, digitRegExp];
      } else if (isDecimalsRequired(options, fractionMask.length)) {
        for (let i = 0; i < options.decimalLimit - fractionPart.length; i++) {
          fractionMask.push('0');
        }
      }
    }
  } else if (options.requireDecimal) {
    fractionMask = fractionMask.concat([options.decimalSymbol, '0', '0']);
  }

  return fractionMask;
}

const checkDoubleDecimalSymbol = (hasDoubleDecimalSymbol: boolean): [] | string[] =>
  hasDoubleDecimalSymbol ? [] : [caretTrap];

const checkForDigits = (value: string): RegExp | string => (digitRegExp.test(value) ? digitRegExp : '0');

const isDecimalsRequired = (options: MaskCreationOptionsFull, fractionPartSize: number): boolean =>
  options.requireDecimal && fractionPartSize < options.decimalLimit;

export const createNumberMask = (options: MaskCreationOptions): ((string) => Array<string | RegExp>) => {
  /**
   * Require decimals must always allow decimals (duh!)
   */
  if (!options.allowDecimal && options.requireDecimal) {
    console.warn('Allow decimal was forced by requiring decimals');
    options.allowDecimal = true;
  }

  const optionsFull: MaskCreationOptionsFull = { ...defaultOptions, ...options };

  /**
   * Return generator function for number mask
   */
  return (rawValue = ''): Array<string | RegExp> => {
    const valueLength = rawValue.length;
    if (valueLength === 0 || rawValue === optionsFull.prefix) {
      return [
        ...optionsFull.prefix.split(''),
        ...([digitRegExp] as Array<string | RegExp>),
        ...(() => {
          if (optionsFull.requireDecimal) {
            return [optionsFull.decimalSymbol].concat('0'.repeat(optionsFull.decimalLimit).split(''));
          }
          return [];
        })(),
        ...optionsFull.suffix.split('')
      ];
    }

    /**
     * Remove prefix and suffix
     */
    if (rawValue.slice(0, optionsFull.prefix.length) === optionsFull.prefix) {
      rawValue = rawValue.slice(optionsFull.prefix.length);
    }

    if (rawValue.slice(optionsFull.suffix.length * -1) === optionsFull.suffix) {
      rawValue = rawValue.slice(0, optionsFull.suffix.length * -1);
    }

    const decimalIndex = rawValue.indexOf(optionsFull.decimalSymbol);
    const hasDecimalSymbol = decimalIndex > -1;
    const hasDoubleDecimalSymbol = rawValue.lastIndexOf(optionsFull.decimalSymbol) !== decimalIndex;
    const isNegative = rawValue.indexOf('-') === 0;

    /**
     * Get integer part of value
     */
    const integer = getInteger(rawValue, decimalIndex, valueLength, optionsFull);
    const integerMask = getIntegerMask(integer as string, optionsFull);

    /**
     * Amend fraction mask if needed
     */
    const fractionMask = getFractionMask(
      hasDecimalSymbol,
      optionsFull,
      rawValue,
      decimalIndex,
      valueLength,
      hasDoubleDecimalSymbol
    );

    return rebuildMaskWithOptions(optionsFull, isNegative, integerMask, fractionMask);
  };
};

/**
 * Reattach the prefix & negative & integer + fraction mask & suffix
 */
function rebuildMaskWithOptions(
  optionsFull: MaskCreationOptionsFull,
  isNegative: boolean,
  integerMask: Array<string | RegExp>,
  fractionMask: Array<string | RegExp>
): Array<string | RegExp> {
  let mask: Array<string | RegExp> = [];

  if (optionsFull.prefix.length > 0) {
    mask = mask.concat(optionsFull.prefix.split(''));
  }
  if (optionsFull.allowNegative && isNegative) {
    mask = mask.concat([minusRegExp]);
  }
  mask = mask.concat(integerMask).concat(fractionMask);
  if (optionsFull.suffix.length > 0) {
    mask = mask.concat(optionsFull.suffix.split(''));
  }
  return mask;
}
