import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {EerModule} from './app/eer.module';
import {environment} from './environments/environment';
import {EnvMode} from "./environments/EnvMode";
import "hammerjs";

if (environment.mode === EnvMode.PRODUCTION || environment.mode === EnvMode.STAGING) {
  enableProdMode();
  console.error = function() {}
}

platformBrowserDynamic().bootstrapModule(EerModule)
  .then(() => {
    const element = document.getElementById('not-supported')
    if(element) element.parentNode.removeChild(element);
  })
  .catch(err => console.error(err));

