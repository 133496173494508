import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UiLoaderMode } from '../loader.model';
import { UiEnhancedLoaderState } from './enhanced-loader.model';

@Component({
  selector: 'ui-enhanced-loader',
  templateUrl: 'enhanced-loader.component.html',
  styleUrls: ['enhanced-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiEnhancedLoaderComponent implements AfterViewInit {
  loaderType = UiLoaderMode.LOGO;

  @Input()
  loadingMessage: string;

  @Input()
  successMessage: string;

  @Input()
  errorMessage: string;

  @Input()
  immediateResult: 'SUCCESS' | 'ERROR';

  states = UiEnhancedLoaderState;

  state = UiEnhancedLoaderState.LOADING;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.immediateResult) {
      if (this.immediateResult === 'ERROR') {
        this.error();
      } else if (this.immediateResult === 'SUCCESS') {
        this.success();
      }
    }
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }

  success(): void {
    this.state = UiEnhancedLoaderState.SUCCESS;
    this.markForCheck();
  }

  error(): void {
    this.state = UiEnhancedLoaderState.ERROR;
    this.markForCheck();
  }

  reset(): void {
    this.state = UiEnhancedLoaderState.LOADING;
    this.markForCheck();
  }
}
