import { Injectable } from '@angular/core';
import { UiToasterBuilderService } from '@bpce/ui/toaster';
import { UiEnhancedLoaderComponent } from '../enhanced-loader/enhanced-loader.component';
import { UiToasterLoaderConfig, UiToasterLoaderRef } from './toaster-loader.model';

@Injectable({
  providedIn: 'root'
})
export class UiToasterLoaderBuilderService {
  constructor(private readonly toasterBuilderService: UiToasterBuilderService) {}

  open({
    id,
    showSuccessButton = false,
    showErrorButton = true,
    successMessage = '',
    errorMessage = '',
    loadingMessage = '',
    autoCloseOnSuccess = true,
    successButtonText = 'Valider',
    errorButtonText = 'Retour',
    viewContainerRef
  }: UiToasterLoaderConfig = {}): UiToasterLoaderRef {
    const ref = this.toasterBuilderService
      .create(UiEnhancedLoaderComponent)
      .open<UiEnhancedLoaderComponent, undefined>({
        id,
        showButton: false,
        viewContainerRef
      });

    if (!ref.componentRef) {
      throw new Error(`Missing 'componentRef' in UiToasterRef`);
    }

    // Loader initialisation
    ref.componentRef.instance.errorMessage = errorMessage;
    ref.componentRef.instance.loadingMessage = loadingMessage;
    ref.componentRef.instance.successMessage = successMessage;
    // Toaster initialisation
    const toasterRef = new UiToasterLoaderRef([ref.containerRef, ref.componentRef]);
    toasterRef.successButtonText = successButtonText;
    toasterRef.errorButtonText = errorButtonText;
    toasterRef.showSuccessButton = showSuccessButton;
    toasterRef.showErrorButton = showErrorButton;
    toasterRef.autoCloseOnSuccess = autoCloseOnSuccess;

    return toasterRef;
  }
}
