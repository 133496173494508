import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgEmbedderModule } from '@bpce/ui/utils';
import { IllustrationComponent } from './illustration.component';

@NgModule({
  declarations: [IllustrationComponent],
  imports: [CommonModule, SvgEmbedderModule],
  exports: [IllustrationComponent]
})
export class UiIllustrationModule {}
