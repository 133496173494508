import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ApiModule} from '../api/api.module';
import {environment} from '../../../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {DataEffects} from './store/data.effects';
import {ProspectService} from './services/prospect.service';
import {
  associationChoicesPageReducers,
  commitmentAccountPageReducers,
  commitmentCardPageReducers,
  commitmentPrinciplePageReducers,
  donationChoicesPageReducers,
  finalPageReducers,
  formulaChoicesPageReducers,
  errorPageReducers,
  loadingReducer,
  mailPageReducers,
  mailSavedPageReducers,
  stepsPresentationPageReducers,
  summaryPageReducers,
  trackingChoicesPageReducers,
  transverseReducer,
} from './store/data.reducers';
import {ReferentielService} from './services/referentiel.service';
import {EnvMode} from '../../../environments/EnvMode';
import {PartnerStoreService} from './services/partner-store.service';

@NgModule({
  imports: [
    StoreModule.forRoot({
      loading: loadingReducer,
      transverse: transverseReducer,
      errorPage: errorPageReducers,
      stepsPresentationPage: stepsPresentationPageReducers,
      mailPage: mailPageReducers,
      mailSavedPage: mailSavedPageReducers,
      formulaChoicesPage: formulaChoicesPageReducers,
      commitmentPrinciplePage: commitmentPrinciplePageReducers,
      commitmentAccountPage: commitmentAccountPageReducers,
      trackingChoicesPage: trackingChoicesPageReducers,
      commitmentCardPage: commitmentCardPageReducers,
      associationChoicesPage: associationChoicesPageReducers,
      donationChoicesPage: donationChoicesPageReducers,
      summaryPage: summaryPageReducers,
      finalPage: finalPageReducers,
    }),
    EffectsModule.forRoot([DataEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.mode !== EnvMode.PRODUCTION,
    }),
    ApiModule,
  ],
  providers: [ProspectService, PartnerStoreService, ReferentielService],
})
export class DataModule {}
