import { FocusMonitor } from '@angular/cdk/a11y';
import { ContentObserver } from '@angular/cdk/observers';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BpceAriaButtonDirective } from '@bpce/a11y';
import { KeyboardUtils } from '@bpce/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiButtonV2 } from './button-v2.model';

@Component({
  selector: 'button[uiButtonV2], a[uiButtonV2]',
  templateUrl: 'button-v2.component.html',
  styleUrls: ['button-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiButtonV2Component extends BpceAriaButtonDirective
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private static NB_INSTANCE = 1;

  @Input()
  alignment: UiButtonV2.Alignment = UiButtonV2.AlignmentEnum.inline;

  @HostBinding('attr.aria-label')
  @Input()
  ariaLabel = `Bouton d'action`;

  @Input()
  id = `bpce-button-v2-${UiButtonV2Component.NB_INSTANCE++}`;

  @Input()
  category: UiButtonV2.Category = UiButtonV2.CategoryEnum.primary;

  @HostBinding('class.bpce-button-v2-disabled')
  @Input()
  disabled = false;

  /**
   * @description colored background mode - so that the button can be used on a colored background
   */
  @HostBinding('class.bpce-button-v2-inverted')
  @Input()
  onColoredBackground = false;

  /**
   * @description the name of the font-icon
   */
  @Input()
  icon?: string;

  @Input()
  noBorder = false;

  @Input()
  noMobileBorder = false;

  @Input()
  tabIndex = 0;

  @Input()
  type: 'button' | 'submit' = 'button';

  @ViewChild('buttonV2Content', { static: false })
  content: ElementRef;

  @HostBinding('class.bpce-button-v2-icon-only')
  iconOnly = false;

  @HostBinding('class.bpce-button-v2')
  readonly _mainClass = true;

  private _originalHref: string;

  private readonly destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly _elRef: ElementRef,
    private readonly _focusMonitor: FocusMonitor,
    private readonly _renderer: Renderer2,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly obs: ContentObserver
  ) {
    super(_elRef, _focusMonitor, _renderer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (this.disabled) {
        if (this._elRef.nativeElement.href) {
          this._originalHref = this._elRef.nativeElement.href;
          this._renderer.removeAttribute(this._elRef.nativeElement, 'href');
        }
      } else if (this._originalHref) {
        this._renderer.setAttribute(this._elRef.nativeElement, 'href', this._originalHref);
      }
    }
  }

  ngAfterViewInit(): void {
    this.verifyContent();

    this.obs
      .observe(this._elRef.nativeElement)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_event: MutationRecord[]) => {
        this.verifyContent();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  verifyContent(): void {
    if (this.icon || this.iconOnly) {
      setTimeout(() => {
        this.iconOnly = (this.content.nativeElement as HTMLSpanElement).innerHTML.trim().length === 0;
        this.changeDetector.markForCheck();
      }, 0);
    }
  }

  @HostBinding('class.bpce-button-v2-primary')
  get _isPrimary(): boolean {
    return this.category === UiButtonV2.CategoryEnum.primary;
  }

  @HostBinding('class.bpce-button-v2-secondary')
  get _isSecondary(): boolean {
    return this.category === UiButtonV2.CategoryEnum.secondary;
  }

  @HostBinding('class.bpce-button-v2-tertiary')
  get _isTertiary(): boolean {
    return this.category === UiButtonV2.CategoryEnum.tertiary;
  }

  @HostBinding('class.bpce-button-v2-quaternary')
  get _isQuaternary(): boolean {
    return this.category === UiButtonV2.CategoryEnum.quaternary;
  }

  @HostBinding('class.bpce-button-v2-block')
  get _isBlockAligned(): boolean {
    return this.alignment === UiButtonV2.AlignmentEnum.block;
  }

  @HostBinding('class.bpce-button-v2-inline')
  get _isInlineAligned(): boolean {
    return this.alignment === UiButtonV2.AlignmentEnum.inline;
  }

  @HostBinding('class.bpce-button-v2-flex')
  get _isFlexAligned(): boolean {
    return this.alignment === UiButtonV2.AlignmentEnum.flex;
  }

  @HostBinding('class.bpce-button-v2-no-border')
  get _isNoBorder(): boolean {
    return this.noBorder || this.onColoredBackground;
  }

  /**
   * @description flag that controls the display of a button border when in mobile resolution
   */
  @HostBinding('class.bpce-button-v2-no-mobile-border')
  get _isNoMobileBorder(): boolean {
    return this.noMobileBorder && !this._isTertiary && !this._isQuaternary;
  }

  @HostBinding('attr.tabindex')
  get _tabIndex(): number {
    return this.disabled ? -1 : this.tabIndex || 0;
  }

  @HostListener('keyup', ['$event'])
  _onKeyupEvent(event: KeyboardEvent): void {
    if (KeyboardUtils.isTabKey(event)) {
      this._renderer.removeClass(this._elRef.nativeElement, 'no-focus-visible');
    }
  }

  @HostListener('mousedown')
  _onMouseDownEvent(): void {
    this._renderer.addClass(this._elRef.nativeElement, 'no-focus-visible');
  }

  @HostBinding('attr.disabled')
  get _disabled(): boolean | null {
    return this.disabled || null;
  }

  @HostBinding('attr.aria-disabled')
  get _ariaDisabled(): string {
    return this.disabled.toString();
  }
}
