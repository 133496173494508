import { ConditionalRegexpItemModel, ConditionalRegexpModel } from '../models/regexp.model';

export namespace ReTree {
  export function test(string: string, regex?: ConditionalRegexpModel): boolean {
    if (regex && regex.raw) {
      return regex.raw.test(string);
    } else if (regex && Array.isArray(regex.and)) {
      return regex.and.every((item: ConditionalRegexpItemModel) => test(string, item));
    } else if (regex && Array.isArray(regex.or)) {
      return regex.or.some((item: RegExp) => item.test(string));
    } else if (regex && regex.not) {
      return !test(string, regex.not);
    } else {
      return false;
    }
  }

  export function exec(string: string, regex?: RegExp | RegExp[]): RegExpExecArray | null {
    if (regex instanceof RegExp) {
      return regex.exec(string);
    } else if (regex && Array.isArray(regex)) {
      return regex.reduce((res: RegExpExecArray | null, item: RegExp) => (res ? res : exec(string, item)), null);
    } else {
      return null;
    }
  }
}
