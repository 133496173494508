import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RoutingModule} from './core-modules/routing/routing.module';
import {RootEerComponent} from './components/root-eer/root-eer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DataModule} from "./core-modules/data/data.module";
import {NgxMatomoTrackerModule} from "@ngx-matomo/tracker";
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {environment} from "../environments/environment";
import {SharedModule} from "./core-modules/shared/shared.module";
import {CommonModule, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RedirectInterceptor} from "./core-modules/api/services/redirect.interceptor";
import {ApiAuthInterceptor} from "./core-modules/api/services/api-auth.interceptor";
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    RootEerComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  imports: [
    CommonModule,
    DataModule,
    RoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMatomoTrackerModule.forRoot({
      disabled: environment.trackerUrl === undefined,
      trackerUrl: environment.trackerUrl,
      siteId: environment.siteId
    }),
    NgxMatomoRouterModule,
    SharedModule,
  ],
  bootstrap: [RootEerComponent]
})
export class EerModule {
}
