import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, share, tap } from 'rxjs/operators';
import { BreakpointsService } from '@bpce/ui/breakpoints';
import { MODAL_STATE, ModalState } from '../modal.model';

@Injectable()
export class ModalService {
  private readonly _state$ = new BehaviorSubject<ModalState>(MODAL_STATE.CLOSED);

  constructor(private readonly breakpointsService: BreakpointsService) {}

  get state$(): Observable<ModalState> {
    return combineLatest([this._state$.asObservable(), this.breakpointsService.isMobile()]).pipe(
      map(results => {
        const [status, mobile] = results;
        return mobile && status === MODAL_STATE.OPENED ? MODAL_STATE.MOBILE_OPENED : status;
      }),
      distinctUntilChanged(),
      share()
    );
  }

  private set state(value: ModalState) {
    this._state$.next(value);
  }

  init(): void {
    this.state = MODAL_STATE.OPEN;
  }

  nextState(from: ModalState): void {
    switch (from) {
      case MODAL_STATE.OPEN:
        this.state = MODAL_STATE.OPENED;
        break;
      case MODAL_STATE.CLOSE:
        this.state = MODAL_STATE.CLOSING;
        break;
      case MODAL_STATE.CLOSING:
        this.state = MODAL_STATE.CLOSED;
        break;
    }
  }

  close(): Observable<void> {
    return this.waitOpened().pipe(
      tap(() => (this.state = MODAL_STATE.CLOSE)),
      mergeMap(() => this.waitClosed())
    );
  }

  waitOpened(): Observable<void> {
    return this.state$.pipe(
      filter(state => state === MODAL_STATE.OPENED || state === MODAL_STATE.MOBILE_OPENED),
      first(),
      map(() => undefined)
    );
  }

  waitClosed(): Observable<void> {
    return this.state$.pipe(
      filter(state => state === MODAL_STATE.CLOSED),
      first(),
      map(() => undefined)
    );
  }
}
