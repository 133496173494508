import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {HelpReferentiel, QuitReferentiel} from '../../../../../../../models/Referentiel';
import {Observable} from 'rxjs';
import {ReferentielService} from '../../../data/services/referentiel.service';
import {NavigationService} from '../../../routing/services/navigation.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Prospect} from '../../../../../../../models/Prospect';
import {ProspectService} from '../../../data/services/prospect.service';

@Component({
  selector: 'app-quit-modale',
  templateUrl: './quit-modale.component.html',
  styleUrls: ['./quit-modale.component.scss'],
})
export class QuitModaleComponent {
  public readonly closed = new EventEmitter<void>();
  public referentiel$: Observable<QuitReferentiel>;
  public html$: Observable<string>;
  public prospect$: Observable<Prospect>;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closed.emit();
  }

  constructor(
    public navigationService: NavigationService,
    private referentielService: ReferentielService,
    private prospectService: ProspectService,
  ) {
    this.referentiel$ = referentielService.quitReferentiel$;
    this.prospect$ = this.prospectService.prospect$;
    this.html$ = this.referentiel$.pipe(
      mergeMap((referentiel) =>
        this.prospectService.prospect$.pipe(
          map((prospect) => referentiel.html.replace('{{email}}', prospect.email)),
        ),
      ),
    );
  }
}
