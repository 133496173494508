export interface RedactionRule {
  firstIndex: number;
  lastIndex: number;
}

export enum ENUMREDACTIONTYPE {
  EMAIL = 'EMAIL',
  TEL = 'TEL',
  CREDITCARD = 'CREDITCARD',
}
