/*
 * Public API ui/icon
 */
export { UiIconModule } from './icon.module';
export { UiIconComponent } from './icon.component';
export {
  getUiIconNameNotFoundError,
  getUiIconNoHttpProviderError,
  getUiIconFailedToSanitizeError,
  UiIconRegistry,
  ICON_REGISTRY_PROVIDER_FACTORY
} from './icon-registry.service';
