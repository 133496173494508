import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BpceA11yModule } from '@bpce/a11y';
import { UtilsModule } from '@bpce/utils';
import { UiThemeModule } from '@bpce/ui/theming';
import { UiHeaderComponent } from './header.component';

@NgModule({
  imports: [CommonModule, UiThemeModule, BpceA11yModule, UtilsModule],
  declarations: [UiHeaderComponent],
  exports: [UiHeaderComponent]
})
export class UiHeaderModule {}
