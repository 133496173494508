<ui-fab-custom
  [label]="label"
  [scrollContainer]="scrollContainer"
  (opened)="onOpened($event)"
  (closed)="onClosed($event)"
  [picto]="icon"
>
  <ul role="menu">
    <li role="none" *ngFor="let item of items; let last = last">
      <button
        [id]="item.id"
        role="menuitem"
        class="bpce-fab-menu-item"
        [ngClass]="{ 'bpce-fab-menu-item-last': last }"
        (click)="onItemClick($event, item)"
      >
        <ui-icon [fontIconSize]="fontIconSize" [fontIcon]="item.fontIcon" aria-hidden="true"></ui-icon>
        <span> {{ item.label }} </span>
      </button>
    </li>
  </ul>
</ui-fab-custom>
