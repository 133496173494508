export interface IPostalAddress {
  address: string;
  zipCode: string;
  town: string;
  knownAs: string;
  additionalAddress: string;
  autocompletedAddress?: boolean;
}

export class PostalAddress implements IPostalAddress {
  address: string;
  zipCode: string;
  town: string;
  knownAs: string;
  additionalAddress: string;
  autocompletedAddress?: boolean;

  constructor(data?: Partial<PostalAddress>) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.address = '';
      this.zipCode = '';
      this.town = '';
      this.knownAs = '';
      this.additionalAddress = '';
      this.autocompletedAddress = false;
    }
  }

  public static isValid(val: unknown): boolean {
    if (!val || typeof val !== 'object') {
      return false;
    }

    const hasAddress = (val as PostalAddress).address !== undefined;
    const hasZipCode = (val as PostalAddress).zipCode !== undefined;
    const hasTown = (val as PostalAddress).town !== undefined;

    return hasZipCode && hasAddress && hasTown;
  }

  get full(): string {
    return `${this.address || ''} ${this.zipCodeAndTown}`.trim();
  }

  get fullWithKnownAs(): string {
    return `${this.address || ''}${this.knownAs ? `, ${this.knownAs}, ` : ' '}${this.zipCodeAndTown}`.trim();
  }

  get numberAndStreet(): string {
    return this.address || '';
  }

  get zipCodeAndTown(): string {
    return `${this.zipCode || ''} ${this.town || ''}`.trim();
  }

  public toString(): string {
    return this.full;
  }
}
