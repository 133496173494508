<div class="template" *ngIf="transverseReferentiel$ | async as transverseReferentiel">

  <app-page-header
    id="header"
    [showLogo]="true"
    [backTitle]="'Retour étape précédente'"
    [closeTitle]="'Quitter Parcours'"
    (backButtonClicked)="backButtonClicked.emit()"
    (closeButtonClicked)="closeButtonClicked.emit()"
    [label]="transverseReferentiel.mainTitle"
    [showCloseButton]="showCloseButton"
    [showBackButton]="showBackButton">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6">
          <ng-container  *ngIf="stepperSelectedIndex !== undefined">
            <ui-stepper
              aria-hidden="true"
              [steps]="steps$ | async"
              [currentStepIndex]="stepperSelectedIndex">
            </ui-stepper>
            <ol class="bpce-visually-hidden" title="Étapes du parcours">
              <li [attr.aria-current]="stepperSelectedIndex === i" *ngFor="let step of steps$ | async; let i = index">
                {{step.label}}
                <span [attr.aria-current]="'false'" *ngIf="i > stepperSelectedIndex"> - étape non complété</span>
                <span [attr.aria-current]="'current'" *ngIf="i === stepperSelectedIndex"> - étape courante</span>
                <span [attr.aria-current]="'false'" *ngIf="i < stepperSelectedIndex"> - étape complété</span>
              </li>
            </ol>
          </ng-container>
        </div>
      </div>
    </div>
  </app-page-header>

  <ui-illustration [id]="illustation" *ngIf="illustation !== undefined"></ui-illustration>


  <main>
    <div class="container" style="height: 100%">
      <div class="row justify-content-center" *ngIf="title">
        <app-page-title [title]="title" [big]="true" class="col-12 col-md-10 col-lg-8"></app-page-title>
      </div>
      <div class="row justify-content-center" *ngIf="subTitle">
        <p class="col-12 col-md-10 col-lg-8 subTitle" id="sub-title" [class.bold]="boldSubTitle">{{subTitle}}</p>
      </div>
      <div class="row justify-content-center" *ngIf="price">
        <p class="pricing">
          <span class="price">{{price}}</span>
          <span class="slash" aria-hidden="true"></span>
          <span class="bpce-visually-hidden"> par </span>
          <span>mois</span>
        </p>
      </div>
      <div class="row justify-content-center image" *ngIf="image">
        <div class="col-12 col-md-10 col-lg-8" style="text-align: center; padding: 0px;">
          <img alt="" [style.backgroundColor]="imagePaddingColor" [class.padded]="imagePadding" [src]="apiUrl + '/referentiel/files/' + image">
        </div>
      </div>
      <div class="row justify-content-center content d-block d-sm-flex" [class.centered]="centered">
        <div [ngClass]="{
        'mt-2': centered,
        'm-sm-auto': centered,
        'col-lg-6': contentWidth === 'medium',
        'col-md-10': contentWidth === 'large',
        'col-lg-8': contentWidth === 'large'}" class="col-12 m-auto">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </main>

  <app-docked-button
    *ngIf="showDockedButtons"
    [class.shadow]="scrollbarVisible$ | async"
    [class.docked]="dockButton"
    (primaryButtonClicked)="primaryButtonClicked.emit();"
    (secondaryButtonClicked)="secondaryButtonClicked.emit()"
    [primaryButtonDisabled]="primaryButtonDisabled"
    [primaryButtonLabel]="primaryButtonLabel"
    [secondaryButtonDisabled]="secondaryButtonDisabled"
    [secondaryButtonLabel]="secondaryButtonLabel"
    [showPrimaryButton]="showPrimaryButton"
    [showSecondaryButton]="showSecondaryButton">
  </app-docked-button>

  <app-page-footer [link]="transverseReferentiel.legalInformationLabel" (linkClicked)="openLegalInfo()"></app-page-footer>

  <app-fab-help-button (click)="openContactModale()"></app-fab-help-button>

</div>
