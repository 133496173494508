import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-docked-button',
  templateUrl: './docked-button.component.html',
  styleUrls: ['./docked-button.component.scss']
})
export class DockedButtonComponent {

  @Input() showPrimaryButton = true
  @Input() primaryButtonLabel: string
  @Input() primaryButtonDisabled = false
  @Output() primaryButtonClicked = new EventEmitter()

  @Input() showSecondaryButton = false
  @Input() secondaryButtonLabel: string
  @Input() secondaryButtonDisabled = false
  @Output() secondaryButtonClicked = new EventEmitter()

  constructor() {
  }
}
