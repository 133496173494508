import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UiPictogram } from './pictogram.model';

const SM_SIZE = 40;
const MD_SIZE = 48;
const LG_SIZE = 56;
const XL_SIZE = 80;

@Component({
  selector: 'ui-pictogram',
  templateUrl: './pictogram.component.html',
  styleUrls: ['./pictogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiPictogramComponent implements OnInit, OnChanges {
  @Input() id: string;

  @Input() size: UiPictogram.Size = UiPictogram.SizeType.MD;

  @Input() reverse = false;

  svgId = '';
  svgSrc = '';
  width = SM_SIZE;
  height = SM_SIZE;

  ngOnInit(): void {
    this.initSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.size) {
      this.initSize();
    }

    if (changes && changes.id) {
      this.initId();
      this.initSource();
    }
  }

  private initId(): void {
    this.svgId = this.id.split(':')[1] || this.id;
  }

  private initSize(): void {
    switch (this.size) {
      case UiPictogram.SizeType.SM:
        this.width = SM_SIZE;
        this.height = SM_SIZE;
        break;
      case UiPictogram.SizeType.MD:
        this.width = MD_SIZE;
        this.height = MD_SIZE;
        break;
      case UiPictogram.SizeType.LG:
        this.width = LG_SIZE;
        this.height = LG_SIZE;
        break;
      case UiPictogram.SizeType.XL:
        this.width = XL_SIZE;
        this.height = XL_SIZE;
        break;
      default:
        this.width = SM_SIZE;
        this.height = SM_SIZE;
        break;
    }
  }

  private initSource(): void {
    const source = this.id.split(':')[0];

    switch (source) {
      case 'bpce':
        this.svgSrc = 'svg/icons.svg';
        break;
      case 'monochrome':
        this.svgSrc = 'svg/monochrome.svg';
        break;
      case 'trans-cat':
        this.svgSrc = 'svg/transaction-categories.svg';
        break;
      case 'pro-cat':
        this.svgSrc = 'svg/pro-categories.svg';
        break;
      default:
        this.svgSrc = 'svg/icons.svg';
        break;
    }
  }
}
