<ng-container *ngIf="referentiel$ | async as referentiel">

  <div ui-modal-title>
    <app-page-title [big]="false" [modale]="true" [title]="referentiel.title"></app-page-title>
  </div>

  <ui-modal-content>
    <app-inner-html [content]="referentiel.html"></app-inner-html>

    <div class="detail">
      {{referentiel.detail}}
    </div>
  </ui-modal-content>

  <div ui-modal-actions uiButtonV2Group alignment="block">
    <button tabindex="0" id="close-help-modale" (click)="closed.emit()" category="primary" uiButtonV2>{{referentiel.dockedButtonLabel}}</button>
  </div>

</ng-container>
