import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {TransverseReferentiel} from "../../../../../../../models/Referentiel";
import {Store} from "@ngrx/store";
import {transverseReferentielSelector} from "../../../data/store/data.selectors";
import {ModalBuilderService} from "@bpce/ui/modal";
import {HelpModaleComponent} from "../../components/help-modale/help-modale.component";
import {map} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";
import {Step} from "@bpce/ui/stepper";
import {
  EligibilityIntersticielComponent
} from "../../../../pages-modules/1-presentation-etapes/components/eligibility-intersticiel/eligibility-intersticiel.component";
import { InfoLegalesModaleComponent } from '../../components/info-legales-modale/info-legales-modale.component';
import {IdPipe} from "../../pipes/IdPipe";

@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss']
})
export class PageTemplateComponent implements OnInit {
  public transverseReferentiel$: Observable<TransverseReferentiel>;
  public scroll = false
  public apiUrl = environment.apiUrl

  @Input() illustation: string
  @Input() title: string
  @Input() subTitle: string
  @Input() boldSubTitle = false
  @Input() price: string
  @Input() showDockedButtons = true
  @Input() centered = false
  @Input() contentWidth: 'small' | 'medium' | 'large' = 'medium'
  @Input() imagePadding = false
  @Input() imagePaddingColor: string
  @Input() image: string = undefined

  @Input() showBackButton = false
  @Input() stepperSelectedIndex
  @Input() backButtonLabel = 'Back'
  @Input() showCloseButton = true
  @Input() dockButton = true
  @Output() backButtonClicked = new EventEmitter();
  @Output() closeButtonClicked = new EventEmitter();

  @Input() showPrimaryButton = true
  @Input() primaryButtonLabel: string
  @Input() primaryButtonDisabled = false;
  @Output() primaryButtonClicked = new EventEmitter();

  @Input() showSecondaryButton = false
  @Input() secondaryButtonLabel: string
  @Input() secondaryButtonDisabled = false;
  @Output() secondaryButtonClicked = new EventEmitter();

  public steps$: Observable<Step[]>
  public scrollbarVisible$ = new BehaviorSubject<boolean>(true);

  constructor(
    public store: Store,
    private modalBuilder: ModalBuilderService,
  ) {
    this.transverseReferentiel$ = store.select(transverseReferentielSelector)
    this.steps$ = this.transverseReferentiel$
      .pipe(map((referentiel: TransverseReferentiel) => referentiel.stepper))
      .pipe(map((steps: string[]) => steps.map(step => ({
        id: new IdPipe().formatId(step),
        label: step,
        subStepCount: undefined,
        selectable: false
      }))));
  }

  public ngOnInit() {
    if(this.dockButton) {
      setInterval(() => {
        const output = window.scrollY < document.documentElement.scrollHeight - document.documentElement.clientHeight
        this.scrollbarVisible$.next(output);
      }, 100)

      setTimeout(() => {
        document.getElementById('header')?.click()
      }, 100)
    }
  }

  public openContactModale() {
    this.modalBuilder.create(HelpModaleComponent).open({
      mode: 'dialog'
    })
  }


  public openLegalInfo() {
    this.modalBuilder.create(InfoLegalesModaleComponent).open({
      mode: 'contextual'
    })
  }
}
