/*
 * Public API ui/utils
 */

// DQE
export { URL_DQM, provideUrlDqmService } from './services/dqe/urlInjectionToken';
export { DQE_CONFIG, provideDqeConfigService, DqeConfig, dqeFactory, ServiceNameEnum } from './services/dqe/dqe.config';
export {
  ValidationCode,
  FormMessageErrors,
  MessageErrors,
  AbstractControlWarn,
  SuggestionService
} from './services/dqe/dqe.model';
export { IConfig } from './services/dqe/provider.config';

// Address
export { IPostalAddress, PostalAddress } from './services/address/address.model';
export {
  UiAddressSuggestionModule,
  provideAddressSuggestionService
} from './services/address/address-suggestion.module';
export { AddressSuggestionService, ADDRESS_SUGGESTION_SERVICE } from './services/address/address-suggestion.service';
export { DqeAddressValidationService } from './services/address/address.service';

// Email
export { DqeEmailAbstractService } from './services/email/dqe-email-abstract-service';
export { DQEEmailLookUpResponse, DQEEmailLookUpUnoBody } from './services/email/dqe-email-types';
export { DqeEmailV2Service } from './services/email/dqe-email-v2.service';
export {
  emailValidatorFactory,
  UiEmailValidationModule,
  provideEmailValidationService
} from './services/email/email-validation.module';
export { EMAIL_VALIDATION_SERVICE, EMAIL_VALIDATOR } from './services/email/email-validation.service';
export { AsyncValidationService, BpceValidators } from './services/email/validators';

// Town
export { ITown, Town } from './services/town/town-suggestion.model';
export { UiTownSuggestionModule } from './services/town/town-suggestion.module';
export { DqeTownSuggestService, TOWN_SUGGESTION_SERVICE } from './services/town/town-suggestion.service';
export { isIdLocalityValid, convertObjectToTown, getOnlyDigit } from './services/town/town-suggestion.util';
export { provideTownSuggestionService } from './services/town/town-suggestion.module';

// Phone
export { countryPrefixes, commonCountryPrefixes, CountryPrefix } from './phone/country-prefixes';
export { numberSpaceRegex, sortListByKey, removeSpacing, insertCharacter } from './phone/utils';
export { DqePhoneService } from './phone/dqe-phone-validation.service';
export { UrlDqeConfService, URL_DQE } from './phone/url-dqe-phone.service';
export { UiPhoneValidationModule } from './phone/phone-validation.module';
export { IDqeConf } from './phone/dqe.model';

// Fixed mask
export { MaskCreationOptions, createNumberMask } from './fixed-mask/fixed-mask';

// Floating UI
export { UiFloatingUiService } from './services/floating-ui/floating-ui.service';
export { UiFloatingUiModule } from './services/floating-ui/floating-ui.module';

// SvgEmbedderModule
export { SvgEmbedderModule } from './svg-embedder/svg-embedder.module';
export { SvgEmbedderComponent } from './svg-embedder/svg-embedder.component';
