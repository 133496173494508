import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {
  /**
   * @description check if a param is not null, defined and not empty
   * @export
   * @param param
   * @returns boolean
   */
  public isDefinedNotEmptyNotNull = (param: unknown): boolean => param !== undefined && param !== '' && param !== null;

  /**
   * @description check if a param is defined
   * @export
   * @param param
   * @returns boolean
   */
  public isDefined = (param: unknown): boolean => param !== undefined;

  /**
   * @description check if a param is not null, defined
   * @export
   * @param param
   * @returns boolean
   */
  public isDefinedNotNull = (param: unknown): boolean => param !== undefined && param !== null;

  /**
   * @description check if a param is not null
   * @export
   * @param param
   * @returns boolean
   */
  public isNotNull = (param: unknown): boolean => param !== null;

  /**
   * @description check if a param is not null and not empty
   * @export
   * @param param
   * @returns boolean
   */
  public isNotNullNotEmpty = (param: unknown): boolean => param !== null && param !== '';

  /**
   * @description check if a param is not empty
   * @export
   * @param param
   * @returns boolean
   */
  public isNotEmpty = (param: unknown): boolean => param !== '';

  /**
   * @description check if a collection/array is defined, not null, not empty
   * @export
   * @param param
   * @returns boolean
   */
  public isCollectionOrArrayEmpty = (param: unknown[] | null | undefined): boolean =>
    param !== undefined && param !== null && param && param.length > 0;

  /**
   * @description check if a collection/array is defined, not null
   * @export
   * @param param
   * @returns boolean
   */
  public isCollectionOrArrayDefined = (param: unknown[] | null | undefined): boolean =>
    param !== undefined && param !== null;
}
