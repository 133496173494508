import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { Observable } from 'rxjs';
import { TransverseReferentiel } from '../../../../../../../models/Referentiel';
import {Store} from "@ngrx/store";
import {transverseReferentielSelector} from "../../../data/store/data.selectors";
import {NavigationService} from "../../../routing/services/navigation.service";
import {Platform} from "@angular/cdk/platform";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  public apiUrl = environment.apiUrl

  @Input() label: string
  @Input() showLogo = false
  @Input() backTitle = 'Retour';
  @Input() closeTitle = 'Fermer';
  @Input() showBackButton = false;
  @Input() showCloseButton = true;
  @Output() backButtonClicked = new EventEmitter()
  @Output() closeButtonClicked = new EventEmitter()

  public referentiel$: Observable<TransverseReferentiel>
  public showBackLabel: boolean;

  constructor(
    private store: Store,
  ) {
    this.referentiel$ =  store.select(transverseReferentielSelector)
    this.showBackLabel = window.innerWidth > 600;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.showBackLabel = window.innerWidth > 600;
  }

}
