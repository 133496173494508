import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDqeConf } from './dqe.model';

const TWO = 2;
const THREE = 3;

@Injectable({
  providedIn: 'root'
})
/*
 * validates a phone using DQE services
 */
export class DqePhoneService implements OnDestroy {
  dqeData = new Subject();
  dqePhoneCodeEnum = {
    0: 'Numéro de téléphone est incorrect',
    1: 'Numéro de téléphone est valide',
    2: 'La ligne est construite mais le téléphone est peut-être inactif',
    3: 'La réponse du service est inconnue'
  };

  constructor(private readonly httpClient: HttpClient) {}

  callDqe(phoneToValidate: string, dqeConf: IDqeConf): Observable<ValidationErrors | null> {
    const url = `${dqeConf.url}${dqeConf.serviceRestName}/?Tel=${phoneToValidate}`;
    return this.httpClient.get(url).pipe(
      map(response => this.handleDqeResponse(response)),
      catchError(error => of(this.handleErrorResponse(error)))
    );
  }

  handleDqeResponse(response: unknown): ValidationErrors | null {
    const idError = response && typeof response === 'object' && response[1] ? response[1].IdError : null;

    this.dqeData.next(response);
    switch (idError) {
      case 0: {
        return { incorrectPhone: this.dqePhoneCodeEnum[0] };
      }
      case 1: {
        return null;
      }
      case TWO: {
        return { partialPhoneControl: this.dqePhoneCodeEnum[TWO] };
      }
      default: {
        return { unknownDqeServiceError: this.dqePhoneCodeEnum[THREE] };
      }
    }
  }

  handleErrorResponse(error: HttpErrorResponse): ValidationErrors {
    this.dqeData.next(error);
    return { unknownDqeServiceError: this.dqePhoneCodeEnum[THREE] };
  }

  ngOnDestroy(): void {
    this.dqeData.complete();
  }
}
