import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiIconV2 } from './icon-v2.model';

@Component({
  selector: 'ui-icon-v2',
  templateUrl: './icon-v2.component.html',
  styleUrls: ['./icon-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiIconV2Component {
  @Input()
  name: string;

  @Input()
  size: UiIconV2.Size = UiIconV2.SizeType.MD;

  @Input()
  ariaLabel: string;

  get iconClasses(): string {
    return `ui-icon-${this.size} ${this.name || ''}`;
  }
}
