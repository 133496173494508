
<div class="bullet-number">
  <ui-pictogram [id]="'bpce:' + pictogram"></ui-pictogram>
</div>

<div class="informations">
  <h3 class="name">
    {{name}}
  </h3>

  <p class="description">
    {{description}}
  </p>
</div>
