import { InjectionToken, Provider } from '@angular/core';

export const URL_DQM = new InjectionToken<string>('URL_DQM');

export function provideUrlDqmService(urlDQM: string): Provider {
  return {
    provide: URL_DQM,
    useValue: urlDQM
  };
}
