import { ComponentRef, InjectionToken, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UiToasterComponent } from './toaster.component';

/** Injection token that can be used to access the data that was passed in to a dialog. */
export const UI_TOASTER_DATA = new InjectionToken<any>('UiToasterData'); // NOSONAR

// prettier-ignore
export interface UiToasterConfig<T = any> { // NOSONAR
  data?: T;
  id?: string;
  showButton?: boolean;
  buttonText?: string;
  viewContainerRef?: ViewContainerRef;
}

export class UiToasterRef<T> {
  containerRef: ComponentRef<UiToasterComponent>;
  componentRef?: ComponentRef<T>;

  constructor(containerRef: ComponentRef<UiToasterComponent>, cmpRef?: ComponentRef<T>) {
    this.containerRef = containerRef;
    this.componentRef = cmpRef;
  }

  buttonClick(): Observable<void> {
    return this.containerRef.instance.buttonClick;
  }

  backdropClick(): Observable<void> {
    return this.containerRef.instance.backdropClick;
  }

  afterClose(): Observable<void> {
    return this.containerRef.instance.afterClose;
  }

  close(): void {
    return this.containerRef.instance.close();
  }
}
