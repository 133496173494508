import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {BPCE_MODAL_DATA, ModalContent} from "@bpce/ui/modal";
import {BehaviorSubject, Observable} from "rxjs";
import {SupportingDocumentsReferentiel} from '../../../../../../../models/Referentiel';
import { StepsPresentationService } from '../../../../pages-modules/1-presentation-etapes/services/steps-presentation.service';
import {ReferentielService} from "../../../data/services/referentiel.service";

@Component({
  selector: 'app-documents-modale',
  templateUrl: './documents-modale.component.html',
  styleUrls: ['./documents-modale.component.scss']
})
export class DocumentsModaleComponent implements ModalContent {
  public readonly closed = new EventEmitter<void>()
  public referentiel$: Observable<SupportingDocumentsReferentiel>;

  constructor(
    private referentielService: ReferentielService
  ) {
    this.referentiel$ = referentielService.supportingDocumentsReferentiel$
  }
}
