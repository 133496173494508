<section class="bpce-fab">
  <span class="bpce-fab-overlay" (click)="onOverlayClick($event)"></span>
  <button
    id="fabBtn"
    class="bpce-fab-button"
    (click)="onFabClick($event)"
    [attr.aria-expanded]="isOpened"
    [class.bpce-fab-button-disappear]="!isFabDisplayed"
  >
    <span class="bpce-fab-button-label"> {{ label }} </span>
    <ui-icon
      class="bpce-fab-icon bpce-fab-icon-help"
      [svgIconSize]="pictoSize"
      [svgIcon]="picto"
      aria-hidden="true"
    ></ui-icon>
    <ui-icon
      class="bpce-fab-icon bpce-fab-icon-close"
      [fontIconSize]="closeIconSize"
      [fontIcon]="closeIcon"
      aria-hidden="true"
    ></ui-icon>
  </button>
  <aside id="fabMenu" class="bpce-fab-menu" aria-labelledby="fabBtn" (click)="$event.stopPropagation()">
    <ng-content></ng-content>
  </aside>
  <span class="bpce-fab-arrow" (click)="$event.stopPropagation()"></span>
</section>
