import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@bpce/ui/icon';
import { UiToasterModule } from '@bpce/ui/toaster';
import { UiLoaderComponent } from './basic-loader/loader.component';
import { UiEnhancedLoaderComponent } from './enhanced-loader/enhanced-loader.component';
import { UiProgressLoaderComponent } from './progress-loader/progress-loader.component';

@NgModule({
  imports: [CommonModule, UiToasterModule, UiIconModule],
  declarations: [UiLoaderComponent, UiProgressLoaderComponent, UiEnhancedLoaderComponent],
  exports: [UiLoaderComponent, UiProgressLoaderComponent, UiEnhancedLoaderComponent],
  entryComponents: [UiLoaderComponent, UiProgressLoaderComponent, UiEnhancedLoaderComponent]
})
export class UiLoaderModule {}
