import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { UnsubscriberComponent } from '@bpce/utils';
import dialogPolyfill from 'dialog-polyfill';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UiModalContentWrapper } from './modal-content-directives';
import {
  BPCE_MODAL_CLOSE_EVENT,
  BPCE_MODAL_DATA,
  BPCE_MODAL_OVERLAY_CLICK,
  DialogPolyfill,
  HtmlDialogElement,
  IEvent,
  ModalMode,
  ModalState
} from './modal.model';
import { ModalService } from './services/modal.service';

const DURATION_400 = 400;
const DURATION_250 = 250;
const ONE_HUNDRED = 100;
const TWENTY = 20;
const MINUS_TWO = -2;
const BOTTOM_MARGIN = 64;

// prettier-ignore
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent<T = unknown, H = unknown> extends UnsubscriberComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private static NB_INSTANCE = 1;

  @ViewChild('modal', { static: true }) modalElement: ElementRef<HtmlDialogElement>;
  @ViewChild(UiModalContentWrapper, { static: true }) modalContentWrapper: UiModalContentWrapper;
  @ViewChild('modalAnimateContainer', { static: true }) modalAnimateContainer: ElementRef<HTMLDivElement>;

  @Input() mode: ModalMode;
  @Input() data: T;
  @Input() id: string;
  @Input() hasStickyFooter = false;

  @Output() status: EventEmitter<ModalState> = new EventEmitter<ModalState>();
  @Output() afterClose: EventEmitter<H> = new EventEmitter<H>();

  close$ = new Subject<unknown>();
  private modalFooter: HTMLElement | null;

  private escapeListener: () => void;
  private closeListener: () => void;
  private dialogMeasures = {
    footerHeight: 0,
    clientHeight: 0,
    scrollHeight: 0,
    bodySpaceTopVal: 0,
  };

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(BPCE_MODAL_DATA) private readonly data$: BehaviorSubject<unknown>,
    @Inject(BPCE_MODAL_OVERLAY_CLICK) private readonly overlayClick$: Subject<string>,
    @Inject(BPCE_MODAL_CLOSE_EVENT) private readonly closeEvent$: Subject<string>,
    private readonly renderer: Renderer2,
    private readonly modalService: ModalService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  get modal(): HtmlDialogElement {
    return this.modalElement.nativeElement as HtmlDialogElement;
  }

  get modalStyle(): string {
    return `bpce-modal-${this.mode}`;
  }

  ngOnInit(): void {
    if (!this.modal) {
      throw new Error(`Can't instantiate modal : modal does not exist`);
    }

    if (this.data) {
      this.data$.next(this.data);
      this.changeDetectorRef.markForCheck();
    }

    // Set autogenerated id if not provided
    if (!this.id) {
      this.id = `modal-${ModalComponent.NB_INSTANCE}`;
      ModalComponent.NB_INSTANCE++;
    }

    this.modalService.init();

    // Move dialog component under body to avoid polyfill problems with stacked context
    // SEE https://github.com/GoogleChrome/dialog-polyfill/#stacking-context
    this.addClass('bpce-modal-is-open', this.document.body);

    // Add animate-in class
    this.addClass('animate-in');

    // Register dialog with dialog polyfill for non compatible browsers
    (dialogPolyfill as DialogPolyfill).registerDialog(this.modal);
    this.modal.showModal();

    // Remove animate-in class
    setTimeout(() => {
      this.removeClass('animate-in');
    }, DURATION_400);

    // Catch 'ESC' event
    this.escapeListener = this.renderer.listen(this.modal, 'cancel', $event => {
      $event.preventDefault();
      this.handleCloseEvent();
    });

    this.closeListener = this.renderer.listen(this.modal, 'close', $event => {
      $event.preventDefault();
      this.handleCloseEvent();
    });

    this.close$.pipe(this.autoUnsubscriber()).subscribe(data => {
      this.modalService.close();
      this.afterClose.emit(data);

      // Should complete the afterClose Observable when all subscriptions are done
      this.afterClose.complete();
    });

    const headerTitle = this.modalAnimateContainer.nativeElement.querySelector('.bpce-header-ellipsis-container');

    if (headerTitle) {
      this.renderer.setAttribute(headerTitle, 'tabindex', '-1');
      (headerTitle as HTMLElement).focus();
      this.renderer.setStyle(headerTitle, 'outline', 'none');
    }
  }

  private handleCloseEvent() {
    this.closeEvent$.next(this.id);
  }

  ngAfterViewInit(): void {
    // Emit open status change
    this.modalService.state$
      .pipe(
        tap((event: ModalState) => this.status.emit(event)),
        this.autoUnsubscriber()
      )
      .subscribe();

    this.modalFooter = this.modal.querySelector('.bpce-modal-footer');

    if (this.modalFooter) {
      this.initDialogMeasures();

      // First scroll check
      this.addScrollShadow(window.innerHeight);

      // Check scroll on resize and scroll events
      this.checkScrollShadow();
    }
  }

  /**
   * Set initial values
   */
  private initDialogMeasures(): void {
    const footerSpacingBottomVal = this.extractStylePropertyVal(this.modal, '.bpce-modal-footer', 'padding-bottom');
    const footerSpacingTopVal = this.extractStylePropertyVal(this.modal, '.bpce-modal-footer', 'padding-top');
    const footerMarginBottomVal = this.extractStylePropertyVal(this.modal, '.bpce-modal-footer', 'margin-bottom');
    const footerHeight = this.modalFooter ? this.modalFooter.clientHeight - (footerSpacingBottomVal + footerSpacingTopVal) : 0;

    // TODO remaining marging bottom when < md layout defined in css vars, should be dynamic
    this.dialogMeasures = {
      footerHeight: footerMarginBottomVal ? footerHeight : footerHeight - 64,
      clientHeight: this.modalAnimateContainer.nativeElement.clientHeight,
      scrollHeight: this.modalAnimateContainer.nativeElement.scrollHeight,
      bodySpaceTopVal: this.extractStylePropertyVal(this.modal, '.bpce-modal-body', 'padding-top'),
    };
  }

  /**
   * Check scroll shadow on resize and scroll events
   */
  private checkScrollShadow(): void {
    this.handleResizeEvent();
    this.handleWheelEvent();
  }

  /**
   * Handle scroll shadow on resize
   */
  private handleResizeEvent(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(ONE_HUNDRED), this.autoUnsubscriber())
      .subscribe((event: IEvent) => {
        this.initDialogMeasures();
        if (this.isContentNotOverlappingWithFooter()) {
          this.removeClass('bpce-modal-with-scroll-footer');
        } else {
          this.addScrollShadow(event.target.innerHeight);
        }
      });
  }

  /**
   * Handle scroll shadow on wheel
   */
  private handleWheelEvent(): void {
    const touchMoveEvent = fromEvent(window, 'touchmove');
    const wheelEvent = fromEvent(window, 'wheel');
    merge(touchMoveEvent, wheelEvent)
      .pipe(debounceTime(TWENTY), this.autoUnsubscriber())
      .subscribe(() => {
        if (this.isContentNotOverlappingWithFooter()) {
          this.removeClass('bpce-modal-with-scroll-footer');
        } else {
          this.addScrollShadow(this.dialogMeasures.clientHeight);
        }
      });
  }

  /**
   * Check if content is not overlapped with footer
   */
  private isContentNotOverlappingWithFooter(): boolean {
    const remainingScrollingDistance =
      this.dialogMeasures.scrollHeight - this.modalAnimateContainer.nativeElement.scrollTop;
    return remainingScrollingDistance <= this.dialogMeasures.clientHeight + this.dialogMeasures.footerHeight;
  }

  /**
   * Check whether there is scroll in the modal
   * @param viewHeight
   */
  private addScrollShadow(viewHeight = 0): void {
    this.handleHeaderShadow();
    this.handleFooterShadow(viewHeight);
  }

  /**
   * Display/hide header shadow on scroll following sticky position
   * Which is applied for fullscreen (mobile + Desktop) / contextual (mobile)
   */
  private handleHeaderShadow(): void {
    if (this.modalAnimateContainer.nativeElement.scrollTop > this.dialogMeasures.bodySpaceTopVal) {
      const hasShadowClass = this.modal.classList.contains('bpce-modal-with-scroll-header');
      if (!hasShadowClass) {
        this.addClass('bpce-modal-with-scroll-header');
      }
    } else {
      this.removeClass('bpce-modal-with-scroll-header');
    }
  }

  /**
   * Display/hide footer shadow on scroll
   * @param viewHeight
   */
  private handleFooterShadow(viewHeight: number): void {
    const hasShadowClass = this.modal.classList.contains('bpce-modal-with-scroll-footer');

    // If there is scroll in the modal, display shadowed border on footer
    if (this.isScrollable(viewHeight)) {
      if (!hasShadowClass) {
        this.addClass('bpce-modal-with-scroll-footer');

        // When there is scroll, the specific footer with 80px margin-bottom (bpce-modal-fullscreen-no-scroll) should be removed
        // and display the usual footer
        if (this.mode === 'fullscreen') {
          this.removeClass('bpce-modal-fullscreen-no-scroll');
        }
      }
      // Prevent a fixed box shadow after resizing
      if (this.isContentNotOverlappingWithFooter()) {
        this.removeClass('bpce-modal-with-scroll-footer');
      }
    } else {
      this.removeClass('bpce-modal-with-scroll-footer');

      // Fullscreen mode with no scroll, has a specific footer with 80px margin-bottom
      if (this.mode === 'fullscreen') {
        this.addClass('bpce-modal-fullscreen-no-scroll');
      }
    }
  }

  /**
   * Check if the content is scrollable
   * @param viewHeight
   */
  private isScrollable(viewHeight: number): boolean {
    return (
      !!this.modalAnimateContainer.nativeElement.scrollHeight &&
      (this.modalAnimateContainer.nativeElement.scrollHeight - BOTTOM_MARGIN) > viewHeight
    );
  }

  /**
   * Add className to the dialog element
   * @param className
   * @param element
   */
  private addClass(className: string, element?: HTMLElement): void {
    this.renderer.addClass(element || this.modal, className);
  }

  /**
   * Remove className to the dialog element
   * @param className
   * @param element
   */
  private removeClass(className: string, element?: HTMLElement): void {
    this.renderer.removeClass(element || this.modal, className);
  }

  /**
   * Extract the number value of css value
   * It removes the property unity with two caracters (i.e. px)
   * @param element
   * @param selector
   * @param property
   */
  private extractStylePropertyVal(element: HTMLElement, selector: string, property: string): number {
    if (!element || !element.querySelector(selector)) {
      return 0;
    }

    const value = window
      .getComputedStyle(element.querySelector(selector) as HTMLElement, null)
      .getPropertyValue(property);

    if (!value) {
      return 0;
    }

    return +value.slice(0, MINUS_TWO);
  }

  /**
   * To update the view after data being changed
   * Checking if the new content is scrollable
   * so footer shadow and scrollbar are handled
   */
  updateView(): void {
    // The view should be updated to get the correct value of the new height
    this.changeDetectorRef.detectChanges();
    this.initDialogMeasures();
    this.addScrollShadow(this.dialogMeasures.clientHeight);
  }

  // prettier-ignore
  close(data?: unknown): void {
    // Add animate-out class
    this.addClass('animate-out');

    // Close modal and remove animate-out class
    setTimeout(() => {
      this.close$.next(data);
      this.removeClass('animate-out');
    }, DURATION_250);
  }

  // After fixing a bug related to the scroll in firefox and chrome
  // https://jira.f.bbg/browse/DESY-836 and https://jira.f.bbg/browse/DESY-1060
  // the backdrop becomes behind the modal so the click isn't detected anymore
  // Thus, the check of the click area is detected manually.
  // We check the target on mousedown to avoid "selection" fake positives:
  // when you press the mouse button, move and release, the target of the click event is
  // the parent of both the target of the mousedown and the target of the mouseup if they are different.
  // And this parent is outside of the modal elements.
  // Until finding a better solution ¯\_(ツ)_/¯

  onModalMouseDown(event: MouseEvent): void {
    // We don't do anything if the click was on the the modal
    // event + target as separated args for recursion
    if (this.isInsideModalContent(event, event.target as HTMLElement)) {
      return;
    }
    // If the click was outside of the modal, it means it was on the overlay.
    this.onOverlayClick();
  }

  /**
   * Recursive method to check whether the click is inside the modal
   * @param element
   */
  private isInsideModalContent(event: MouseEvent, element: HTMLElement | null): boolean {
    // Check whether the clicked element is inside the modal
    // Which could be inside the modal header, body or footer
    // Adding bpce-header aims to reduce the recursive call as the <ui-header> is
    // generally present in the modal header
    if (
      !element ||
      element.classList.contains('bpce-header') ||
      element.classList.contains('bpce-modal-header') ||
      element.classList.contains('bpce-modal-body') ||
      element.classList.contains('bpce-modal-footer')
    ) {
      return true;
    }

    // Check if scroll exists, calc the width (for different browsers or custom styles)
    // If mousedown for scroll, return true so the modal does not close.
    // Proper values from event + element to adapt resizing with no need to intercept any other event
    if(element.scrollHeight > element.clientHeight) {
      const scrollWidth = element.offsetWidth - element.clientWidth;
      if(event.clientX > element.offsetWidth - scrollWidth) {
        return true;
      }
    }

    // Check whether the clicked element is the animate-container
    // which means that the click is done outside of the modal
    if (element.classList.contains('bpce-modal-animate-container')) {
      return false;
    }

    // If the element is deep in the DOM structure, we make a recursive call with its parent element
    return this.isInsideModalContent(event, element.parentElement);
  }

  onOverlayClick(): void {
    this.overlayClick$.next(this.id);
  }

  updateFooter(value: boolean): void {
    this.hasStickyFooter = value;
  }

  ngOnDestroy(): void {
    if (this.escapeListener) {
      this.escapeListener();
    }

    if (this.closeListener) {
      this.closeListener();
    }

    if (this.modal) {
      // To be removed only if one modal exists in the DOM
      // otherwise it would impact when overlapping modals are opened
      // Also when there is no modal, to avoid scrollbar issues on the main container
      if (this.document.documentElement.getElementsByClassName('bpce-modal').length <= 1) {
        this.removeClass('bpce-modal-is-open', this.document.body);
      }

     this.modal.close();
    }

    super.ngOnDestroy();

    // Reset data value
    this.data$.next(null);
  }

  attachContent<K>(cmp: ComponentType<K> | undefined, componentInjector: Injector | undefined): ComponentRef<K> {
    return this.modalContentWrapper.attach(cmp as ComponentType<K>, componentInjector as Injector);
  }
}
