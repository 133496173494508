<ng-container *ngIf="referentiel$ | async as referentiel">


  <div ui-modal-title>
    <app-page-title [big]="false" [title]="referentiel.title"></app-page-title>
  </div>


  <ng-container *ngIf="prospect$ | async as prospect">
    <ui-modal-content *ngIf="prospect.email" >
      <app-inner-html [content]="html$ | async"></app-inner-html>
    </ui-modal-content>
  </ng-container>


  <div ui-modal-actions uiButtonV2Group alignment="block">
    <button (click)="navigationService.redirectToPortailCCoop()" category="primary" uiButtonV2>{{referentiel.quitButtonLabel}}</button>
    <button (click)="closed.emit()" category="secondary" uiButtonV2>{{referentiel.resumeButtonLabel}}</button>
  </div>

</ng-container>
