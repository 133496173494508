export namespace UiIconV2 {
  export enum SizeType {
    SM = 'sm', // 16px
    MD = 'md', // 24px
    LG = 'lg', // 32px
    XL = 'xl', // 40px
    XXL = 'xxl', // 48px
    XXXL = 'xxxl' // 56px
  }

  export type Size = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}
