import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DqeConfig, DQE_CONFIG, ServiceNameEnum } from '../dqe/dqe.config';
import { ServiceUnavailableError, SuggestionService } from '../dqe/dqe.model';
import { PostalAddress } from './address.model';

export const ADDRESS_SUGGESTION_SERVICE = new InjectionToken<SuggestionService<string, PostalAddress>>(
  'ADDRESS_SUGGESTION_SERVICE'
);

@Injectable({
  providedIn: 'root'
})
export class AddressSuggestionService implements SuggestionService<string, PostalAddress> {
  /*
   * for the moment DQE is directly called by the component and the response is interpreted
   * should be taken over by the back-end
   */
  dqeconfig: DqeConfig;
  lastResponse: PostalAddress[];
  lastInput: string;
  maxCharAddress: number;

  constructor(private readonly httpClient: HttpClient, @Inject(DQE_CONFIG) dqeconfig: DqeConfig) {
    this.init(dqeconfig);
  }

  /*
   * value: input data of type string
   * to avoid calling the DQE service to many times following rules have been implemented
   * only query the suggestion if the address contains more than 3 alphanumerical characters
   * in any other situation an empty list of suggestion is returned
   */
  suggest(value: string): Observable<PostalAddress[]> {
    const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    if (!this.shallRequestBeSent(normalizedValue)) {
      return of(this.lastResponse);
    }

    const url = `${this.dqeconfig.url}/${ServiceNameEnum.AddressSuggest}/`;
    const params = new HttpParams().set('Adresse', normalizedValue).append('Taille', '38').append('Pays', 'FRA');
    this.lastInput = normalizedValue;
    return this.httpClient.get(url, { params }).pipe(
      map((response): PostalAddress[] => {
        if (!response || typeof response !== 'object') {
          throw new ServiceUnavailableError();
        }
        this.lastResponse = this.responseToPostalAddresses(response);
        return this.lastResponse;
      })
    );
  }

  shallRequestBeSent(inputAddress: string): boolean {
    // test cases: address is not empty, does not fail the regex, has changed since last request
    // in case of selection from the list reduce the suggestions to that single value
    const lastAddress = this.lastResponse.find((elt: PostalAddress) => elt.full === inputAddress);
    if (lastAddress) {
      // one needs to return the same suggestion as otherwise does not differentiate between
      // address not found and same address as before
      this.lastResponse = [lastAddress];
      return false;
    }

    // in case no request shall be sent reset both the last suggestion list and the last input
    if (!inputAddress || !this.validValue(inputAddress)) {
      this.lastResponse = [];
      this.lastInput = '';
    }
    // finally if some input which do not fail the regex and is different from the last one send the request
    return !!inputAddress && this.validValue(inputAddress) && inputAddress !== this.lastInput;
  }

  // A value should be valid if it is composed of at least 3 alpha characters or 5 if it contains 'bis' or 'ter'
  validValue(value: string): boolean {
    const str = (value || '')
      .replace(/quinquies/i, 'C')
      .replace(/quater/i, 'Q')
      .replace(/ter/i, 'T')
      .replace(/bis/i, 'B')
      .replace(/[\d\s]+/g, '');
    return str.length >= this.maxCharAddress;
  }

  private init(dqeconfig: DqeConfig) {
    // should be taken over by the back-end
    if (dqeconfig !== null && dqeconfig.url !== '') {
      this.dqeconfig = dqeconfig;
    } else {
      throw new TypeError('Invalid DQE config');
    }
    this.lastResponse = [];
    this.maxCharAddress = 3;
  }

  private responseToPostalAddresses(response): PostalAddress[] {
    return Object.keys(response)
      .filter(key => !!response[key].label)
      .map(
        key =>
          new PostalAddress({
            address: response[key].Numero ? `${response[key].Numero} ${response[key].Voie}` : response[key].Voie,
            zipCode: response[key].CodePostal,
            town: response[key].Localite,
            knownAs: response[key].LieuDit ? response[key].LieuDit : '',
            additionalAddress: ''
          })
      );
  }
}
