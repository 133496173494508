import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss']
})
export class LoaderOverlayComponent implements OnInit, OnDestroy {
  public show = false
  public timeoutId: any;

  public ngOnInit() {
    this.timeoutId = setTimeout(() => {
      this.show = true
    }, 500)
  }

  public ngOnDestroy() {
    clearTimeout(this.timeoutId)
  }
}
