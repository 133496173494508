// TODO: move to town-suggestion when ng-forms will be deprecated

export const boroughsObject = {
  'PARIS (75)': [
    {
      Province: '*',
      IDLocalite: '75101',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.864',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75001',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.33105',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75002',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8676',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75002',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.34399',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75103',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8635',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75003',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.35911',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75104',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8575',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75004',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.35149',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75105',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8435',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75005',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.35183',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75106',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8489',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75006',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.33542',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75107',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8543',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75007',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.3134',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75108',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8719',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75008',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.31764',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75109',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.879',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75009',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.33791',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75110',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8786',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75010',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.36037',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75111',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.858',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75011',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.38115',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75112',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8294',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75012',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.42654',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75113',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8308',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75013',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.3592',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75114',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8314',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75014',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.32557',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75115',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8422',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75015',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.29277',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75116',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8531',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75016',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.24876',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75117',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.892',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75017',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.31929',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75118',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8913',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75018',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.35299',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75119',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.8906',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75019',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.38671',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '75120',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '48.86',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '75020',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'PARIS',
      Longitude: '2.40664',
      Complement: '',
      Entreprise: '',
    },
  ],
  'MARSEILLE (13)': [
    {
      Province: '*',
      IDLocalite: '13201',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2986',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13001',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.38403',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13202',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3175',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13002',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.35994',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13203',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3117',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13003',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.38087',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13204',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3059',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13004',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.40181',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13205',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2917',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13005',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.39657',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13206',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2876',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13006',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.38087',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13207',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.284',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13007',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.3547',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13208',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2371',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13008',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.37825',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13209',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.229',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13009',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.44633',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13210',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2732',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13010',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.43061',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13211',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.2849',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13011',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.48827',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13212',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3072',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13012',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.42538',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13213',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3649',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13013',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.42538',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13214',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3426',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13014',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.38872',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13215',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3628',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13015',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.36778',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '13216',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '43.3754',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '13016',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'MARSEILLE',
      Longitude: '5.30502',
      Complement: '',
      Entreprise: '',
    },
  ],
  'LYON (69)': [
    {
      Province: '*',
      IDLocalite: '69381',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7678',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69001',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.83527',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69382',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7477',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69002',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.8241',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69383',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7583',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69003',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.85539',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69384',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.781',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69004',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.82435',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69385',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7583',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69005',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.79908',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69386',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7751',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69006',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.8502',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69387',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7304',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69007',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.83994',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69388',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7315',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69008',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.86962',
      Complement: '',
      Entreprise: '',
    },
    {
      Province: '*',
      IDLocalite: '69389',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '45.7699',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '69009',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'LYON',
      Longitude: '4.80372',
      Complement: '',
      Entreprise: '',
    },
  ],
  Y: [
    {
      Province: '*',
      IDLocalite: '80829',
      NbNumero: '',
      Pays: 'FRA',
      IDVoie: '',
      Latitude: '49.8',
      Numero: '',
      TypeVoie: '',
      Instance: '0',
      ListeNumero: '',
      CodePostal: '80190',
      SousLocalite: '',
      LieuDit: '',
      Nbnumero: '',
      Localite: 'Y',
      Longitude: '2.98333',
      Complement: '',
      Entreprise: '',
    },
  ],
};
