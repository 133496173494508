import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {BPCE_MODAL_DATA, ModalBuilderService} from "@bpce/ui/modal";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { IFrameModaleComponent } from '../iframe-modale/i-frame-modale.component';
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent {
  @Input() showArrow = true
  @Input() title: string
  @Input() label: string
  @Input() url: string
  @Input() isLink = true
  @Input() iFrameTitle = ''
  @Input() iframeModale = true
  @Output() linkOpened = new EventEmitter<void>();

  constructor(
    private modalBuilder: ModalBuilderService,
    @Inject(BPCE_MODAL_DATA) private data: Subject<{title: string, url: string, iFrameTitle: string}>
  ) {}

  public open() {
    this.linkOpened.next();
    if(this.isLink === false) return
    if(this.iframeModale === true) {
      this.openIframeModale()
    } else {
      this.openNewTab()
    }
  }

  public openNewTab() {
    if (!this.url) return;
    window.open(this.url, '_blank');
  }

  public openIframeModale() {
    this.modalBuilder.create(IFrameModaleComponent).open({
      mode: 'contextual'
    })
    this.data.next({url: this.url, title: this.title ?? this.label, iFrameTitle: this.iFrameTitle ?? this.title})
  }
}
