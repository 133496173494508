import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import { loadingSelector } from "../../data/store/data.selectors";
import {DataActions} from "../../data/store/data.actions";



@Injectable()
export class LoadingService {

  constructor(
    private store: Store
  ) {}

  public get loading() {
    return this.store.select(loadingSelector)
  }

  public startLoading() {
    this.store.dispatch(DataActions.startLoading())
  }

  public stopLoading() {
    this.store.dispatch(DataActions.stopLoading())
  }
}
